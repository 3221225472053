import React from 'react'
import './numbersBar.css'
import pharmaNum from './pahrmaNumbers.jpg'
import deliveryNum from './deliveryNumbers.jpg'
import medicineNum from './medicineNumbers.jpg'
import { Paper } from '@mui/material'
const NumbersBar = () => {
    return (
        <>
            <div className='num_maindiv'>
                <div style={{ display: 'flex', flexDirection: 'column', justifyContent: 'center', alignItems: 'center',padding:'2px' }}>
                    <img className='num_img_top' src={pharmaNum} />
                    <div className='num_img_top' style={{  textAlign: 'center', }}>
                        <h4 style={{ margin: '0',color:'black', }}>500+</h4>
                        <p style={{ margin: '0',color:'black', }}>Pharmacies</p>
                    </div>
                </div>
                <div style={{ display: 'flex', flexDirection: 'column', justifyContent: 'center', alignItems: 'center',padding:'2px' }}>
                    <img className='num_img_top' src={medicineNum} />
                    <div className='num_img_top' style={{  textAlign: 'center', }}>
                        <h4 style={{ margin: '0',color:'black', }}>5000+</h4>
                        <p style={{ margin: '0',color:'black', }}>Medicines</p>
                    </div>
                </div>

                <div style={{ display: 'flex', flexDirection: 'column', justifyContent: 'center', alignItems: 'center',padding:'2px' }}>
                    <img className='num_img_top' src={deliveryNum} />
                    <div className='num_img_top' style={{  textAlign: 'center', }}>
                        <h4 style={{ margin: '0',color:'black', }}>Loading...</h4>
                        <p style={{ margin: '0',color:'black', }}>Orders Delivered</p>
                    </div>
                </div>




                {/* <div className='num_rightdiv'></div> */}
            </div>
        </>
    )
}

export default NumbersBar