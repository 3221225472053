import React, { useEffect } from 'react';

import { Provider } from 'react-redux';
import { useRoutes } from 'react-router-dom';
import { MatxTheme } from './components';
import ScrollToTop from './components/ScrollToTop';

import { AuthProvider } from './contexts/JWTAuthContext';
import { SettingsProvider } from './contexts/SettingsContext';
import CartProvider from './contexts/CartContext';
import { Store } from './redux/Store';
import routes from './routes';
import axios from 'axios';
import { useNavigate } from 'react-router-dom';
import ReactGa from 'react-ga';
import swal from 'sweetalert';

axios.defaults.baseURL = process.env.REACT_APP_API_BASE_PATH;
// axios.defaults.baseURL ="https://hegan.in";
axios.defaults.headers.post['Content-Type'] = 'application/json';
axios.defaults.headers.post['Accept'] = 'application/json';

axios.defaults.withCredentials = true;
axios.interceptors.request.use(function (config) {
  const token = localStorage.getItem('accessToken');
  // config.headers.Authorization = token ? `Bearer ${token}` : '';
  config.headers['x-api-key'] = token ? token : '';
  return config;
});

ReactGa.initialize('G-FR4V81ZEG7');

const App = () => {
  const navigate = useNavigate();
  useEffect(() => {
    window.addEventListener('click', check_authorisation);

    // Clean up the event listener when the component unmounts
    return () => {
      window.removeEventListener('click', check_authorisation);
    }; // Call the authorization function
  }, []);

  const check_authorisation = () => {
    const token = localStorage.getItem('login-mobile-no')==null ? false : true;

    if (token) {
      try {
        // await axios.get('/sanctum/csrf-cookie').then(response => {
        // const id='9dca0ce4-79e9-4190-b0d4-31012b80ca90';
        const userData = JSON.parse(localStorage.getItem('user'));
        // console.log(userData);

        axios
        .post(`api/admin/auth/user_authentication`)
          .then((response) => {
            // console.log(95,response);
            if (response.data.logoutStatus == true) {
              localStorage.removeItem('accessToken');
              localStorage.removeItem('auth_name');
              localStorage.removeItem('register_as');
              localStorage.removeItem('login-mobile-no');
              localStorage.removeItem('user');
              swal({
                title: "Important!",
                text: response.data.message,
                icon: "success",
            });
              navigate('/');
            }
          })
          .catch(function (error) {
            console.error(error);
          });
        // });
      } catch (e) {}
    }
  };

  const content = useRoutes(routes);
  return (
    <Provider store={Store}>
      <SettingsProvider>
        <MatxTheme>
          <AuthProvider>
            <CartProvider>
              <ScrollToTop>{content}</ScrollToTop>
            </CartProvider>
          </AuthProvider>
        </MatxTheme>
      </SettingsProvider>
    </Provider>
  );
};

export default App;
