import React, { useEffect, useState } from 'react';

import {
    Link, NavLink, useLocation, useNavigate,
} from 'react-router-dom';
import useAuth from 'app/hooks/useAuth';
import swal from 'sweetalert';
import axios from 'axios';
import Moment from 'moment';

import { Paper, Button, Grid, Divider } from '@mui/material'
import Carousel from 'react-multi-carousel';
import 'react-multi-carousel/lib/styles.css';


// import OwlCarousel from 'react-owl-carousel';

// import 'owl.carousel/dist/assets/owl.carousel.css';
// import 'owl.carousel/dist/assets/owl.theme.default.css';
// import './hotSellerSlider.css'
import heganlogo from './heganlogo.png'

function TopBrandSlider() {

    const [brands, setBrands] = useState(false);
    const [loading, setLoading] = useState(false);
    const [page, set_page] = useState(1);
    const location = useLocation();
    let { logout, user, isAuthenticated } = useAuth();

    useEffect(() => {

        if (isAuthenticated) {
            fetchProductData();
        } else {
            fetchData();
        }
    }, [location]);




   

    /**
     * Fetching data from server.
     */
 

    const fetchData = async () => {
        setLoading(true);
        try {
            // await axios.get('/sanctum/csrf-cookie').then(response => {
            axios.post('api/admin/subCategory/getAllTopBrandslist', {
             
            })
                .then(response => {
                    console.log(response);
                    if (response.data.status === 200) {
                        setBrands(response.data.data);
                    } else {
                    }
                    setLoading(false);
                })
                .catch(function (error) {
                    setLoading(false);
                    console.log(error);
                });
            // });
        } catch (e) {
            setLoading(false);
        }
    }

    /**
     * Fetching data from server.
     */



    const fetchProductData = async () => {
        setLoading(true);
        try {
            // await axios.get('/sanctum/csrf-cookie').then(response => {
            // axios.get('api/user/product/getAll')
            axios.post('api/admin/subCategory/getAllTopBrandslist', {

            })
                .then(response => {
                    console.log(response);
                    if (response.data.status === 200) {
                        setBrands(response.data.data);
                    } else {
                    }
                    setLoading(false);
                })
                .catch(function (error) {
                    setLoading(false);
                    console.log(error);
                });
            // });
        } catch (e) {
            // setLoading(false);
        }
    }


   
    const responsive = {
        superLargeDesktop: {
        
          breakpoint: { max: 4000, min: 3000 },
          items: 6
        },
        desktop: {
          breakpoint: { max: 3000, min: 1024 },
          items: 6
        },
        tablet: {
          breakpoint: { max: 1024, min: 464 },
          items: 3
        },
        mobile: {
          breakpoint: { max: 464, min: 0 },
          items: 2
        }
      };
    return (
        <>
            <div className="section small_pt prodstyel marg-btm30">
                <div >
                    {location.pathname == '/' &&

                        <div className="row">
                            <div className="col-md-12">
                                <div className="heading_tab_header">
                                    <h3>Top Brands<Link to="/brand" className="vall">VIEW ALL</Link></h3>
                                </div>
                            </div>
                        </div>
                    }
                    <div className="row">
                        <div className="col-md-12">


                            <Carousel responsive={responsive}
                            infinite={true}
                              showDots={false}
                            >
                                
                                {brands && brands.map((row, index) => (

                                    <Paper key={'pa' + index} style={{
                                        border
                                            : '2px solid #015998', marginLeft:'7px',
                                    }}>



                                        <Link to={`/brand/${row.brandkey}`} className="hover_effect1" title={row.brandkey}>
                                            <div className='productImage_div'>
                                                <img src={row.brandImage ? row.brandImage : heganlogo} alt={`Product ${index + 1}`} />
                                            </div>
                                        </Link>

                                        <Divider />
                                        <div className='product-info'>
                                            <p className='product-name' style={{ color: '#015998', fontWeight: 'bold' }} key={"h2" + index}>{row.brandkey}</p>

                                        </div>


                                    </Paper>

                                ))}
                            </Carousel>

                        </div>
                    </div>
                </div>
            </div >
        </>
    );
}
export default TopBrandSlider;
