import React, { useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { useCart } from 'app/contexts/CartContext';
import { Button, Badge } from '@mui/material';
import DeleteIcon from '@mui/icons-material/Delete';
import IconButton from '@mui/material/IconButton';
import AddIcon from '@mui/icons-material/Add';
import RemoveIcon from '@mui/icons-material/Remove';
import TextField from '@mui/material/TextField';
import { makeStyles } from '@material-ui/core/styles';

import './cartProducts.css'
const useStyles = makeStyles({
  payment_table_heads: {
    fontFamily: 'Poppins, sans-serif',
    fontWeight: 'bold',
  },
});

const CartProducts = (props) => {
  const navigate = useNavigate();
  const [loading, setLoading] = useState(false);
  const { cart, removeItemFromCart, increaseCartQuantity, increaseSecondCartQuantity, cartItems, setCartItems } = useCart();


  const classes = useStyles();

  const fetchData = () => {
    //   if (cart[0].product_carts.length > 0) {

    //     setCartItems(cart[0].product_carts);
    //     console.log(cartItems);
    //   } else {
    //     // console.log('hiiiiiiiiiii');
    //     setCartItems([])
    //   }
  }

  useEffect(() => {

    fetchData()
    // console.log(cart[0].product_carts.length,'------------');



  }, [])

  // const updatePage = () => {
  //   // console.log(cart);
  //   // if (cart[0].product_carts.length > 0) {
  //   //   console.log('yes');
  //   //   setCartItems(cart[0].product_carts);
  //   // } else {
  //   //   setCartItems([])
  //   // }
  //   setLoadPage(1)
  //   console.log('loadpage');

  // }


  const [quantity, setQuantity] = useState('');
  const [pId, setPid] = useState('');
  // const [minQty_error, set_minQty_error] = useState(false);

  const handleQuantityChange = (event, product_id, minQty, qty) => {
    // const newQuantity = parseInt(event.target.value);
    // if (!isNaN(newQuantity)) {
    //   setQuantity(newQuantity);
    // }
    // console.log(product_id, minQty, qty);
    // if (event.target.value >= minQty) {
    console.log(props, 'hi');
    setPid(product_id)
    setQuantity(event.target.value);
    if (event.target.value >= minQty) {
      increaseSecondCartQuantity(product_id, event.target.value)
      props.set_minQty_error(false)
    } else {
      props.set_minQty_error(true)
    }
    // else {
    //   setPid(product_id)
    //   setQuantity(qty);
    // }
    // setPid(product_id)
    // setQuantity(event.target.value);
    // increaseSecondCartQuantity(product_id, event.target.value)

  };

  const incrementQuantity = (id, quantity) => {
    // setQuantity(quantity + 1);
    setPid(id)
    console.log('hi');
    increaseSecondCartQuantity(id, quantity);
    props.set_minQty_error(false)
    setQuantity(quantity)
    fetchData();
  };

  const decrementQuantity = (id, quantity, minQty) => {
    // console.log(id, quantity, minQty);
    setPid(id)
    if (quantity >= minQty) {
      increaseSecondCartQuantity(id, quantity);
      props.set_minQty_error(false)
      setQuantity(quantity)
      // fetchData();
    } else if (quantity < minQty) {
      props.set_minQty_error(true)
      setQuantity(quantity)
      fetchData();
    }
  };


  return (
    <>
      {(cartItems.length > 0) ? (
        <>
          <h4 style={{}}>   <span style={{ backgroundColor: '#015998', color: 'white', padding: '5px', borderRadius: '7px', border: '1px solid black' }}>Products</span><Badge badgeContent={cartItems.length} color="primary" style={{ border: '11px solid black ', borderRadius: '50%', marginLeft: '18px' }}></Badge></h4>

          <div >

            {cartItems && (

              cartItems.map((row, index) => (
                <div key={'cart-summary-' + index} className="row" style={{
                  padding: "13px", borderRadius: '12px', marginTop: '13px', backgroundColor: '#E2EEF4', position: 'relative', border: '2px solid #015998',
                }}>
                  <div className='col-sm-12 col-lg-3  marg-btm30' style={{ borderRadius: '12px', backgroundColor: '#fff', boxShadow: ' 3px 5px 4px rgba(0, 0, 0, 0.25)', }}>
                    <a className="hover_effect1" title={row.productName} href={`/product/details/${row.productId}`}>
                      <img className='os-list-img'
                        src={row.product.uploadImg}
                        alt={row.productName}
                      />
                    </a>
                  </div>
                  <div className='col-sm-12 col-lg-9'>
                    <h5 className={classes.payment_table_heads}>{row.productName}</h5>
                    <p className={classes.payment_table_heads}> {row.product.formulation}</p>
                    <p className={classes.payment_table_heads}>Sold By Seller: {row.product.user.userName}</p>
                    <p className={classes.payment_table_heads}>Unit Pack: {row.product.unitPacking}</p>
                    <p className={classes.payment_table_heads}>Net Rate: {row.product.netRate} / {row.product.minQty} items<span style={{ fontSize: '10px', padding: '0 0 0 1px' }}>(Min. Qty)</span></p>
                    <p className={classes.payment_table_heads}>
                      {/* <span className='summary-list-price'>{process.env.REACT_APP_CURRENCY}. {(row.quantity * row.product.tradeRate).toFixed(2)}</span>
                      <span className='summary-list-price'> {process.env.REACT_APP_CURRENCY} {(row.quantity * row.product.mrp).toFixed(2)}</span> */}
                      Net Rate: {((row.product.netRate / row.product.minQty) * row.quantity).toFixed(2)} / {row.quantity} items<span style={{ fontSize: '10px', padding: '0 0 0 1px', }}>(Actual Qty)</span>
                    </p>
                  </div>
                  {/* <div className='col-sm-12 col-lg-10'>
                    <h6>Delivery between February 23- February 26</h6>
                  </div> */}
                  <div className='edit_cart_butons'>
                    {props.currentStep != 1 && <Button onClick={() => { removeItemFromCart(row.productId); }} variant="outlined" color="inherit" sx={{ background: "#FFF", borderRadius: '10px', border: '2px solid #015998', fontWeight: 'bold', }}>
                      Remove <DeleteIcon color="error" fontSize="small" />
                    </Button>}
                    <div style={{ position: 'relative', bottom: '92px' }}>

                      {props.currentStep != 1 && <IconButton onClick={(e) => { decrementQuantity(row.productId, (row.quantity * 1 - 1), row.product.minQty) }} style={{ position: 'absolute', zIndex: '1', bottom: '-37px' }} >
                        <RemoveIcon />
                      </IconButton>}

                      <input
                        type="text"
                        variant="outlined"
                        value={pId == row.productId ? quantity : row.quantity}
                        onChange={(e) => { { props.currentStep != 1 && handleQuantityChange(e, row.productId, row.product.minQty, row.quantity) } }}

                        style={{ width: '105px', textAlign: 'center', backgroundColor: 'white', position: 'absolute', borderRadius: '10px', height: '33px', border: '2px solid #015998', fontWeight: 'bold', }}
                      />
                      {props.currentStep != 1 && <IconButton onClick={(e) => { incrementQuantity(row.productId, (row.quantity * 1 + 1), row.product.availableQty) }} style={{ position: 'absolute', zIndex: '1', left: '64px', top: '-5px', }}>
                        <AddIcon />
                      </IconButton>}

                      {props.minQty_error && pId == row.productId && < p style={{ color: 'red', position: 'absolute', top: '29px' }}>Minimum Permissible Quantity is {row.product.minQty} </p>}
                    </div>
                  </div>
                </div>
              ))
            )}
          </div>
        </>
      ):(
        
          <h3 style={{color:'#015998'}}>Your Cart Is Empty!</h3>
        
      )
      }
    </>
  );
}

export default CartProducts;
