import AuthGuard from 'app/auth/AuthGuard';
// import chartsRoute from 'app/views/charts/ChartsRoute';
import dashboardRoutes from 'app/views/dashboard/DashboardRoutes';
import NotFound from 'app/views/frontend/NotFound';
import ScrollToTop from './components/ScrollToTop';
import StockRoutes from './views/dashboard/Seller/Stock/StockRoutes';

// Seller Dashboard Route

// (Vendor) Dashboard Route

// Comman route for Seller and Vendor
import sOrderHistoryRoutes from 'app/views/dashboard/Seller/OrderHistory/OrderHistoryRoutes';
import medicineRoutes from 'app/views/dashboard/Seller/Medicine/MedicineRoutes';

// Retailer Dashboard Route
// import rOrderHistoryRoutes from 'app/views/dashboard/Retailer/OrderHistory/OrderHistoryRoutes';
import purchaseHistoryRoutes from 'app/views/dashboard/PurchaseHistory/purchaseHistoryRoutes';

//Front End Routes
import frontendPrivateRoutes from 'app/views/frontend/FrontendPrivateRoutes';
import frontendRoutes from 'app/views/frontend/FrontendRoutes';

import { Navigate } from 'react-router-dom';
import MatxLayout from './components/MatxLayout/MatxLayout';
const register_as = localStorage.getItem('register_as');

let routes = [];
routes = [
  // <ScrollToTop>
  {
    element: (
      <AuthGuard>
        <MatxLayout />
      </AuthGuard>
    ),
    // Route for admin login
    children: [
      ...dashboardRoutes,
      ...medicineRoutes,
      ...purchaseHistoryRoutes,
      ...StockRoutes,
      ...sOrderHistoryRoutes,
    ],
  },
  ...frontendPrivateRoutes,
  // </ScrollToTop>
  ...frontendRoutes,
  // { path: '/', element: <HomePage />},
  { path: '*', element: <Navigate to="dashboard" /> },
];

export default routes;
