import React, { useEffect, useState } from 'react';

import {
    Link, NavLink, useLocation, useNavigate,
} from 'react-router-dom';
import useAuth from 'app/hooks/useAuth';
import swal from 'sweetalert';
import axios from 'axios';
import Moment from 'moment';
import Carousel from 'react-material-ui-carousel'
import { Paper, Button, Grid, Divider } from '@mui/material'


import OwlCarousel from 'react-owl-carousel';

import 'owl.carousel/dist/assets/owl.carousel.css';
import 'owl.carousel/dist/assets/owl.theme.default.css';
// import './hotSellerSlider.css'
import heganlogo from '../HomePage/heganlogo.png'
import './brandSidePanel.css'

function BrandsSlidePanel({ take_params_from_panels }) {


    const [brands, setBrands] = useState(false);
    const [loading, setLoading] = useState(false);
    const [page, set_page] = useState(1);
    const location = useLocation();
    let { logout, user, isAuthenticated } = useAuth();

    const [topPosition, setTopPosition] = useState(213); // Initial 'top' position
    const scrollThreshold = 200; // Change this value to your desired threshold





    useEffect(() => {
        const handleScroll = () => {
            const scrollY = window.scrollY;
            if (scrollY >= scrollThreshold && topPosition !== 2) {
                setTopPosition(2); // Change this to your desired new 'top' position
            } else if (scrollY < scrollThreshold && topPosition !== 213) {
                setTopPosition(213); // Change this to your original 'top' position
            }
        };

        window.addEventListener('scroll', handleScroll);

        return () => {
            window.removeEventListener('scroll', handleScroll);
        };
    }, [topPosition]);

    useEffect(() => {

        if (isAuthenticated) {
            fetchProductData();
        } else {
            fetchData();
        }
    }, [location]);


    const handleFetchData = (brand) => {
        take_params_from_panels(brand, 'brand')

    }


    /**
     * Fetching data from server.
     */
    const getProductsToShow = () => {
        const screenWidth = window.innerWidth;
        if (screenWidth >= 800) {
            return 6;
        } else if (screenWidth >= 500) {
            return 3;
        } else {
            return 2;
        }
    };

    const numberOfProducts = getProductsToShow();

    const fetchData = async (page_no = 1) => {
        setLoading(true);
        try {
            // await axios.get('/sanctum/csrf-cookie').then(response => {
            axios.post('api/admin/subCategory/getAllTopBrandslist', {
                page: page_no, pageSize: numberOfProducts,
            })
                .then(response => {
                    console.log(response);
                    if (response.data.status === 200) {
                        setBrands(response.data.data);
                    } else {
                    }
                    setLoading(false);
                })
                .catch(function (error) {
                    setLoading(false);
                    console.log(error);
                });
            // });
        } catch (e) {
            setLoading(false);
        }
    }

    /**
     * Fetching data from server.
     */



    const fetchProductData = async (page_no = 1) => {
        setLoading(true);
        try {
            // await axios.get('/sanctum/csrf-cookie').then(response => {
            // axios.get('api/user/product/getAll')
            axios.post('api/admin/subCategory/getAllTopBrandslist', {
                page: page_no, pageSize: numberOfProducts,
            })
                .then(response => {
                    console.log(response);
                    if (response.data.status === 200) {
                        setBrands(response.data.data);
                    } else {
                    }
                    setLoading(false);
                })
                .catch(function (error) {
                    setLoading(false);
                    console.log(error);
                });
            // });
        } catch (e) {
            // setLoading(false);
        }
    }


 
    return (
        <>
            <div className='main-outer-brand-div' /* style={{ top: `${topPosition}px` }}*/ >
                {
                    brands && brands.map((row, idx) => (
                        // <Link to={`/brand/${row.brand}`}>
                        <div className='main-inner-brand-div' key={`m+${idx}`} onClick={(e) => { handleFetchData(row.brandkey) }}>
                            <div className='outer-img-brand-div' key={`o+${idx}`} style={{ borderRadius: '30px' }}>
                                <img className='img-brand' style={{ borderRadius: '30px' }} src={row.brandImage} key={`i+${idx}`} />
                            </div>

                            <p className='name-brand' key={`p+${idx}`}>{row.brandkey}</p>
                        </div>
                        //  </Link>
                    ))
                }
            </div>
        </>
    );
}
export default BrandsSlidePanel;
