import dashboardImg from './Dashboard.png'
import autoupdateImg from './Autoupdate.png'
import orderImg from './order.png'
import stockImg from './stock.png'
import productNameImg from './product name.png'
import reportsImg from './Reports.png'
import accountImg from './Account.png'


export const navigations = [
  { name: 'Dashboard', path: '/dashboard', icon:dashboardImg  },
  {
    name: 'Medicine',
    icon: productNameImg,
    children: [
      { name: 'Single Entry', iconText: 'SI', path: '/dashboard/medicine/add-stock' },
      { name: 'Bulk Entry', iconText: 'SU', path: '/dashboard/medicine/bulk-stock-entry' },
      { name: 'Medicine List', iconText: 'SI', path: '/dashboard/medicine/list' },
      { name: 'Near Expiry Goods', iconText:'SI', path : '/dashboard/medicine/expiryList' }
    ],
  },
  {
    name: 'Order History',
    icon: orderImg,
    children: [
      { name: 'Sales Record (Order Id)', iconText: 'SI', path: '/dashboard/order-history/sales/record' },
      { name: 'Order Details', iconText: 'SU', path: '/dashboard' },
    ],
  },
  {
    name: 'Stock',
    icon: stockImg,
    children: [
      { name: 'Stock Available', iconText: 'SI', path: '/dashboard/stock/stock-list' },
    ],
  },
  // {
  //   name: 'Accounts',
  //   icon: accountImg,
  //   children: [
  //     { name: 'Ledger Entry', iconText: 'SI', path: '/dashboard' },
  //     { name: 'Ledger List', iconText: 'SU', path: '/dashboard' },
  //     { name: 'Hegan Settlement', iconText: 'SI', path: '/dashboard' },
  //   ],
  // },
  // {
  //   name: 'Reports',
  //   icon: reportsImg,
  //   children: [
  //     { name: 'Sales Reports', iconText: 'SI', path: '/dashboard' },
  //     { name: 'Order Cancelled', iconText: 'SU', path: '/dashboard' },
  //     { name: 'Order Refund', iconText: 'SU', path: '/dashboard' },
  //   ],
  // },
  // { name: 'Auto Update', path: '/dashboard', icon: autoupdateImg },
];
