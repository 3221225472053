import React from "react";
import { LoadingButton } from '@mui/lab';
import { TextField } from '@mui/material';
import { Box, styled, useTheme } from '@mui/system';
import useAuth from 'app/hooks/useAuth';
import { Formik } from 'formik';
import { useState } from 'react';
import { NavLink, useNavigate } from 'react-router-dom';
import * as Yup from 'yup';
import axios from 'axios';
import swal from 'sweetalert';
import ReactGA from 'react-ga'; 

import Header from './../Header/Header';
import Footer from './../Footer/Footer';
import AppLinks from './AppLinks';
import loginimage from './login.png'


import "./partner.css";

const FlexBox = styled(Box)(() => ({ display: 'flex', alignItems: 'center' }));

const JustifyBox = styled(FlexBox)(() => ({ justifyContent: 'center' }));

const ContentBox = styled(Box)(() => ({
  height: '100%',
  padding: '32px',
  position: 'relative',
  background: 'rgba(0, 0, 0, 0.01)',
}));

const JWTRoot = styled(JustifyBox)(() => ({
  background: '#e7feff',
  minHeight: '100% !important',
  '& .card': {
    maxWidth: 950,
    minHeight: 500,
    margin: '1rem',
    display: 'flex',
    borderRadius: 2,
    alignItems: 'center',
  },
}));

// inital login credentials
const initialValues = {
  mobile: '',
};

// form field validation schema
const validationSchema = Yup.object().shape({
  mobile: Yup.string()
    .min(10, 'Mobile Number must be 10 digit.')
    .max(10, 'Mobile Number must be 10 digit.')
    .required('Mobile Number is required!'),
});



const PartnerLogin = () => {
  const theme = useTheme();
  const navigate = useNavigate();
  const [loading, setLoading] = useState(false);

  const { login } = useAuth();

  // sending OTP to user
  // const otpSendToUserFunction = async (phoneNumber, otp) => {

  //   // let user = 'user'

  //   // let otp = 98766
  //   const response = await axios(
  //     `https://www.mysmsapp.in/api/push.json?apikey=63eb3f9c56754&sender=HeganH&mobileno=${phoneNumber}&text=Hello%0AYour%20Hegan.in%20login%20OTP%20is%20%20%20${otp}%20Your%20OTP%20%20will%20expire%20within%2010%20minutes.%0APlease%20do%20not%20share%20it%20with%20anyone.%20%0ARegards%20%0AHegan`, {
  //     headers: {
  //       "Content-Type": "application/json",
  //     },
  //     method: 'get',
  //   }
  //   );
  //   response = await response.json();
  //   // console.log(response);
  //   if (response) alert('otp send success fully')
  //   return
  // }

  const otpSendToUserFunction = async (phoneNumber, otp) => {
    try {
      const response = await axios(
        `https://www.mysmsapp.in/api/push.json?apikey=63eb3f9c56754&sender=HeganH&mobileno=${phoneNumber}&text=Hello%0AYour%20Hegan.in%20login%20OTP%20is%20%20%20${otp}%20Your%20OTP%20%20will%20expire%20within%2010%20minutes.%0APlease%20do%20not%20share%20it%20with%20anyone.%20%0ARegards%20%0AHegan`,
        {
          headers: {
            "Content-Type": "application/json",
          },
          method: 'get',
        }
      );
  
      // response.json() is not needed for axios; the data is already parsed
      // If the API returns JSON data, you can access it directly with response.data
      // response.json() is used with fetch API, not axios
  
      console.log(response.data); // Check the API response data if needed
  
      // Assuming the API response indicates success in some way, you can show an alert
      // alert('OTP sent successfully');
    } catch (error) {
      console.error('Error sending OTP:', error);
      // Handle the error appropriately (e.g., show an error message)
    }
  };
  



  const handleFormSubmit = async (values, errors) => {
    // alert('hie')
    // localStorage.setItem('login-mobile-no', '1234567890')
    // setLoading(false);
    // navigate('/login-otp');

    try {
      // alert('hiee')
      setLoading(false);
      // axios.get('/sanctum/csrf-cookie').then(response => {
      // axios.post('api/admin/auth/userLogin', {
      axios.post('api/user/otp', {
        mobile: values.mobile,
      })
        .then(response => {
          // console.log(response);
          if (response.data.status === 200) {
            localStorage.setItem('login-mobile-no', response.data.mobile);
            // localStorage.setItem('OTP', response.data.OTP);
            swal({
              title: "Good job!",
              text: response.data.message,
              icon: "success",
            });
            ReactGA.event({
              category: 'User',
              action: 'Login'
            });
            
            // otpSendToUserFunction(response.data.mobile, response.data.OTP)
            navigate('/login-otp');
          } else if (response.data.status == 404) {
            swal({
              title: "Warning!",
              text: response.data.message,
              icon: "warning",
            });
            // errors.setErrors(response.data.errors);
          }
          //  else {
          //   errors.setErrors(response.data.errors);
          // }
          // setLoading(false);
        })
        .catch(function (error) {
          console.error(error);
        });
      // });
    } catch (e) {
      setLoading(false);
      console.log(e);
    }
  };

  return (
    <>
      <Header />
      <div className="main_content">
        <div className="login_register_wrap section" style={{ background: 'linear-gradient(90deg, #9E8FEC 0%, #0CB7FF 50%, #6DFFAC 100%)' }}>
          <div className="container" style={{
            background: 'linear-gradient(90deg, #9E8FEC 0%, #0CB7FF 50%, #6DFFAC 100%)', maxWidth: "980px", margin: "0 auto",
            padding: "0px", boxShadow: "0px 4px 4px rgba(0, 0, 0, 0.25)"
          }}>
            <div className="row justify-content-center">
              <div className="col-xl-6 login_wrap" style={{ background: 'linear-gradient(90deg, #9E8FEC 0%, #0CB7FF 50%, #6DFFAC 100%)' }}>
                <div className=" text-center" style={{ padding: "8% 0% 0% 4%", }}>
                  <div className="heading_s1">
                    <h3>Welcome Back to Hegan</h3>
                  </div>
                  <img src={loginimage} />
                </div>
              </div>
              <div className="col-xl-6" >
                <div className="login_wrap">
                  <div className="padding_eight_all bg-white">
                    <div className="heading_s1 text-center">
                      <h3>Login to your Account</h3>
                      <p>Get access to your Inventory, Order Details & Reports</p>
                    </div>
                    <Formik
                      onSubmit={(values, errors) => {
                        // alert('hi')
                        handleFormSubmit(values, errors);
                      }
                      }
                      initialValues={initialValues}
                      validationSchema={validationSchema}
                    >
                      {({ values, errors, touched, handleChange, handleBlur, handleSubmit }) => (
                        <form onSubmit={handleSubmit} style={{ marginTop: "40px" }}>
                          <div className="form-group mb-3">
                            <label>Enter  Mobile No.</label>
                            <TextField
                              fullWidth
                              className="login_mobile form-control1"
                              size="medium"
                              type="text"
                              name="mobile"
                              variant="filled"
                              // placeholder="+91 "
                              onBlur={handleBlur}
                              value={values.mobile}
                              onChange={handleChange}
                              helperText={touched.mobile && errors.mobile}
                              error={Boolean(errors.mobile && touched.mobile)}
                              inputProps={{ maxLength: 10 }}
                              sx={{ mb: 3, height: '2em' }}
                            />
                          </div>
                          <div className="form-group mb-3">
                            <LoadingButton
                              className="btn btn-fill-out btn-block"
                              type="submit"
                              color="primary"
                              loading={loading}
                              variant="contained"
                              sx={{ my: 2 }}
                            >
                              Get OTP
                            </LoadingButton>
                          </div>
                        </form>
                      )}
                    </Formik>

                    <div className="form-note text-center" style={{ marginTop: '150px' }}>
                      <NavLink
                        to="/partner-register"
                        style={{ color: '#4b5563', marginLeft: 5, fontWeight: 500, fontSize: '15px' }}
                      >
                        New to Hegan? Register (User / Partner)
                      </NavLink>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      {/* <AppLinks /> */}

      <Footer />
    </>

  );
};

export default PartnerLogin;
