import React, { useState } from 'react'
import axios from 'axios'

import Header from './../Header/Header';
import Footer from './../Footer/Footer';

import contactUs from './contactUs.png'
import call from './call.png';
import mail from './mail.png'
import whatsAppBusiness from './whatsappBusiness.png'

import Box from '@mui/material/Box';
import Card from '@mui/material/Card';
import CardActions from '@mui/material/CardActions';
import CardContent from '@mui/material/CardContent';
import Button from '@mui/material/Button';
import Typography from '@mui/material/Typography';
import { TextField, FormControl, InputLabel, Select, MenuItem } from '@material-ui/core';
import { Stack } from '@mui/material';


const bull = (
    <Box
        component="span"
        sx={{ display: 'inline-block', mx: '2px', transform: 'scale(0.8)' }}
    >
        •
    </Box>
);



const ContactUs = () => {

    const containerStyle = {
        fontFamily: 'Arial, sans-serif',
        margin: 0,
        padding: '20px',
    };

    const paragraphStyle = {
        marginBottom: '10px',
        fontWeight: 'bold',
    };


    const [firstName, setFirstName] = useState('');
    const [lastName, setLastName] = useState('');
    const [mobile, setMobile] = useState('');
    const [email, setMail] = useState('');
    const [message, setMessage] = useState('')
    const [reason, setReason] = useState('');

    const [showThanks, setShowThanks] = useState(false);

    const handleChange = (event) => {
        const { name, value } = event.target;
        if (name === 'firstName') {
            setFirstName(value);
        } else if (name === 'lastName') {
            setLastName(value);
        } else if (name === 'mobile') {
            setMobile(value);
        } else if (name === 'email') {
            setMail(value);
        } else if (name === 'message') {
            setMessage(value)
        } else if (name === 'reason') {
            setReason(value);
        }
    };


    const sendContactInfo = async () => {
        const result = await axios.post('api/user/contactUs/create', {
            "firstName": firstName,
            "lastName": lastName,
            "mobile": mobile,
            "email": email,
            "reasonToContact": reason,
            "message": message
        })

        //  console.log(84,result);
    }

    const resetForm = () => {
        setFirstName('');
        setLastName('');
        setMobile('');
        setMail('');
        setMessage('');
        setReason('');
    }

    const handleClick = () => {
        setShowThanks(true);
        sendContactInfo();
        resetForm();
    }




    const card = (
        <React.Fragment>
            <CardContent>

                <Typography variant="h5" component="div">
                    We Are Happy To Help You!
                </Typography>
                <Stack spacing={2}>
                    <Stack direction='row' >
                        <TextField
                            name="firstName"
                            label="First Name"
                            value={firstName}
                            onChange={handleChange}
                            fullWidth
                            margin="normal"
                            variant='outlined'
                        />

                        <TextField
                            name="lastName"
                            label="Last Name"
                            value={lastName}
                            onChange={handleChange}
                            fullWidth
                            margin="normal"
                            variant='outlined'
                            style={{ marginLeft: '4%' }}
                        />
                    </Stack>

                    <Stack direction='row'>
                        <TextField
                            name="mobile"
                            label="Mobile"
                            value={mobile}
                            onChange={handleChange}
                            fullWidth
                            margin="normal"
                            variant='outlined'
                        />

                        <TextField
                            name="email"
                            label="Email"
                            value={email}
                            onChange={handleChange}
                            fullWidth
                            margin="normal"
                            variant='outlined'
                            style={{ marginLeft: '4%' }}
                        />
                    </Stack>

                    <Stack direction='row'>


                        <FormControl fullWidth margin="normal">
                            <InputLabel >Reason to Contact</InputLabel>
                            <Select
                                name="reason"
                                value={reason}
                                onChange={handleChange}

                            >
                                <MenuItem value="To Partner/Collaborate">To Partner/Collaborate</MenuItem>
                                <MenuItem value="For Branding">For Branding</MenuItem>
                                <MenuItem value="Technical Issue">Technical Issue</MenuItem>
                                <MenuItem value="Other">Other</MenuItem>
                            </Select>
                        </FormControl>

                        <TextField
                            name="message"
                            label="Message"
                            value={message}
                            onChange={handleChange}
                            fullWidth
                            margin="normal"
                            variant='outlined'
                            style={{ marginLeft: '4%' }}
                        />

                    </Stack>
                </Stack>

                {showThanks &&
                    <React.Fragment>
                        <CardContent>
                            <Typography variant="h6" component="div">
                                Thank you for connecting with Hegan!<br />
                                We will contact you soon.
                            </Typography>
                        </CardContent>
                    </React.Fragment>
                }

            </CardContent>
            {/* <CardActions > */}
            <div style={{ display: 'flex', justifyContent: 'center', alignItems: 'center', height: '2rem' }}>
                <button style={{ backgroundColor: '#015998', color: 'whitesmoke', borderRadius: '10px', border: 'none', width: '30%', height: '100%' }} onClick={handleClick}>SUBMIT</button>
            </div>

            {/* </CardActions> */}



        </React.Fragment>
    );


    // open gmail function
    const openGmail = () => {
        const recipient = 'support@hegan.in';
        // const subject = 'Hello';
        // const body = 'This is the predefined message.';

        const mailtoUrl = `mailto:${recipient}`;
        window.open(mailtoUrl);
    };




    return (
        <>
            <div>
                <Header />
                <div style={{ display: 'flex', flexDirection: 'column', justifyContent: 'space-evenly' }}>
                    <div style={{ display: 'flex', justifyContent: 'center', }}>
                        <div style={{ width: '70%', }}>
                            <img src={contactUs} alt='contact us' />
                        </div>
                    </div>
                    <div style={{ display: 'flex', margin: '3% 15%', justifyContent: 'space-between', flexWrap: 'wrap', }}>
                        <div style={{ background: '#ffffff', border: '1px solid white', boxShadow: ' 0px 2px 4px rgba(0, 0, 0, 0.25)', borderRadius: '6px', display: 'flex', flexDirection: 'column', justifyContent: 'space-around', alignItems: 'center' }} >
                            <div><h5 style={paragraphStyle}>Head Office:</h5></div>
                            <div>
                                <p style={paragraphStyle}>
                                    Office No.2406A,A-41,<br />
                                    The Iconic Corenthum,Noida,<br />
                                    Sector-62 (UP), 201301
                                </p>
                            </div>
                            <div style={{ height: '28%', width: '100%', display: 'flex', flexDirection: 'column', justifyContent: 'space-between' }}>
                                <div style={{ display: 'flex', justifyContent: 'space-evenly', alignItems: 'center' }}>
                                    <img src={mail} alt='mail' style={{ width: '14%', cursor: 'pointer' }} onClick={openGmail} />
                                    <h5 style={{ cursor: 'pointer' }} onClick={openGmail} >support@hegan.in</h5>
                                </div>
                                <div style={{ display: 'flex', justifyContent: 'space-evenly', alignItems: 'center' }}>
                                    <img src={call} alt='call' style={{ width: '14%', }} />
                                    <h5  >+91 9670767909</h5>
                                </div>
                            </div>
                            {/* <div style={{ display: 'flex', justifyContent: 'center' }} > */}
                            <a href="http://wa.aisensy.com/+919670767909" target="_blank" style={{ display: 'flex', justifyContent: 'center' }}>
                                <img src={whatsAppBusiness} alt='whatsapp' style={{ width: '20%', cursor: 'pointer' }} />
                            </a>

                            {/* </div> */}
                        </div>
                        <div >
                            <Box sx={{ minWidth: 275 }}>
                                <Card variant="outlined">{card}</Card>
                            </Box>
                        </div>
                    </div>
                </div>
                <Footer />
            </div>
        </>
    )
}

export default ContactUs