import React, { useState, useEffect } from 'react';

import { Box, Button, Stack, styled, Grid } from '@mui/material';
import { useNavigate, Link, useLocation } from 'react-router-dom';

import Header from './../Header/Header';

import Footer from './../Footer/Footer';
import FullPageBannerSlider from './FullPageBannerSlider';

import BannerSlider from './BannerSlider';

import ServicesSlider from './ServicesSlider';
import LoginPopup from './LoginPopup';


import TrustedBrandSlider from './TrustedBrandSlider';
import HotSellersSlider from './HotSellersSlider';
import TopBrandSlider from './TopBrandSlider';
import SubCategoriesSlider from './SubCategoriesSlider';
import ExploreCategoriesSlider from './ExploreCategoriesSlider';

import MarketplaceCarousel from '../marketPlaceInfoCarousel/MarketplaceCarousel';

import CarouselFile from '../FeaturesCarousel/contactInfo';
import BusinessPartnersCarousel from '../BusinessPartners/BusinessPartnersCarousel';
import LogisticsPartnersCarousel from '../LogisticsPartners/LogisticsPartnersCarousel';
import SellWithUsCarouselFile from '../whySellCarousel/SellWithUsCarouselFile';
import SpecialOffersSlider from './SpecialOffersSlider';
import PaymentPartners from '../paymentPartners/PaymentPartners';
import FormulationSlider from './FormulationSlider';
import SpecialMarginSlider from './SpecialMarginsSlider';
import NumbersBar from './NumbersBar';
import MostSearchedProduct from './MostSearchedProduct';
import Steps from './Steps';


const HomePage = () => {
  const navigate = useNavigate();
  const location = useLocation();
  const [accessToken, setAccessToken] = useState((localStorage.getItem('accessToken')) ? localStorage.getItem('accessToken') : false);
  const [open, setOpen] = useState(false);
  useEffect(() => {
    if (localStorage.getItem('accessToken')) {

    } else {
      // setOpen(true);
    }
  }, [location]);

  return (
    <>

      <Header />


      <div style={{ backgroundColor: '#E2EEF4', }}>



        <LoginPopup open={open} setOpen={setOpen} />
        {/* <FullPageBannerSlider /> */}
        {/* <ServicesSlider /> */}
        {/* <TrustedBrandSlider /> */}


        <BusinessPartnersCarousel />


        {/* <div className="section" > */}
        {/* <div className="container" > */}
        {/* <div className="sctnhding" style={{ backgroundColor: '#015998', color: 'white', height: '10%' }}>BE OUR PARTNER  TO DIGITIZE & GROW YOUR BUSINESS.</div> */}
        {/* </div> */}
        {/* </div> */}

        <div className="main_content pb_40">
          {/* <div className="section "> */}
          <div style={{ width: '100%', }} >

            {/* <MarketplaceCarousel /> */}
            <CarouselFile />




          </div>
          <TopBrandSlider />
          <Steps />
          <MostSearchedProduct />
          <HotSellersSlider />
          <NumbersBar />
          <ExploreCategoriesSlider />
          <SpecialMarginSlider />
          <FormulationSlider />
          <SpecialOffersSlider />


          <SubCategoriesSlider />
          <SellWithUsCarouselFile />



          {/* <BannerSlider /> */}
          {/* <PaymentPartners/> */}
          {/* <LogisticsPartnersCarousel /> */}



        </div>

        <Footer />
      </div>
    </>
  );
};

export default HomePage;
