import React, { useEffect, useState } from 'react';
import {
  CardContent,
  Box,
  Dialog,
  DialogTitle, DialogContent, DialogActions,
  IconButton,
  Button, Grid, Alert, AlertTitle
} from "@mui/material";
import CloseIcon from '@mui/icons-material/Close';
import { useCart } from 'app/contexts/CartContext';
import CheckCircleIcon from '@mui/icons-material/CheckCircle';
import Moment from 'moment';

function BootstrapDialogTitle(DialogTitleProps) {
  const { children, onClose, ...other } = DialogTitleProps;
  return (
    <DialogTitle sx={{ m: 0, p: 2 }} {...other}>
      {children}
      {onClose ? (
        <IconButton
          aria-label="close"
          onClick={onClose}
          sx={{
            position: 'absolute',
            right: 8,
            top: 8,
            color: (theme) => theme.palette.grey[500],
          }}
        >
          <CloseIcon />
        </IconButton>
      ) : null}
    </DialogTitle>
  );
}

const PaymentProcess = ({ open, setOpen, orderId, payableAmount }) => {
  const [loading, setLoading] = useState();
  const { order, recent_order_id, getOrder } = useCart();
  const handleClose = () => {
    setOpen(false);
  };
  const handlePaymentProcessContinue = () => {
    window.open('https://www.onlinesbi.sbi/sbicollect/icollecthome.htm?corpID=5380831', '_blank', 'noreferrer');
  };

  useEffect(() => {
    console.log(order)

  }, [])

  return (
    <Dialog
      maxWidth="md"
      open={open}
      onClose={handleClose}
    >
      <BootstrapDialogTitle id="customized-dialog-title" onClose={handleClose}>
        Payment Details
      </BootstrapDialogTitle>
      <DialogContent dividers style={{ overflowY: 'auto' }}>
        <CardContent>
          <Box width="100%" overflow="auto" style={{ border: '2px solid #015998' }}>
            <Alert severity="success" icon={false} sx={{ width: "100%", display: "block", overflow: 'hidden' }}>
              <AlertTitle color="success" sx={{ fontSize: "20px" }}>
                Dear Customer,
              </AlertTitle>
              <p style={{ lineHeight: '15px', }}><strong>Please copy the Order ID and Amount to use at the time of payment.</strong></p>
              <p style={{ lineHeight: '15px', }}>Your Order ID : <strong>{order.orderId ? order.orderId : orderId}</strong></p>
              <p style={{ lineHeight: '15px', margin: '0 0 10px 0 ' }}>Your Amount Payable : <strong>{process.env.REACT_APP_CURRENCY}. {order.totalPayable ? order.totalPayable : payableAmount}</strong></p>
            </Alert>
          </Box>
        </CardContent>
      </DialogContent>
      <DialogActions>
        <Button onClick={handlePaymentProcessContinue} type='submit'
          variant="contained" color="success" size='medium'
          sx={{ fontSize: '15px', fontWeight: "700" }}>
          Proceed to Pay
        </Button>
      </DialogActions>
    </Dialog>
  );
};
export default PaymentProcess;