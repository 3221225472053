import React, { useEffect, useState } from 'react';

import { useNavigate, Link } from 'react-router-dom';

import { useCart } from 'app/contexts/CartContext';
import Badge from '@mui/material/Badge';
import { styled } from '@mui/material/styles';
import IconButton from '@mui/material/IconButton';
import ShoppingCartIcon from '@mui/icons-material/ShoppingCart';

function MiniCart() {
  const navigate = useNavigate();
  const { totalCartItems } = useCart();

  return (
    <>
      {totalCartItems ? (
        <Link to={'/product/checkout'}>
          <Badge badgeContent={totalCartItems} color="primary">
            <ShoppingCartIcon />
          </Badge >
        </Link>
      ) : (
        <Badge badgeContent={'0'} color="primary">
          < ShoppingCartIcon />
        </Badge >
      )}
      {/* <Link to="#">{totalCartItems}<i className="linearicons-cart"></i></Link> */}
    </>
  );
}
export default MiniCart;