import React, { useState, useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import { useCart } from 'app/contexts/CartContext';
import {
  Button, Grid
} from '@mui/material';
import DeleteIcon from '@mui/icons-material/Delete';
import * as Yup from 'yup';

import PaymentDetails from './PaymentDetails';
import CartProducts from './CartProducts';


const validationSchema = Yup.object().shape({

});
const CheckoutOrderSummary = (props) => {
  const navigate = useNavigate();
  const { cart, removeItemFromCart } = useCart();
  const [loading, setLoading] = useState(false);
  const [minQty_error, set_minQty_error] = useState(false);
  const [maxQty_error, set_maxQty_error] = useState(false);

  const handleFormSubmit = (values, errors) => {
    props.next(values);
  }

  return (
    <>
      {cart && (
        <Grid container item rowSpacing={3} columnSpacing={3}>
          <Grid item lg={8} sm={12} xs={12}>
            <CartProducts {...props} minQty_error={minQty_error} set_minQty_error={set_minQty_error} maxQty_error={maxQty_error} set_maxQty_error={set_maxQty_error} />
          </Grid>
          <Grid item lg={4} sm={12} xs={12}>
            <PaymentDetails {...props} minQty_error={minQty_error} set_minQty_error={set_minQty_error} maxQty_error={maxQty_error} set_maxQty_error={set_maxQty_error} />
          </Grid>
        </Grid>
      )}
    </>
  );
}

export default CheckoutOrderSummary;
