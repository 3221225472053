
import React from 'react'
import Header from '../Header/Header'
import Footer from '../Footer/Footer'

import "../Partner/termsAndConditions.css"

const RefundPolicy = () => {
    return (
        <>
        <div>
            <Header/>
        
            <div style={{ margin: '1% 5% ' }}>
                <div className="headerModal" style={{ borderRadius: '10px' }}>
                    <div className="headingDiv">
                        <h4 className="headingModal">Refund Policies</h4>
                    </div>

                </div>

                <p className="points">
                    (1) If the consignment received by the customer is expired
                    medicines then vendor will accept the refund or replace the
                    medicine.
                </p>
                <p className="points">
                    (2) If the consignment received by the customer is found to be
                    damage (quality issue from vendor side) then vendor will accept the refund or replacement of the
                    medicines
                </p>
                <p className='points'>
                    Refund process to complete within 7 working days.
                </p>
            </div>
            <Footer/>
            </div>
        </>
    )
}

export default RefundPolicy