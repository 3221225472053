import Loadable from 'app/components/Loadable';
import { lazy } from 'react';
import { authRoles } from '../../../../auth/authRoles';


const Stock = Loadable(lazy(() => import('./Stock')));

const StockRoutes = [
  { path: '/dashboard/stock/stock-list', element: <Stock />, auth: authRoles.admin },
  
];

export default StockRoutes;
