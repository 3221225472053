import React, { useReducer, createContext, useEffect, useContext, ReactNode, useState } from "react";
import axios from 'axios';
import swal from 'sweetalert';
import { useNavigate } from 'react-router-dom';
import useAuth from 'app/hooks/useAuth';

const CartStateContext = createContext({
  getItemQuantity: 0,
  increaseCartQuantity: '',
  decreaseCartQuantity: '',
  removeFromCart: '',

});
// let { isAuthenticated, user } = useAuth();
// 
export function useCart() {
  return useContext(CartStateContext);
}

const CartProvider = ({ children }) => {
  let { isAuthenticated, user } = useAuth();
  const navigate = useNavigate();
  const [loading, setLoading] = useState(false);
  const [isMiniCartOpen, setIsMiniCartOpen] = useState(false);
  const [cartItems, setCartItems] = useState([]);
  const [cart, setCart] = useState(false);
  const [order, setOrder] = useState(false);
  const [recent_order_id, set_recent_order_id] = useState(false);
  const [cod_response, set_cod_response] = useState(false);

  const openMiniCart = () => setIsMiniCartOpen(true);
  const closeMiniCart = () => setIsMiniCartOpen(false);

  useEffect(() => {

    /**
     * Check User logged IN.
     * @author Pavan Ji <dropmail2pavan@gmail.com>
     */
    if (isAuthenticated) {
      fetchCartData();
      // console.log('called');
    }

  }, [isAuthenticated]);

  /**
   * Fetch cart data.
   * @author Pavan Ji <dropmail2pavan@gmail.com>
   */
  const fetchCartData = async () => {
    // setLoading(true);
    try {
      // alert('called')
      // await axios.get('/sanctum/csrf-cookie').then(response => {
      // axios.post('/api/frontend/get-user-cart')
      //   .then(response => {
      //     if (response.data.response_code === 200) {
      //       setCartItems(response.data.data);
      //     } else if (response.data.response_code === 401) {

      //     } else {

      //     }
      //     setLoading(false);
      //   })
      //   .catch(function (error) {
      //     setLoading(false);
      //     console.log(error);
      //   });
      let token = localStorage.getItem('accessToken')
      const config = { headers: { 'x-api-key': token } };
      // axios.get('api/cart/getAll', config)
      axios.get('api/cart/getAll', config)
        .then(response => {
          // console.log(response.data);
          if (response.data.status === 200) {
            // console.log('checking fetchcartdata status code');
            setCart(response.data.data);
         
            if (response.data.data[0].product_carts.length > 0) {
              // console.log('called');
              setCartItems(response.data.data[0].product_carts)
           
            } else {
              setCartItems([])
              setCart(false)
              // navigate('/')
            }

          } else {
            // console.log('no way');
            setCartItems([])
            setCart(false)
            // navigate('/')
          }
          // setLoading(false);
        })
        .catch(function (error) {
          // setLoading(false);
          console.log(error);
        });
      // });
    } catch (e) {
      setLoading(false);
    }
  }

  /**
   * Cart Items.
   * @author Pavan Ji <dropmail2pavan@gmail.com>
   */
  // console.log(cartItems.length);
  const totalCartItems = cartItems.length || 0;

  /**
   * Cart Quantity.
   * @author Pavan Ji <dropmail2pavan@gmail.com>
   */
  const cartQuantity = cartItems.reduce(
    (quantity, item) => item.quantity + quantity, 0
  );

  /**
   * Increase Cart Quantity.
   * @param {*} id  // Product ID
   * @param {*} quantity  // Product quantity
   * @author Pavan Ji <dropmail2pavan@gmail.com>
   */
  async function increaseCartQuantity(id, quantity) {
    // setCartItems(currentItems => {
    //   if (currentItems.find(item => item.id === id) == null) {
    //     return [...currentItems, { id, quantity }];
    //   } else {
    //     return currentItems.map(item => {
    //       if (item.id === id) {
    //         return { ...item, quantity: item.quantity + quantity };
    //       } else {
    //         return item;
    //       }
    //     })
    //   }
    // })
    try {
      setLoading(true);
      // axios.get('/sanctum/csrf-cookie').then(response => {
      let token = localStorage.getItem('accessToken')
      const config = { headers: { 'x-api-key': token } };
      // axios.post('api/cart/create', {
      await axios.post('api/cart/create', {
        cartItem: {
          productId: id,
          quantity: quantity
        }
      },
        config,)
        .then(response => {
          // console.log(response);
          if (response.data.status === 200) {
            fetchCartData();
            swal({
              title: "Good job!",
              text: response.data.message,
              icon: "success",
            });
          } else if (response.status == 504) {
          } else {
            swal({
              title: "Warning!",
              text: response.data.message,
              icon: "warning",
            });
          }
          setLoading(false);
        })
        .catch(function (error) {
          console.error(error);
        });
      // });
    } catch (e) {
      setLoading(false);
    }
  }

  async function increaseSecondCartQuantity(id, quantity) {
    // setCartItems(currentItems => {
    //   if (currentItems.find(item => item.id === id) == null) {
    //     return [...currentItems, { id, quantity }];
    //   } else {
    //     return currentItems.map(item => {
    //       if (item.id === id) {
    //         return { ...item, quantity: item.quantity + quantity };
    //       } else {
    //         return item;
    //       }
    //     })
    //   }
    // })
    try {
      setLoading(true);
      // axios.get('/sanctum/csrf-cookie').then(response => {
      let token = localStorage.getItem('accessToken')
      const config = { headers: { 'x-api-key': token } };
      // axios.post('api/cart/create', {
      await axios.post('api/cart/create', {
        cartItem: {
          productId: id,
          quantity: quantity
        }
      },
        config,)
        .then(response => {
          // console.log(response);
          if (response.data.status === 200) {
            fetchCartData();
            // swal({
            //   title: "Good job!",
            //   text: response.data.message,
            //   icon: "success",
            // });
          } else if (response.status == 504) {
          } else {
            swal({
              title: "Warning!",
              text: response.data.message,
              icon: "warning",
            });
          }
          setLoading(false);
        })
        .catch(function (error) {
          console.error(error);
        });
      // });
    } catch (e) {
      setLoading(false);
    }
  }

  /**
   * Decrease Cart Quantity.
   * @param {*} id  // Product ID
   * @param {*} quantity  // Product quantity
   * @author Pavan Ji <dropmail2pavan@gmail.com>
   */
  function decreaseCartQuantity(id, quantity) {
    setCartItems(currentItems => {
      if (currentItems.find(item => item.id === id)?.quantity === 1) {
        return currentItems.filter(item => item.id !== id);
      } else {
        return currentItems.map(item => {
          if (item.id === id) {
            return { ...item, quantity: item.quantity - quantity };
          } else {
            return item;
          }
        })
      }
    })
  }

  /**
   * Remove Product item from cart.
   * @param {*} id  // Product ID
   * @author Pavan Ji <dropmail2pavan@gmail.com>
   */
  const removeItemFromCart = (id) => {
    try {
      setLoading(false);
      // axios.get('/sanctum/csrf-cookie').then(response => {
      let token = localStorage.getItem('accessToken')
      const config = { headers: { 'x-api-key': token } };
      // axios.delete('api/cart/deleteProd', {
      axios.delete('api/cart/deleteProd', {
        headers: config.headers,
        data: {
          productId: id
        }
      })
        .then(response => {
          // console.log('delete prod from cart api', response);
          if (response.data.status === 200 || response.data.status === 202) {
            // console.log('checking if remove item from cart is sending right status code', `${status}`);
            fetchCartData();
            swal({
              title: "Good Job!",
              text: 'Cart Updated Successfully',
              icon: "success",
            });
          } else if (response.data.status == 504) {
          } else {
            swal({
              title: "Warning!",
              text: response.data.message,
              icon: "warning",
            });
          }
          setLoading(false);
        })
        .catch(function (error) {
          console.error(error);
        });
      // });
    } catch (e) {
      setLoading(false);
    }
  }

  /**
   * Place order of selected products in to a Cart.
   * @author Pavan Ji <dropmail2pavan@gmail.com>
   */
  const orderPlaced = (payment_method) => {
    setLoading(true);
    // alert('placed')
    try {
      // axios.get('/sanctum/csrf-cookie').then(response => {
      const token = localStorage.getItem('accessToken')
      const config = { headers: { "x-api-key": token } };
      // console.log(token);
      // axios.post('api/cart/orderProcess',{},config)
      axios.post('api/cart/orderProcess', { payment_type: payment_method }, config)
        .then(response => {
          // console.log(response.data);
          if (response.data.status === 200) {
            getOrder(response.data.order_id);
            if (payment_method == 'COD' || payment_method == 'CREDIT') {
              codOrderPlaced(payment_method, response.data.order_id)
            }
            //  setCartItems([]);

          } else {
          }
          setLoading(false);
        })
        .catch(function (error) {
          console.log(267, error);
          setLoading(false);
        });
      // });
    } catch (e) {
      console.log(e);
      setLoading(false);
    }
  }

  /**
   * Get Order detail from server 
   * @param {*} order_id 2103%23HEGAN-202333
   * @author Pavan Ji <dropmail2pavan@gmail.com>
   */
  const getOrder = (order_id) => {
    // setLoading(true);
    console.log('called', order_id);
    try {
      // axios.get('/sanctum/csrf-cookie').then(response => {
      if (order_id) {
        const token = localStorage.getItem('accessToken')
        const config = { headers: { "x-api-key": token } };
        // console.log(config, order_id);
        // axios.get(`api/cart/getOrderStatus/${encodeURIComponent(order_id)}`,config)
        axios.get(`api/cart/getOrderStatus/${encodeURIComponent(order_id)}`, config)
          .then(response => {
            console.log(response);
            if (response.data.status === 200) {
              setOrder(response.data.data);
              // navigate('/product/checkout/order-success/' + encodeURIComponent(order_id));
            } else {
            }
            setLoading(false);
          })
          .catch(function (error) {
            console.error(error);
            setLoading(false);
          });
      }
      // });
    } catch (e) {
      console.log(307, e);
      setLoading(false);
    }
  }

  const getCodAvailability = () => {
    setLoading(true);

    try {
      // axios.get('/sanctum/csrf-cookie').then(response => {

      const token = localStorage.getItem('accessToken')
      const config = { headers: { "x-api-key": token } };

      axios.post(`api/cart/checkCodAvailable`, {}, config)
        .then(response => {
          console.log(response.data);
          if (response.data.status === 200) {
            set_cod_response(response.data);
            return cod_response;

          } else {
          }

        })
        .catch(function (error) {
          console.error(error);
          // setLoading(false);
        });

      // });
    } catch (e) {
      console.log(307, e);
      // setLoading(false);
    }
  }

  const codOrderPlaced = (payment_method, orderId) => {
    // setLoading(true);
    // alert(payment_method, orderId)
    try {
      // axios.get('/sanctum/csrf-cookie').then(response => {
      const token = localStorage.getItem('accessToken')
      const config = { headers: { "x-api-key": token } };

      axios.post('api/cart/cash_credit_order_process', {
        payment_type: payment_method, status: "Completed",
        paymentStatus: "Pending", orderId: orderId,
      }, config)
        .then(response => {
          // console.log(response.data);
          if (response.data.status === 200) {
            navigate('/dashboard/order-history/purchase/record')
          } else {
          }
          // setLoading(false);
        })
        .catch(function (error) {
          console.log(267, error);
          // setLoading(false);
        });
      // });
    } catch (e) {
      console.log(e);
      // setLoading(false);
    }
  }



  /**
   * Rander Cart Provider
   * @param {*} order_id 2103%23HEGAN-202333
   * @author Pavan Ji <dropmail2pavan@gmail.com>
   */
  return (
    <CartStateContext.Provider value={{
      openMiniCart,
      closeMiniCart,
      cartItems,
      setCartItems,
      cart,
      totalCartItems,
      cartQuantity,
      increaseCartQuantity,
      decreaseCartQuantity,
      removeItemFromCart,
      orderPlaced,
      order,
      getOrder,
      increaseSecondCartQuantity,
      set_recent_order_id,
      recent_order_id,
      getCodAvailability,
      cod_response,
      set_cod_response,
      codOrderPlaced,
      fetchCartData
    }}>
      {children}
    </CartStateContext.Provider>

  );
};

export default CartProvider;