import React from 'react'

import Header from '../Header/Header'
import Footer from '../Footer/Footer'
import { Paper, } from '@mui/material'
import Button from '@mui/material/Button';
import { Link, } from 'react-router-dom';


import dashBoardImg from './dashboard new.png'
import phone_features from './pfone_features.png'
import './beOurPartner.css'


const BeOurPartner = () => {

    const containerStyle = {
        fontFamily: 'Arial, sans-serif',
        margin: 0,
        padding: '20px',
        textAlign: 'justify',

    };

    const paragraphStyle = {
        marginBottom: '10px',
        fontWeight: 'bold',
        textAlign: 'justify',

    };

    return (
        <>

            <Header />


            <Paper style={{ margin: '2% 10% 5% 10% ' }} elevation={5}>

                <div className='outer_bop_container_one' style={{ ...containerStyle }}>
                    <p style={paragraphStyle}>
                        <h4 style={{ color: '#015998', ...paragraphStyle }}>Benefits:</h4>
                        <p style={{ color: '#015998', ...paragraphStyle ,marginBottom:'0',}}>No Listing Fee</p>
                        <p style={paragraphStyle}>Attract more sellers! Enjoy the benefits of our platform with No Listing Fees. Join us and list your products hassle-free.</p>
                        {/* <p style={{ color: '#015998', ...paragraphStyle }}>No Registration Fee</p>
                        <p style={paragraphStyle}>Join us without any cost! Experience our platform with No Registration Fees. Sign up today and explore the possibilities.</p> */}
                        <p style={{ color: '#015998', ...paragraphStyle,marginBottom:'0', }}>Buy Now Pay Later</p>
                        <p style={paragraphStyle}>Shop now, pay later! Enjoy the flexibility of our Buy Now, Pay Later option, allowing you to make purchases and settle payments at your convenience.</p>
                        <p style={{ color: '#015998', ...paragraphStyle,marginBottom:'0', }}>Active Customer Support</p>
                        <p style={paragraphStyle}>Your satisfaction is our priority. Experience proactive assistance with our Active Customer Support. Reach out anytime, we're here to help.</p>
                        <p style={{ color: '#015998', ...paragraphStyle ,marginBottom:'0',}}>Low Commission Charges</p>
                        <p style={paragraphStyle}>Maximize your earnings! Benefit from our platform's Low Commission Charges. Keep more of your profits with our competitive fee structure.</p>
                        <p style={{ color: '#015998', ...paragraphStyle,marginBottom:'0', }}>Access To Verified Customers</p>
                        <p style={paragraphStyle}>Connect with confidence. Gain access to a network of Verified Customers, ensuring secure and reliable transactions on our platform.</p>
                        <p style={{ color: '#015998', ...paragraphStyle ,marginBottom:'0',}}>Account Settlement Within 7 Days</p>
                        <p style={paragraphStyle}>Get paid faster! Experience swift account settlements within 7 days. Your earnings are our priority, ensuring timely and hassle-free transactions.</p>
                    </p>

                    <img src={phone_features} alt='dashboard mockup' className='top-right-image' />
                </div>
                <div className='outer_bop_container_two' style={{ ...containerStyle }}>
                    <img src={dashBoardImg} alt='dashboard mockup' className='top-right-image' />
                    <p style={paragraphStyle}>
                        <h4 style={{ color: '#015998', ...paragraphStyle }}>Features:</h4>
                        <p style={{ color: '#015998', ...paragraphStyle,marginBottom:'0', }}>Account Analytics</p>
                        <p style={paragraphStyle}>Unlock insights into your account activities! Explore detailed Account Analytics to gain valuable data-driven insights into your performance and usage patterns.</p>
                        <p style={{ color: '#015998', ...paragraphStyle ,marginBottom:'0',}}>Live Inventory Tracking</p>
                        <p style={paragraphStyle}>Stay in control with Live Inventory Tracking! Monitor stock levels in real time, ensuring accurate and up-to-date information for smarter inventory management.</p>
                        <p style={{ color: '#015998', ...paragraphStyle,marginBottom:'0', }}>Sell & Purchase Record</p>
                        <p style={paragraphStyle}>Track all your transactions effortlessly! Our platform offers detailed Sell and Purchase Records, ensuring accurate documentation of all your buying and selling activities for better financial management.</p>
                        <p style={{ color: '#015998', ...paragraphStyle,marginBottom:'0', }}>Bulk & Single Entry</p>
                        <p style={paragraphStyle}>Effortless inventory management! Seamlessly upload items in bulk or individually with our Bulk and Single Inventory Upload feature, simplifying the process of adding products to your inventory.</p>


                    </p>


                </div>
                <Link to="/partner-register" title="Register">
                    <Button variant='contained' style={{ width: '100%' }} >Register Now</Button>
                </Link>
            </Paper>


            <Footer />

        </>
    )
}

export default BeOurPartner