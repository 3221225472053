import React, { useEffect, useState } from 'react';

import {
  Link, NavLink, useLocation, useNavigate,
} from 'react-router-dom';
import useAuth from 'app/hooks/useAuth';
import swal from 'sweetalert';
import axios from 'axios';
import Moment from 'moment';
import Carousel from 'react-material-ui-carousel'
import { Paper, Button, Grid, Divider } from '@mui/material'


import OwlCarousel from 'react-owl-carousel';

import 'owl.carousel/dist/assets/owl.carousel.css';
import 'owl.carousel/dist/assets/owl.theme.default.css';
import './hotSellerSlider.css'
import heganlogo from './heganlogo.png'

function HotSellersSlider() {

  const [products, setProducts] = useState(false);
  const [loading, setLoading] = useState(false);
  const [page, set_page] = useState(1);
  const location = useLocation();
  let { logout, user, isAuthenticated } = useAuth();

  useEffect(() => {

    if (isAuthenticated) {
      fetchProductData();
    } else {
      fetchData();
    }
  }, [location]);




  const getNextPaginatedProductData = () => {
    console.log(page);
    let nextPage = page + 1;
    set_page(nextPage)
    if (isAuthenticated) {
      fetchProductData(nextPage);
    } else {
      fetchData(nextPage);
    }
  }

  const getPrevPaginatedProductData = () => {
    console.log(page);

    let prevPage = page - 1;
    set_page(prevPage)
    if (isAuthenticated) {
      fetchProductData(prevPage);
    } else {
      fetchData(prevPage);
    }
  }

  /**
   * Fetching data from server.
   */
  const getProductsToShow = () => {
    const screenWidth = window.innerWidth;
    if (screenWidth >= 800) {
      return 6;
    } else if (screenWidth >= 500) {
      return 3;
    } else {
      return 2;
    }
  };

  const numberOfProducts = getProductsToShow();

  const fetchData = async (page_no = 1) => {
    setLoading(true);
    try {
      // await axios.get('/sanctum/csrf-cookie').then(response => {
      axios.post('api/admin/getAllProducts', {
        page: page_no, pageSize: numberOfProducts,
      })
        .then(response => {
          console.log(response);
          if (response.data.status === 200) {
            setProducts(response.data.data);
          } else {
          }
          setLoading(false);
        })
        .catch(function (error) {
          setLoading(false);
          console.log(error);
        });
      // });
    } catch (e) {
      setLoading(false);
    }
  }

  /**
   * Fetching data from server.
   */



  const fetchProductData = async (page_no = 1) => {
    setLoading(true);
    try {
      // await axios.get('/sanctum/csrf-cookie').then(response => {
      // axios.get('api/user/product/getAll')
      axios.post('api/admin/getAllProducts', {
        page: page_no, pageSize: numberOfProducts,
      })
        .then(response => {
          if (response.data.status === 200) {
            setProducts(response.data.data);
          } else {
          }
          setLoading(false);
        })
        .catch(function (error) {
          setLoading(false);
          console.log(error);
        });
      // });
    } catch (e) {
      // setLoading(false);
    }
  }


  const options = {
    nav: true,
    dots: false,
    autoplay: false,
    loop: true,
    margin: 20,
    responsiveClass: true,
    // navText: ['<i class="ion-ios-arrow-left"></i>', '<i class="ion-ios-arrow-right"></i>'],
    smartSpeed: 1000,
    responsive: {
      0: {
        items: 2,

      },
      400: {
        items: 2,
      },
      600: {
        items: 2,
      },
      700: {
        items: 3,
      },
      1000: {
        items: 6,

      }
    },
  };
  return (
    <>
      <div className="section small_pt prodstyel marg-btm30">
        <div >
          <div className="row">
            <div className="col-md-12">
              <div className="heading_tab_header">
                <h3 style={{color:'#015998'}}>Hot Sellers<Link to="/categories" className="vall">VIEW ALL</Link></h3>
              </div>
            </div>
          </div>

          <div className="row">
            <div className="col-md-12">


              <Carousel indicators={false}
                next={(next, active) => getNextPaginatedProductData()}
                prev={(prev, active) => getPrevPaginatedProductData()}
                autoPlay={false} // Enable auto-play
                animation="slide" // Set the animation to slide
                duration={1500}
              >
                {products &&
                  Array.from({ length: Math.ceil(products.length / numberOfProducts) }).map((_, rowIndex) => (
                    <Grid container key={rowIndex} spacing={2}>
                      {products.slice(rowIndex * numberOfProducts, rowIndex * numberOfProducts + numberOfProducts).map((row, index) => (
                        <Grid item xs={6} sm={4} md={4} lg={2} key={index}>
                          <Paper key={'pa' + index} style={{ minHeight: '300px', }}>


                            {/* <div></div> */}
                            <Link to={`product/details/${row.id}`} className="hover_effect1" title={row.productName}>
                              <div className='productImage_div'>
                                <img src={row.uploadImg ? row.uploadImg : heganlogo} alt={`Product ${index + 1}`} />
                              </div>
                            </Link>
                            <div className="product--container">

                              <img src={`${process.env.PUBLIC_URL}/assets/images/frontend/hint.png`} alt="Discount" />

                              <div className='hint-pre--badge'>
                                <span>{(row.marginPercentage) ? row.marginPercentage : '0'}%</span>
                              </div>
                            </div>

                            <div className='product-info' style={{ margin: '-78px 0 0 0' }}>
                              <p className='product-name' style={{ color: '#015998', fontWeight: 'bold', margin: '0', }} key={"h2" + index}>{row.productName}</p>
                             
                              <p style={{ color: 'black ', fontWeight: 'bold', margin: '0', }}>MRP ({row.mrp})</p>
                              <p style={{ color: 'black ', fontWeight: 'bold', margin: '0', }}>Net Rate  {process.env.REACT_APP_CURRENCY}. {(row.netRate * 1 / row.minQty).toFixed(2)}</p>
                            </div>


                          </Paper>

                        </Grid>
                      ))}
                    </Grid>
                  ))
                }
              </Carousel>

            </div>
          </div>
        </div>
      </div >
    </>
  );
}
export default HotSellersSlider;
