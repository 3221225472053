import Loadable from 'app/components/Loadable';
import { lazy } from 'react';
import { authRoles } from '../../auth/authRoles';

import MyProfile from './profile/MyProfile';
import UpdateProfile from './profile/UpdateProfile';

const Analytics = Loadable(lazy(() => import('./Analytics')));

const dashboardRoutes = [
  { path: '/dashboard', element: <Analytics />, auth: authRoles.admin },
  { path: '/dashboard/profile', element: <MyProfile />, auth: authRoles.admin },
  { path: '/dashboard/profile/edit/:user_id', element: <UpdateProfile />, auth: authRoles.admin },


];

export default dashboardRoutes;
