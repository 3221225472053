import React, { useEffect, useState } from 'react';

import {
  Link, NavLink, useLocation, useNavigate,
} from 'react-router-dom';

import swal from 'sweetalert';
import axios from 'axios';
import OwlCarousel from 'react-owl-carousel';
import Carousel from 'react-material-ui-carousel'
import { Paper, Button, Grid, Divider } from '@mui/material'

import 'owl.carousel/dist/assets/owl.carousel.css';
import 'owl.carousel/dist/assets/owl.theme.default.css';
// import './hotSellerSlider.css'
import './exploreCategories.css'
import heganlogo from './heganlogo.png'
import smi from './sm.jpeg'
import veti from './veti.jpeg'
import alli from './alli.jpeg'
import ayuri from './ayuri.jpeg'
import geni from './geni.jpeg'


function ExploreCategoriesSlider() {

  const [categories, setCategories] = useState(false);
  const [otc, setOtc] = useState(false);
  const [veterinary, setVeterinary] = useState(false);
  const [allopathic, setAllopathic] = useState(false);
  const [sm, setSm] = useState(false);
  const [ayurvedic, setAyurvedic] = useState(false);
  const [generic, setGeneric] = useState(false);



  const [loading, setLoading] = useState(false);
  useEffect(() => {
    fetchData();
  }, []);

  /**
   * Fetching data from server.
   */
  const fetchData = async () => {
    setLoading(true);
    try {
      // await axios.get('/sanctum/csrf-cookie').then(response => {
      // axios.get('api/admin/medicineCategory/getList')
      //   .then(response => {
      //     if (response.data.status === 200) {
      //       setCategories(response.data.data);
      //     } else if (response.data.response_code === 401) {
      //     } else {
      //     }
      //     setLoading(false);
      //   })
      //   .catch(function (error) {
      //     setLoading(false);
      //     console.log(error);
      //   });
      // });
      axios.post('api/admin/subCategory/getAllMarginCategory', {
      })
        .then(response => {
          // console.log(response)
          if (response.data.status === 200) {
            // setOtc(response.data.data);
            // console.log(response.data.category[0].productImage);
            // console.log(response.data.category[1].productImage);
            // console.log(response.data.category[2].productImage);
            // console.log(response.data.category[3].productImage);
            // console.log(response.data.category[4].productImage);

            setGeneric(response.data.category[0].productImage);
            setOtc(response.data.category[1].productImage);
            setVeterinary(response.data.category[2].productImage);
            setAllopathic(response.data.category[3].productImage);
            setSm(response.data.category[4].productImage);
          } else if (response.data.response_code === 401) {
          } else {
          }
          setLoading(false);
        })
        .catch(function (error) {
          setLoading(false);
          console.log(error);
        });



    } catch (e) {
      // setLoading(false);
    }
  }


  return (
    <>
      <div className="section small_pt prodstyel prodcatl marg-btm30">
        <div >
          <div className="row">
            <div className="col-md-12">
              <div className="heading_tab_header">
                <h3 style={{ color: '#015998' }}>Shop By Margin %age</h3>
              </div>
            </div>
          </div>
          <div className="row" >
            <div className="col-md-12">

              <div className='categories-outer-div-mp'>
                {/* <Carousel
                 autoPlay={true}
                 animation="slide"
                 duration={1500}> */}
                <div className='categories-inner-div-formargin-mp  ' style={{ margin: '0' }}  >
                  <div className="item " >
                    <div className="product">
                      <div className="product_wrap">
                        <div className="sale_banner" style={{ border: '2px solid #015998' }} >
                          <Carousel indicators={false}
                            autoPlay={true}
                            animation="slide"
                            duration={1500}>
                            {generic && generic.map((item, idx) => (


                              <Link to={`/margin/33c784df-2c8c-4ca0-a21b-b8ef18cdf9d0`} className="hover_effect1" key={idx + 1}>
                                <div className='productImage_div'>
                                  <img src={item} key={'gen' + idx} />
                                </div>

                              </Link>

                            ))}
                          </Carousel>
                          <hr/>
                          <h6>
                            <Link to={`/margin/33c784df-2c8c-4ca0-a21b-b8ef18cdf9d0`} className="hover_effect1">
                               80% - 99%
                            </Link>

                          </h6>
                        </div>
                        {/* <div className="product_cont">
                          <h6>
                            <Link to={`/categories/Generic`} className="hover_effect1">
                              80%-99%
                            </Link>
                          </h6>
                        </div> */}
                      </div>
                    </div>
                  </div>
                </div>

                <div className=' categories-inner-div-formargin-mp'>
                  <div className="item" >
                    <div className="product">
                      <div className="product_wrap">
                        <div className="sale_banner" style={{ border: '2px solid #015998' }}>
                          <Carousel indicators={false}
                            autoPlay={true}
                            animation="slide"
                            duration={1500}>
                            {otc && otc.map((item, idx) => (


                              <Link to={`/margin/acbbfec3-d035-45fb-b084-23adfc00e01c`} className="hover_effect1" key={idx + 1 + 'he'}>
                                <div className='productImage_div'>
                                  <img src={item} key={'otc' + idx} />
                                </div>

                              </Link>


                            ))}
                          </Carousel>
                          <hr/>
                          <h6>

                            <Link to={`/margin/acbbfec3-d035-45fb-b084-23adfc00e01c`} className="hover_effect1">
                               60% - 79%
                            </Link>

                          </h6>
                        </div>

                      </div>
                    </div>
                  </div>
                </div>

                <div className=' categories-inner-div-formargin-mp '>
                  <div className="item" >
                    <div className="product">
                      <div className="product_wrap">
                        <div className="sale_banner" style={{ border: '2px solid #015998' }}>
                          <Carousel indicators={false}
                            autoPlay={true}
                            animation="slide"
                            duration={1500}>
                            {veterinary && veterinary.map((item, idx) => (


                              <Link to={`/margin/4d9865e1-4f0b-42c7-95b5-aa94756ae377`} className="hover_effect1" key={idx + 'hee'}>
                                <div className='productImage_div'>
                                  <img src={item} key={'vet' + idx} />
                                </div>

                              </Link>

                            ))
                            }
                          </Carousel>
                          <hr/>
                          <h6>

                            <Link to={`/margin/4d9865e1-4f0b-42c7-95b5-aa94756ae377`} className="hover_effect1">
                               40% - 59%
                            </Link>

                          </h6>
                        </div>

                      </div>
                    </div>
                  </div>
                </div>

                <div className=' categories-inner-div-formargin-mp '>
                  <div className="item" >
                    <div className="product">
                      <div className="product_wrap">
                        <div className="sale_banner" style={{ border: '2px solid #015998' }}>
                          <Carousel indicators={false}
                            autoPlay={true}
                            animation="slide"
                            duration={1500}>
                            {allopathic && allopathic.map((item, idx) => (


                              <Link to={`/margin/f94fd417-a40c-40db-9934-32789cd32b9d`} className="hover_effect1" key={idx + 'heee'}>
                                <div className='productImage_div'>
                                  <img src={item} key={'all' + idx} />
                                </div>

                              </Link>

                            ))
                            }
                          </Carousel>
                          <hr/>
                          <h6>

                            <Link to={`/margin/f94fd417-a40c-40db-9934-32789cd32b9d`} className="hover_effect1">
                               20% - 39%
                            </Link>
                          </h6>
                        </div>

                      </div>
                    </div>
                  </div>
                </div>

                <div className=' categories-inner-div-formargin-mp '>
                  <div className="item" >
                    <div className="product">
                      <div className="product_wrap">
                        <div className="sale_banner" style={{ border: '2px solid #015998' }}>
                          <Carousel indicators={false}
                            autoPlay={true}
                            animation="slide"
                            duration={1500}>
                            {sm && sm.map((item, idx) => (


                              <Link to={`/margin/1886cbeb-0af3-4a7d-b787-f82b0969df17`} className="hover_effect1" key={idx + 'heee'}>
                                <div className='productImage_div'>
                                  <img src={item} key={'sm' + idx} />
                                </div>

                              </Link>

                            ))
                            }
                          </Carousel>
                          <hr/>
                          <h6>

                            <Link to={`/margin/1886cbeb-0af3-4a7d-b787-f82b0969df17`} className="hover_effect1">
                               0% - 19%
                            </Link>
                          </h6>
                        </div>

                      </div>
                    </div>
                  </div>
                </div>

                {/* </Carousel> */}
              </div >

            </div >
          </div >

        </div >
      </div >
    </>
  );
}
export default ExploreCategoriesSlider;
