import Loadable from 'app/components/Loadable';
import { lazy } from 'react';
import { authRoles } from '../../../../auth/authRoles';

const PurchaseList = Loadable(lazy(() => import('./PurchaseList')));
const ViewPurchaseDetails = Loadable(lazy(() => import('./ViewPurchaseDetails')));
const SalesList = Loadable(lazy(() => import('./SalesList')));
const ViewSaleDetails = Loadable(lazy(() => import('./ViewSaleDetails')));

const OrderHistoryRoutes = [
  // { path: '/dashboard/order-history/purchase/record', element: <PurchaseList />, auth: authRoles.admin },
  // { path: '/dashboard/order-history/purchase-details/:row_id', element: <ViewPurchaseDetails />, auth: authRoles.admin },
  { path: '/dashboard/order-history/sales/record', element: <SalesList />, auth: authRoles.admin },
  { path: '/dashboard/order-history/sale-details/:row_id', element: <ViewSaleDetails />, auth: authRoles.admin },
];

export default OrderHistoryRoutes;
