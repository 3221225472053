import React from "react";

import HomePage from 'app/views/frontend/HomePage/HomePage';
import PartnerLogin from 'app/views/frontend/Partner/PartnerLogin';
import PartnerLoginOtp from 'app/views/frontend/Partner/PartnerLoginOtp';
import PartnerRegister from 'app/views/frontend/Partner/PartnerRegister';
import AboutUs from "./AboutUs/AboutUs";
import ContactUs from "./ContactUs/ContactUs";
import BrandingPage from "./BrandingPage/BrandingPage";
import TermsAndConditions from "./TermsAndConditions/TermsAndConditions";
import RefundPolicy from "./TermsAndConditions/RefundPolicy";
import PrivacyPolicy from "./TermsAndConditions/PrivacyPolicy";
import BeOurPartner from "./BeOurPartner/BeOurPartner";

const FrontendRoutes = [
  { path: '/', element: <HomePage /> },
  { path: '/login', element: <PartnerLogin /> },
  { path: '/login-otp', element: <PartnerLoginOtp /> },
  { path: '/partner-register', element: <PartnerRegister /> },
  { path: '/about-us', element: <AboutUs /> },
  { path: '/contact-us', element: <ContactUs /> },
  { path: '/branding-page', element: <BrandingPage /> },
  { path: '/terms-conditions', element: <TermsAndConditions /> },
  { path: '/refund-policy', element: <RefundPolicy /> },
  { path: '/privacy-policy', element: <PrivacyPolicy /> },
  { path: '/be-our-partner', element: <BeOurPartner /> },
];

export default FrontendRoutes;
