import React from 'react'
import { Button } from '@mui/material'
import playBtn from '../Footer/playBtn.png'
import './downloadHegan.css'
const DownloadHeganApp = ({ openD, setOpenD }) => {
    const containerStyle = {
        fontFamily: 'Arial, sans-serif',
        margin: 0,
        padding: '20px',
        textAlign: 'justify',
        color: 'white',
    };

    const paragraphStyle = {
        marginBottom: '10px',
        fontWeight: 'bold',
        textAlign: 'justify',
        color: 'white',
    };



    return (
        <>
            {openD &&
                <div className='outer_dh_div' style={{ backgroundColor: '#015998', border: '1px solid white', boxShadow: ' 0px 2px 4px rgba(0, 0, 0, 0.25)', borderRadius: '6px', position: 'absolute', marginRight: '14px', maxHeight: '400px', overflow: 'auto', }} >
                    <div style={{ ...containerStyle, display: 'flex', flexDirection: 'column', justifyContent: 'center', alignItems: 'center', }} >
                        <p style={paragraphStyle}>Discover the ultimate convenience with our app! Embrace the freedom to Buy Now, Pay Later, allowing you to shop instantly and settle payments at your convenience. But that's not all – our app also offers effortless UPI transactions for seamless and secure payments. Say goodbye to complexities and hello to simplified shopping experiences. Download the app now to enjoy the ease of flexible payments, quick UPI transactions, and the freedom to shop on your terms!
                        </p>
                        <p style={paragraphStyle}> Simplify your shopping journey by clicking the Google Play button below to download. </p>
                        <a href="https://play.google.com/store/apps/details?id=com.hegan.in" >
                            <img src={playBtn} alt='google_play_btn' />
                        </a>
                        <Button variant="contained" color="error" sx={{ margin: '4px 0 2px 0', }} onClick={() => { setOpenD(false) }}>
                            Close
                        </Button>
                    </div>

                </div>}
        </>
    )
}

export default DownloadHeganApp