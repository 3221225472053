import { useState, useEffect } from "react";
import { Link, useParams, NavLink, useNavigate } from 'react-router-dom';
import { Breadcrumb, SimpleCard } from "app/components";
import { LoadingButton } from '@mui/lab';
import { Formik, Form, Field, FieldArray } from 'formik';
import * as Yup from 'yup';
import axios from 'axios';
import swal from 'sweetalert';
import {
  Card,
  Divider,
  CardContent,
  CardHeader,
  Box,
  Grid,
  Icon,
  IconButton,
  Button,
  styled,
  TextField,
  useTheme,
  RadioGroup, FormControlLabel, Radio

} from "@mui/material";


const Item = styled("div")(({ theme }) => (
  {
    marginBottom: "5px",
    fontSize: '16px',
    fontWeight: '400',
    color: '#484747'
  }
));
const ItemContent = styled("div")(({ theme }) => (
  {
    marginBottom: "15px",
    fontSize: '16px',
    fontWeight: '600',
  }
));

const Container = styled("div")(({ theme }) => (
  {
    margin: "30px",
    [theme.breakpoints.down("sm")]: { margin: "16px" },
    "& .breadcrumb": {
      marginBottom: "30px",
      [theme.breakpoints.down("sm")]: { marginBottom: "16px" },
    },
  }
));
const ContentBox = styled(Box)(() => ({
  height: '100%',
  padding: '32px',
  position: 'relative',
  // background: 'rgba(0, 0, 0, 0.01)',
}));

// form field validation schema
const validationSchema = Yup.object().shape({
  // status: Yup.string()
  //   .required('User Accton is required!'),
  status: Yup.string().when('status', {
    is: (status) => status === 'pending',
    then: Yup.string().required('Please checked Approved or Rejected action!'),
    otherwise: Yup.string(),
  }),
}, [['status', 'status']]);

const MyProfile = () => {

  const { user_id } = useParams();
  const theme = useTheme();
  const navigate = useNavigate();
  const [user, setUser] = useState(false);
  const [loading, setLoading] = useState(false);
  const [userId, setUserId] = useState('');

  const fetchUserId = () => {
    const data = localStorage.getItem('user');

    const userData = JSON.parse(data);
    // console.log(userData.id);
    setUserId(userData.id);
  }


  useEffect(() => {
    fetchData();
    fetchUserId();

  }, []);

  /**
   * Fetching data from server.
   */
  const fetchData = async () => {
    // alert('hi')
    setLoading(true);
    try {
      // await axios.get('/sanctum/csrf-cookie').then(response => {
      // const id='9dca0ce4-79e9-4190-b0d4-31012b80ca90';
      const userData = JSON.parse(localStorage.getItem('user'));
      // console.log(userData);

      axios.get(`api/user/getUserByid/${userData.id}`)
        .then(response => {
          // console.log(95,response);
          if (response.data.status === 200) {
            setUser(response.data.data);
          } else if (response.data.response_code === 401) {

          } else {

          }
          setLoading(false);
        })
        .catch(function (error) {
          setLoading(false);
          console.error(error);
        });
      // });
    } catch (e) {
      setLoading(false);
    }
  }


  const DeleteUserAccountFunction = async () => {
    //   let result1 = await AsyncStorage.getItem('token');
    //   let result = await fetch('api/user/delete',{
    //    method: 'delete',
    //    headers: {
    //      'Content-Type': 'application/json',
    //      'x-api-key': result1,
    //    },
    //  });
    //  result = await result.json();
    //     alert(result.message);
    try {
      let token = localStorage.getItem('accessToken')
      const config = { headers: { 'x-api-key': token } };

      axios.delete('api/user/delete', config).then((response) => {
        if (response.data.status === 200) {
          swal({
            title: "Good job!",
            text: "Profile Deleted Successfully ",
            icon: "success",
          });
          navigate('/');
        } else if (response.status === 401) {

        }
      })
        .catch(function (error) {
          console.error(error);
        })
    } catch (error) {
      console.log(error);
    }


  }

  const handleFormSubmit = (values, errors) => {

    try {
      if (values.status == 'pending') {
        swal({
          title: "Warning!",
          text: 'Please select Approved or Rejected approvel action!',
          icon: "warning",
        });
        return false;
      }
      setLoading(true);
      // axios.get('/sanctum/csrf-cookie').then(response => {
      axios.post('/api/admin/user-update-status-commission', {
        user_id: values.id,
        status: values.status,
        userCategories: values.userCategories,
      })
        .then(response => {
          if (response.data.status === 200) {
            setUser(response.data.data);
            swal({
              title: "Good job!",
              text: response.data.message,
              icon: "success",
            });
            // navigate('/');
          } else {
            errors.setErrors(response.data.errors);
          }
          setLoading(false);
        })
        .catch(function (error) {
          console.error(error);
        });
      // });
    } catch (e) {
      console.log(e);
      setLoading(false);
    }
  };

  return (
    <>
      <Container>
        <Box className="breadcrumb">
          <Breadcrumb routeSegments={[{ name: "dashboard", path: "/dashboard" }, { name: "My Profile" }]} />
        </Box>

        <Card title="My Profile">
          <ContentBox>
            <CardHeader
              title="My Profile"
              titleTypographyProps={{
                variant: "h4",
                fontSize: '18px',
                fontWeight: '600',
                // textTransform: 'uppercase',
                color: theme.palette.primary.main
              }}
              action={
                <>
                  {/* <Button title="Edit" component={Link}
                    to={`/dashboard/profile/edit/${userId}`}
                    color="info" variant="outlined" size="small" sx={{ m: .5 }}>
                    Edit
                  </Button> */}
                  <Button title="Back" component={Link}
                    to={`/dashboard`}
                    color="primary" variant="outlined" size="small" sx={{ m: .5 }}>
                    Back
                  </Button>
                  {/* <Button title="Back"
                    onClick={DeleteUserAccountFunction}
                    color="info" variant="contained" size="small" sx={{ m: .5 }}>
                    Delete Profile
                  </Button> */}
                </>
              }
            />
            <Divider />
            <CardContent>
              <Box width="100%" overflow="auto">
                {user && (
                  <>
                    <Grid container>
                      <Grid item sm={6} xs={12} lg={3}>
                        <Item>First Name</Item>
                        <TextField value={user.firstName ? user.firstName : '-'} />
                      </Grid>
                      <Grid item sm={6} xs={12} lg={3}>
                        <Item>Last Name</Item>
                        <TextField value={user.lastName ? user.lastName : '-'} />
                      </Grid>

                      <Grid item sm={6} xs={12} lg={3}>
                        <Item>Mobile No.</Item>
                        <TextField value={user.mobile ? user.mobile : '-'}/>
                      </Grid>
                      <Grid item sm={6} xs={12} lg={3}>
                        <Item>Email</Item>
                        <TextField value={user.email ? user.email : '-'}/>
                      </Grid>
                      <Grid item sm={6} xs={12} lg={3}>
                        <Item>Firm Name</Item>
                        <TextField value={user.firmName ? user.firmName : '-'}/>
                      </Grid>
                      <Grid item sm={6} xs={12} lg={3}>
                        <Item>User Code</Item>
                        <TextField value={user.userName ? user.userName : '-'}/>
                      </Grid>
                      <Grid item sm={6} xs={12} lg={3}>
                        <Item>Address</Item>
                        <TextField value={user.address ? user.address : '-'}/>
                      </Grid>
                      <Grid item sm={6} xs={12} lg={3}>
                        <Item>State</Item>
                        <TextField value={user.state ? user.state : '-'}/>
                      </Grid>
                      <Grid item sm={6} xs={12} lg={3}>
                        <Item>Pin Code</Item>
                        <TextField value={user.pinCode ? user.pinCode : '-'}/>
                      </Grid>
                    </Grid>
                    <Divider />
                    <br />
                    <Grid container>
                      <Grid item sm={6} xs={12} lg={3}>
                        <Item>DL No 1</Item>
                        <TextField value={user.dl_no_20b ? user.dl_no_20b : '-'}/>
                      </Grid>
                      <Grid item sm={6} xs={12} lg={3}>
                        <Item>DL No 2</Item>
                        <TextField value={user.dl_no_21b ? user.dl_no_21b : '-'}/>
                      </Grid>
                      <Grid item sm={6} xs={12} lg={3}>
                        <Item>GST No.</Item>
                        <TextField value={user.gst_no ? user.gst_no : '-'}/>
                      </Grid>
                      <Grid item sm={6} xs={12} lg={3}>
                        <Item>FSSAI No.</Item>
                        <TextField value={user.fssai_no ? user.fssai_no : '-'}/>
                      </Grid>
                    </Grid>
                    <Divider />
                    <br />
                    <Grid item sm={6} xs={12} lg={3}>
                      <Item>Register As</Item>
                      <ItemContent>{(() => {
                        switch (user.registerType) {
                          case 'Manufacturer':
                            return 'Manufacturer'
                          case 'Distributor':
                            return 'Distributor'
                          case 'Wholesaler':
                            return 'Wholesaler'
                          case 'Retailer':
                            return 'Retailer'
                          case 'Hospital':
                            return 'Hospital'
                          default:
                            return null
                        }
                      })()}
                      </ItemContent>
                    </Grid>
                    {(() => {
                      switch (user.registerType) {
                        case 'Retailer':
                          return ''
                        case 'Hospital':
                          return ''
                        default:
                          return (
                            <>
                              <h4 style={{ color: theme.palette.primary.main, fontSize: '18px', }} >Bank Details</h4>
                              <Grid container>
                                <Grid item sm={6} xs={12} lg={3}>
                                  <Item>Account Name</Item>
                                  <TextField value={user.account_name ? user.account_name : '-'}/>
                                </Grid>
                                <Grid item sm={6} xs={12} lg={3}>
                                  <Item>Account No.</Item>
                                  <TextField value={user.account_no ? user.account_no : '-'}/>
                                </Grid>
                                <Grid item sm={6} xs={12} lg={3}>
                                  <Item>Branch</Item>
                                  <TextField value={user.branch ? user.branch : '-'}/>
                                </Grid>
                                <Grid item sm={6} xs={12} lg={3}>
                                  <Item>IFSC Code</Item>
                                  <TextField value={user.ifsc_co ? user.ifsc_co : '-'}/>
                                </Grid>
                                <Grid item sm={6} xs={12} lg={3}>
                                  <Item>Signature</Item>
                                  <ItemContent>{(user.signature) ? (
                                    <>
                                      <img src={user.signature} alt="Signature" style={{ width: '100px' }} />
                                    </>
                                  ) : <>No Signature found.</>}</ItemContent>
                                </Grid>
                              </Grid>
                            </>
                          )
                      }
                    })()}

                    <Divider />
                    <br />
                    <h4 style={{ color: theme.palette.primary.main, fontSize: '18px', }}>Commissions</h4>
                    <br />

                    {user.medicine_categories ? user.medicine_categories
                      .map((row, index) => (
                        <Grid container spacing={2} key={'1_gc_' + (1 + index)}>
                          <Grid item sm={6} xs={12} lg={3} key={'1_g_' + (1 + index)}>
                            <Item key={'1_gi_' + (1 + index)}>Category Name</Item>
                            <ItemContent key={'1_gic_' + (1 + index)}>
                              {user.medicine_categories[index].name}
                            </ItemContent>
                          </Grid>
                          {(() => {
                            switch (user.registerType) {
                              case 'Retailer':
                                return ''
                              case 'Hospital':
                                return ''
                              default:
                                return (
                                  <>
                                    <Grid item sm={6} xs={12} lg={3} key={'2_g_' + (1 + index)}>
                                      <Item key={'2_gi_' + (1 + index)}>Commission %</Item>


                                      {/* {user.medicine_categories.length > 0 && user.medicine_categories.map((item, idx) => ( */}
                                      <ItemContent key={'2_gic_' + (1 + index)}>
                                        {user.medicine_categories[index].commission}
                                      </ItemContent>
                                      {/* ))} */}

                                    </Grid>
                                    <Grid item sm={6} xs={12} lg={3} key={'3_g_' + (1 + index)}>
                                      {/* <Item key={'3_gi_' + (1 + index)}>Default Commission %</Item>
                                      <ItemContent key={'3_gic_' + (1 + index)}>
                                        {(() => {
                                          switch (user.registerType) {
                                            case 'Manufacturer':
                                              return `${user.medicine_categories[index].m_commission} %`
                                            case 'Distributor':
                                              return `${user.medicine_categories[index].d_commission} %`
                                            case 'Wholesaler':
                                              return `${user.medicine_categories[index].w_commission} %`
                                            default:
                                              return null
                                          }
                                        })()}
                                      </ItemContent> */}
                                    </Grid>
                                  </>
                                )
                            }
                          })()}
                        </Grid>
                      )) : <></>}
                  </>
                )}
              </Box>
            </CardContent>
          </ContentBox>
        </Card>

      </Container>
    </>

  );
};

export default MyProfile;
