import React, { useState } from 'react';
import swal from 'sweetalert';
import axios from 'axios';
import {
  CardContent,
  Box,
  Dialog,
  DialogTitle, DialogContent, DialogActions,
  IconButton,
  Button, TextField,
} from "@mui/material";
import { LoadingButton } from '@mui/lab';
import CloseIcon from '@mui/icons-material/Close';

import useAuth from 'app/hooks/useAuth';
import { Formik } from 'formik';
import { NavLink, useNavigate } from 'react-router-dom';
import * as Yup from 'yup';

function BootstrapDialogTitle(props) {
  const { children, onClose, ...other } = props;
  return (
    <DialogTitle sx={{ m: 0, p: 2 }} {...other}>
      {children}
      {onClose ? (
        <IconButton
          aria-label="close"
          onClick={onClose}
          sx={{
            position: 'absolute',
            right: 8,
            top: 8,
            color: (theme) => theme.palette.grey[500],
          }}
        >
          <CloseIcon />
        </IconButton>
      ) : null}
    </DialogTitle>
  );
}
// inital login credentials
const initialValues = {
  mobile_no: '',
};

// form field validation schema
const validationSchema = Yup.object().shape({
  mobile_no: Yup.string()
    .min(10, 'Mobile Number must be 10 digit.')
    .max(10, 'Mobile Number must be 10 digit.')
    .required('Mobile Number is required!'),
});
const LoginPopup = ({ open, setOpen }) => {
  const navigate = useNavigate();
  const [loading, setLoading] = useState();

  const handleClose = (event, reason) => {
    if (reason && reason == "backdropClick" && "escapeKeyDown")
      return;
    setOpen(false);
  }
  const { login } = useAuth();

  const handleFormSubmit = async (values, errors) => {
    setLoading(true);
    try {
      // axios.get('/sanctum/csrf-cookie').then(response => {
        axios.post('/api/client/send-otp', {
          mobile_no: values.mobile_no,
        })
          .then(response => {
            if (response.data.response_code === 200) {
              localStorage.setItem('login-mobile-no', response.data.data.mobile_no);
              swal({
                title: "Good job!",
                text: response.data.message,
                icon: "success",
              });
              navigate('/login-otp');
            } else if (response.data.response_code == 401) {
              swal({
                title: "Warning!",
                text: response.data.message,
                icon: "warning",
              });
              errors.setErrors(response.data.errors);
            } else {
              errors.setErrors(response.data.errors);
            }
            setLoading(false);
          })
          .catch(function (error) {
            console.error(error);
          });
      // });
    } catch (e) {
      setLoading(false);
    }
  };

  return (
    <Dialog
      maxWidth="md"
      open={open}
      onClose={handleClose}
    >
      {/* <BootstrapDialogTitle id="customized-dialog-title" onClose={handleClose}>
        &nbsp;
      </BootstrapDialogTitle> */}
      <DialogContent dividers style={{ height: '568px', overflowY: 'auto', padding: "0px" }}>
        {/* <CardContent> */}
        {/* <Box width="100%" overflow="auto"> */}
        <div className="login_register_wrap section">
          <div className="container" style={{
            background: "#E9F4D8", maxWidth: "980px", margin: "0 auto",
            padding: "0px", boxShadow: "0px 4px 4px rgba(0, 0, 0, 0.25)"
          }}>
            <div className="row justify-content-center">
              <div className="col-xl-6 login_wrap">
                <div className=" text-center" style={{ padding: "8% 0% 0% 4%" }}>
                  <div className="heading_s1">
                    <h3>Welcome Back to Hegan</h3>
                  </div>
                  <img src={`${process.env.PUBLIC_URL}/assets/images/frontend/login-user.png`} />
                </div>
              </div>
              <div className="col-xl-6">
                <div className="login_wrap">
                  <div className="padding_eight_all bg-white">
                    <div className="heading_s1 text-center">
                      <h3>Login to your Account</h3>
                      <p>Get access to your Inventory, Order Details & Reports</p>
                    </div>
                    <Formik
                      onSubmit={(values, errors) => {
                        handleFormSubmit(values, errors);
                      }
                      }
                      initialValues={initialValues}
                      validationSchema={validationSchema}
                    >
                      {({ values, errors, touched, handleChange, handleBlur, handleSubmit }) => (
                        <form onSubmit={handleSubmit} style={{ marginTop: "40px" }}>
                          <div className="form-group mb-3">
                            <label>Enter  Mobile No.</label>
                            <TextField
                              fullWidth
                              className="login_mobile_no form-control1"
                              size="medium"
                              type="number"
                              name="mobile_no"
                              variant="filled"
                              placeholder="+91 "
                              onBlur={handleBlur}
                              value={values.mobile_no}
                              onChange={handleChange}
                              helperText={touched.mobile_no && errors.mobile_no}
                              error={Boolean(errors.mobile_no && touched.mobile_no)}
                              inputProps={{ maxLength: 10 }}
                              sx={{ mb: 3, height: '2em' }}
                            />
                          </div>
                          <div className="form-group mb-3">
                            <LoadingButton
                              className="btn btn-fill-out btn-block"
                              type="submit"
                              color="primary"
                              loading={loading}
                              variant="contained"
                              sx={{ my: 2 }}
                            >
                              Get OTP
                            </LoadingButton>
                          </div>
                        </form>
                      )}
                    </Formik>

                    <div className="form-note text-center" style={{ marginTop: '150px' }}>
                      <NavLink
                        to="/partner-register"
                        style={{ color: '#4b5563', marginLeft: 5, fontWeight: 500, fontSize: '15px' }}
                      >
                        New to Hegan? Register (User / Partner)
                      </NavLink>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
        {/* </Box> */}
        {/* </CardContent> */}
      </DialogContent>
    </Dialog>
  );
};
export default LoginPopup;