import React from 'react';

function AppLinks() {
  return (
    <>

      <div className="section login_register_wrap small_pt small_pb">
        <div className="container">
          <div className="row align-items-center">
            <div className="col-md-2"></div>
            <div className="col-md-3 mb-2">
              <div className="heading_s1 mb-md-0 text-center">
                <h4>Hegan Coming Soon at :</h4>
              </div>
            </div>
            <div className="col-md-5">
              <div className="row text-center">
                <div className="col-md-4 col-sm-12 mb-2 p-2">
                  <a href="#" title={'Google Play'}><img src={`${process.env.PUBLIC_URL}/assets/images/frontend/play-btn.png`} alt={'Google Play'} /></a>
                </div>
                <div className="col-md-4 col-sm-12 p-2">
                  <a href="#" title={'App Store'}><img src={`${process.env.PUBLIC_URL}/assets/images/frontend/apple-btn.png`} alt={'App Store'} /></a>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>

    </>
  );
}
export default AppLinks;
