import React, { useState } from 'react';
import { Link } from 'react-router-dom';
import OwlCarousel from 'react-owl-carousel';
import 'owl.carousel/dist/assets/owl.carousel.css';
import 'owl.carousel/dist/assets/owl.theme.default.css';

import first_order from './1st order.png'
import refer_earn from './Refer Earn.png'
import credit from './credit.png'




const BusinessPartnersCarousel = () => {


  const [slider, setSlider] = useState(
    [

      { "id": 120000, "image": first_order, "title": 'first_order' },
      { "id": 130000, "image": refer_earn, "title": 'refer_earn' },
      { "id": 140000, "image": credit, "title": 'credit' },
    ]
  )

  const options = {
    nav: true,
    dots: false,
    autoplay: true,
    loop: true,
    margin: 0,
    responsiveClass: true,
    // animateIn: 'fadeIn',
    // animateOut: 'fadeOut',
    navText: ['<i class="ion-ios-arrow-left"></i>', '<i class="ion-ios-arrow-right"></i>'],
    smartSpeed: 1000,
    responsive: {
      0: {
        items: 1,
      },
      400: {
        items: 1,
      },
      600: {
        items: 1,
      },
      700: {
        items: 1,
      },
      1000: {
        items: 3,

      }
    },
  };
  return (
    <>
      <div className="section">
        <div >
          <div className="row">
            <div className="col-md-12">
              <div className="slide marg-btm0">
                <OwlCarousel className='product_slider carousel_slider owl-carousel owl-theme nav_style1'
                  {...options}>
                  {slider.map((item, index) => (
                    <div className="item" key={'oc1' + item.id} style={{ width: '100%' }}>
                      <div className="product" key={'oc2' + item.id}>
                        <div className="product_img" key={'oc3' + item.id}>
                          <Link to={`#`}>
                            <img src={item.image} alt={item.title} style={{}} key={'img' + item.id} />
                          </Link>
                        </div>
                      </div>
                    </div>
                  ))}

                </OwlCarousel>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  )
}

export default BusinessPartnersCarousel