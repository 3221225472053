import React, { useState, useEffect } from 'react';
import { useNavigate } from 'react-router-dom';

import axios from 'axios';
import {
  Button, Alert,
  TextField,
  Table, TableBody, TableCell, TableRow,
  Grid, Select, MenuItem, FormControl, Divider, Badge,
} from '@mui/material';
import { LoadingButton } from '@mui/lab';
import DeleteIcon from '@mui/icons-material/Delete';
import CloseIcon from '@mui/icons-material/Close';
import * as Yup from 'yup';
import { Formik } from 'formik';
import swal from 'sweetalert';
import Swal from 'sweetalert2';
import NavigateBeforeIcon from '@mui/icons-material/NavigateBefore';
import NavigateNextIcon from '@mui/icons-material/NavigateNext';
import { useCart } from 'app/contexts/CartContext';
import { Widgets } from '@mui/icons-material';
import PaymentMethod from './PaymentMethod';
import './paymentDetails.css'
import { makeStyles } from '@material-ui/core/styles';
import deliveryChrgImg from './deliveryCharge.png'
import freeDelivery from './freeDelivery.png'
const useStyles = makeStyles({
  payment_table_heads: {
    fontFamily: 'Poppins, sans-serif',
    fontWeight: 'bold',
  },
});

const validationSchema = Yup.object().shape({

});
const PaymentDetails = (props) => {
  const classes = useStyles();
  const [loading, setLoading] = useState(false);
  const [background, setBackground] = useState(
    (props.currentStep === 2) ? "#FFF" : "#ECFEFF"
  );
  const navigate = useNavigate();
  const [open_payment_method, set_open_payment_method] = useState(false);
  const handlePaymentMethodOpen = () => {
    set_open_payment_method(true)
  }

  const { cart, fetchCartData } = useCart();


  // Delivery rate sheet modal logic
  const [showDeliveryCharges, setShowDeliveryCharges] = useState(false);


  const submit_coupon = (coupon_code, coupon_name) => {
    swal({
      title: "Are you sure?",
      text: "You want to apply \"" + coupon_name + "\" coupon!",
      icon: "warning",
      buttons: [
        'No, cancel it!',
        'Yes, I am sure!'
      ],
      dangerMode: true,
    }).then(function (isConfirm) {
      if (isConfirm) {
        setLoading(true)
        try {
          const token = localStorage.getItem('accessToken')
          const config = { headers: { "x-api-key": token } };
          let url;
          if (coupon_name == 'promoCoupon') {
            url = 'api/cart/applyCoupon';
          } else if (coupon_name == 'referCoupon') {
            url = 'api/cart/applyRefCoupon'
          }
          axios.post(url, {
            "couponCode": coupon_code
          }, config)
            .then(response => {
              if (response.data.status === 200) {
                fetchCartData();
                set_show_coupons(false)
                swal({
                  title: 'Done!',
                  text: response.data.message,
                  icon: 'success'
                })
              } else {
                swal({
                  title: "Errors!",
                  text: response.data.message,
                  icon: "error",
                });
              }
              setLoading(false);
            })
            .catch(function (error) {
              setLoading(false);
              console.error(error);
              swal({
                title: "Errors!",
                text: error.response.data.message,
                icon: "error",
              });
            });

        } catch (e) {
          setLoading(false);
          swal({
            title: "Errors!",
            text: e.response.data.message,
            icon: "error",
          });
        }
      }
    });
  }

  const [remove_coup_button, set_remove_coup_button] = useState(false);
  const remove_coupon = () => {

    setLoading(true)
    try {
      const token = localStorage.getItem('accessToken')
      const config = { headers: { "x-api-key": token } };

      axios.post('api/cart/removeCoupon', {}, config)
        .then(response => {
          if (response.data.status === 200) {
            fetchCartData();
            set_show_coupons(false)
            set_remove_coup_button(false)
            swal({
              title: 'Done!',
              text: response.data.message,
              icon: 'success'
            })
          } else {
            swal({
              title: "Errors!",
              text: response.data.message,
              icon: "error",
            });
          }
          setLoading(false);
        })
        .catch(function (error) {
          setLoading(false);
          console.error(error);
          swal({
            title: "Errors!",
            text: error.response.data.message,
            icon: "error",
          });
        });

    } catch (e) {
      setLoading(false);
      swal({
        title: "Errors!",
        text: e.response.data.message,
        icon: "error",
      });
    }


  }
  //Coupon Modal Logic 
  const [show_coupons, set_show_coupons] = useState(false);

  const __handleShowCouponModal = () => {
    set_show_coupons(!show_coupons)
    setShowDeliveryCharges(false)
  }

  const handleShowDeliveryModal = () => {
    setShowDeliveryCharges(!showDeliveryCharges)
    set_show_coupons(false)
  }
  const [coupons, set_coupons] = useState(false);

  const getCoupons = () => {
    try {

      const token = localStorage.getItem('accessToken')
      const config = { headers: { "x-api-key": token } };
      // console.log(config);
      // axios.get('api/cart/getPurchaseOrder', config)
      axios.post('api/admin/coupon/getAllCoupons', {}, config)
        .then(response => {
          // console.log(response);
          if (response.data.status === 200) {
            set_coupons(response.data.data);
          } else if (response.data.response_code === 401) {

          } else {

          }
          // setLoading(false);
        })
        .catch(function (error) {
          // setLoading(false);
          console.error(error);
        });

    } catch (e) {
      console.log(e);
      // setLoading(false);
    }
  }

  // const [cartData, setCartData] = useState([]);



  useEffect(() => {
    getCoupons()
    console.log(cart);
    if (cart && cart[0].couponDisc * 1 > 0) {
      set_remove_coup_button(true)
    }
    // console.log(props.currentStep);
  }, [cart])

  const handleFormSubmit = async (values, errors) => {
    // console.log(props.currentStep);
    // console.log(props);
    // handlePaymentMethodOpen();
    // if (props.currentStep === 2) {
    //   props.next(values, true);
    // } else {
    //   props.next(values);
    // }

    if (props.currentStep == 0) {
      // await orderPlaced();
      // handlePaymentMethodOpen();
      props.next(values);
      // await getCodAvailability();
      // console.log(cod_response);

      // console.log();



    }
    else {
      props.next(values);
    }
  }

  return (
    <>
      {cart && (
        <Formik
          onSubmit={(values, errors) => {
            handleFormSubmit(values, errors);
            // console.log(values);
          }
          }
          initialValues={props.formData}
          validationSchema={validationSchema}
        >
          {({ values, errors, touched, handleChange, handleBlur, handleSubmit, setFieldValue }) => (
            <form onSubmit={handleSubmit}>
              <h4 style={{ backgroundColor: '#015998', color: 'white', padding: '5px', borderRadius: '7px', border: '1px solid black', marginTop: '-7px', }}>   <span >Payment Details</span></h4>
              <div style={{ background: '#E2EEF4', padding: '30px 0 30px 10px', borderRadius: '12px', border: '2px solid #015998', }}>

                <Table sx={{ border: "0px" }} aria-label="simple table">
                  <TableBody sx={{ '&:last-child td, &:last-child th': { border: 0 } }}>
                    <TableRow>
                      <TableCell align="left" className={classes.payment_table_heads}>Sub Total</TableCell>
                      <TableCell align="left" style={{ paddingLeft: '38px' }} className={classes.payment_table_heads}>{process.env.REACT_APP_CURRENCY}. {cart[0].subTotal}</TableCell>
                    </TableRow>
                    <TableRow>
                      <TableCell align="left" className={classes.payment_table_heads}>Bonus Amt.</TableCell>
                      <TableCell align="left" style={{ paddingLeft: '38px' }} className={classes.payment_table_heads}>{process.env.REACT_APP_CURRENCY}. {cart[0].bonusAmount}</TableCell>
                    </TableRow>
                    <TableRow>
                      <TableCell align="left" className={classes.payment_table_heads}>Discount Amt.</TableCell>
                      <TableCell align="left" style={{ paddingLeft: '38px' }} className={classes.payment_table_heads}>{process.env.REACT_APP_CURRENCY}. {cart[0].discountAmount}</TableCell>
                    </TableRow>
                    <TableRow>
                      <TableCell align="left" className={classes.payment_table_heads}>Tax Amt.</TableCell>
                      <TableCell align="left" style={{ paddingLeft: '38px' }} className={classes.payment_table_heads}>{process.env.REACT_APP_CURRENCY}. {cart[0].taxAmount}</TableCell>
                    </TableRow>
                    <TableRow>
                      <TableCell align="left" className={classes.payment_table_heads}>Total Amt.</TableCell>
                      <TableCell align="left" style={{ paddingLeft: '38px' }} className={classes.payment_table_heads}>{process.env.REACT_APP_CURRENCY}. {cart[0].totalAmount}</TableCell>
                    </TableRow>
                    <TableRow>
                      <TableCell align="left" className={classes.payment_table_heads}>Coupon Amt.</TableCell>
                      <TableCell align="left" style={{ paddingLeft: '38px' }} className={classes.payment_table_heads}>{process.env.REACT_APP_CURRENCY}. {cart[0].couponDisc}</TableCell>
                    </TableRow>
                    {/* <TableRow>
                      <TableCell> <Button style={{ background: 'gold', color: 'black' }} onClick={handleShowDeliveryModal}><b style={{ fontSize: '13px' }}>Delivery Rate Sheet</b></Button ></TableCell>

                    </TableRow> */}
                    {/* <TableRow>
                      <TableCell align="left" colSpan={2}></TableCell>
                    </TableRow>
                    <TableRow className='row-highlighter'>
                      <TableCell align="left" colSpan={2}>Add products worth Rs.20.00 more and get FREE delivery</TableCell>
                    </TableRow> */}
                    {/* <TableRow>
                      <TableCell align="left" colSpan={2}></TableCell>
                    </TableRow> */}
                    {/* <TableRow>
                      <TableCell align="left">Total Payable</TableCell>
                      <TableCell align="right">{process.env.REACT_APP_CURRENCY}. {cart.total_payable}</TableCell>
                    </TableRow>
                    <TableRow>
                      <TableCell align="left" colSpan={2}></TableCell>
                    </TableRow> */}
                    <TableRow className='total-payable'>
                      <TableCell colSpan={2}>
                        <div style={{ backgroundColor: '#015998', display: 'flex', justifyContent: 'space-between', color: 'white', height: '39px', padding: '7px', borderRadius: '3px' }} className={`{classes.payment_table_heads font_size_}`}>
                          Total Payable: {process.env.REACT_APP_CURRENCY}.{cart[0].totalPayable} <>
                            {props.currentStep != 1 && <Button className='button_font_' type='submit' variant="contained" color="success" size='small' sx={{ fontWeight: "700" }} disabled={props.minQty_error}>
                              {(props.currentStep === 1) ? "Confirm" : "Continue"}
                            </Button>}

                          </>
                        </div>



                      </TableCell>
                    </TableRow>
                    {props.currentStep != 1 &&
                      <TableRow>
                        <TableCell>
                          <Button
                            variant="contained"
                            color="success"
                            style={{ color: 'white' }}
                            onClick={(e) => {
                              cart[0].couponDisc > 0 ? remove_coupon() : __handleShowCouponModal(e);
                            }}
                          >
                            {cart[0].couponDisc > 0 ? 'Remove Coupon' : 'Coupons'}
                          </Button>
                        </TableCell>


                      </TableRow>}

                    {/* <TableRow>
                      <TableCell align="left" colSpan={2}></TableCell>
                    </TableRow> */}

                    <TableRow className='total-payable'>
                      {/* <TableCell align="left">Total Savings</TableCell>
                      <TableCell align="right">{process.env.REACT_APP_CURRENCY}. {cart[0].totalSaving}</TableCell> */}
                      <TableCell colSpan={2}>
                        <div style={{ backgroundColor: '#015998', display: 'flex', justifyContent: 'space-between', color: 'white', fontSize: '17px', height: '39px', padding: '7px', borderRadius: '3px' }} className={classes.payment_table_heads}>
                          Total Savings: {process.env.REACT_APP_CURRENCY}.{cart[0].totalSaving}


                        </div>
                      </TableCell>
                    </TableRow>

                    <TableRow>
                      <TableCell colSpan={2} >
                        <div style={{ display: 'flex', alignItems: 'center', margin: '-47px 0 -56px 0', cursor: 'pointer', }} onClick={handleShowDeliveryModal}><img src={deliveryChrgImg} style={{ width: '39%' }} /><p className='font_size_' style={{ color: 'red', }} >Delivery Charges Rate Sheet</p></div>
                        {/* <div style={{ display: 'flex', alignItems: 'center', margin: '-47px 0 -56px 0', }} ><img src={freeDelivery} style={{ width: '39%' }} /><p style={{ color: 'red',fontSize:'12px'}} >Applicable For Only First Two Orders</p></div> */}
                      </TableCell>
                    </TableRow>


                  </TableBody>
                </Table>
              </div>
            </form>
          )}



        </Formik >
      )}
      <PaymentMethod open={open_payment_method} setOpen={set_open_payment_method} />
      {showDeliveryCharges && <div className='delivery_rate_sheet' >
        <div style={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center', background: '#015998', color: 'white', borderRadius: '10px', padding: '7px' }}><h2 style={{ color: 'white', margin: '0 0 0 93px', textAlign: 'center' }}>Delivery Rate Sheet</h2><div style={{ cursor: 'pointer' }}><CloseIcon onClick={handleShowDeliveryModal} /></div></div>

        <TableCell align="center" colSpan={2} sx={{ wordBreak: "unset" }}>
          <div className={classes.payment_table_heads} style={{ textAlign: 'left', padding: '3%', textAlign: 'justify', }}>
            After delivery of the consignment, customer will receive delivery slip to pay as per the actual weight of the consignment picked by our logistics partner. Based on pick & drop point the cost of the delivery varies as highlighted below:
          </div>
        </TableCell>
        <Table aria-label="simple table">
          <TableBody sx={{ '&:last-child td, &:last-child th': { border: 0 } }}>
            <TableRow>
              <TableCell align="left"><b>Weight(kg)</b></TableCell>
              <TableCell align="left"><b>Cost </b></TableCell>

            </TableRow>
            <TableRow>

              <TableCell align="left">{`<10`}</TableCell>
              <TableCell align="left">Fixed : Rs.80 to Rs.160</TableCell>
            </TableRow>
            <TableRow>

              <TableCell align="left">{`>=10`}</TableCell>
              <TableCell align="left"> Rs.8/kg to Rs.16/kg</TableCell>
            </TableRow>
            <div style={{ Width: '100%' }}>
              <b>Additional Charges</b>
            </div>

            Docket Charge = Rs.200/shipment

            <TableRow>
              <TableCell align="left"><b>Tax : </b>18% GST</TableCell>
            </TableRow>
          </TableBody>
        </Table>
      </div>}

      {show_coupons &&
        <div className='coupon_sheet' >

          <div style={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center', background: '#015998', color: 'white', borderRadius: '10px', padding: '7px' }}><h2 style={{ color: 'white', margin: '0 0 0 49px', textAlign: 'center' }}>Select a Coupon</h2><div style={{ cursor: 'pointer' }}><CloseIcon onClick={__handleShowCouponModal} /></div></div>


          <Table aria-label="simple table">
            <TableBody sx={{ '&:last-child td, &:last-child th': { border: 0 } }}>
              <TableRow>
                {coupons && coupons.map((coupon, idx) => (
                  <div className='coupon' key={1} style={{ display: 'flex', justifyContent: 'space-between', marginTop: "17px", backgroundColor: 'white', padding: '9px', borderRadius: '11px', boxShadow: '0px 5px 4px rgba(0,0,0,0.25)', border: '2px solid #015998', }}>
                    {/* <label style={{ backgroundColor: 'white', color: 'black', padding: '13px', margin: '0 0 0 18px', borderRadius: '23px', border: '1px solid black', fontSize: '15px' }}>#98743987</label>
  <Badge badgeContent={'i'} color="primary" style={{ border: '11px solid black ', borderRadius: '50%', marginLeft: '8px' }}></Badge>
  <input
    type="radio"
    id={1}
    value={1}
    checked={true}
    style={{ margin: '0 0 0 18px' }}
  // onChange={() => handleCouponSelection(coupon.id)}
  /> */}
                    <div style={{ margin: '0 0 0 7px', position: 'relative' }} >

                      <p className={classes.payment_table_heads} style={{ fontSize: '26px', color: 'green', }}>{coupon.discPercent}% OFF</p>
                      <p className={classes.payment_table_heads} style={{ fontSize: '11px', margin: '-5px 0', color: 'orange' }}>MAX Rs.{coupon.limit}</p>
                      <p className={classes.payment_table_heads} style={{ color: '#015998' }}>{coupon.title}
                        {/* <span className="circle">i</span> */}
                      </p>
                    </div>
                    <div style={{ display: 'flex', flexDirection: 'column', justifyContent: 'space-between', margin: '0 8px 0 0', }}>
                      <p className={classes.payment_table_heads} style={{ color: 'red', fontSize: '14px' }} >Expires on {new Intl.DateTimeFormat('en-GB', { day: '2-digit', month: '2-digit' })
                        .format(new Date(coupon.expiryDate))}</p>
                      <LoadingButton
                        onClick={(e) => { submit_coupon(coupon.couponCode, coupon.couponType) }}
                        loading={loading}
                        variant='outlined'
                        size='small'
                        color='primary'
                        sx={{ borderRadius: '12px' }}>Apply
                      </LoadingButton>
                    </div>



                  </div>
                ))}



              </TableRow>

            </TableBody>
          </Table>
        </div>}
    </>
  );
}
export default PaymentDetails;
