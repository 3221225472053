import React from 'react'
import { useNavigate, Link } from 'react-router-dom';

import Header from './../Header/Header';
import Footer from './../Footer/Footer';
import './button.css'

import lookingToGrow from './lookingToGrow.png'
import image2 from './image2.png'
import image3 from './image3.png'


const BrandingPage = () => {
  return (
    <>
      <div>
        <Header />
        <img src={lookingToGrow} alt='looking to grow' style={{}} />
        <img src={image2} alt='image2' style={{}} />
        <div style={{ position: 'relative', display: 'inline-block' }}>
          <img src={image3} alt='image3' style={{}} />
          <button className='circle-button'
          
          >
           <Link to='/contact-us' style={{ cursor: 'pointer', color: 'white' }} title='Contact Us'>Click Here!</Link>
          </button>
        </div>
        <Footer />
      </div>

    </>
  )
}

export default BrandingPage