import React, { useState, useEffect } from 'react';
import { Link, useNavigate } from 'react-router-dom';
import {
  Box, Stepper, Step, StepButton,
  Divider, Alert, Backdrop, CircularProgress
} from '@mui/material';

import * as Yup from 'yup';

import RelatedProducts from './../ProductDetail/RelatedProducts';
import Header from './../Header/Header';
import Footer from './../Footer/Footer';
import { useCart } from 'app/contexts/CartContext';

import CheckoutOrderSummary from './CheckoutOrderSummary';
import Address from './Address';
import PlaceOrder from './PlaceOrder';
import Payment from './Payment';

const initialValues = {
};
function Checkout({ props }) {
  const navigate = useNavigate();
  const [formData, setFormData] = useState(initialValues);
  const [loading, setLoading] = useState(false);
  const [currentStep, setCurrentStep] = useState(props.selected_step);
  const { totalCartItems, orderPlaced } = useCart();

  useEffect(() => {
    // console.log(currentStep,'hiiiiiiiiiiiiiiiii');
    if (currentStep) {
      switch (currentStep) {
        case 0:
          navigate('/product/checkout/order-summary');
          break;
        case 1:
          navigate('/product/checkout/shipping-address');
          break;
        case 2:
          navigate('/product/checkout/order-placed');
          break;
        case 3:
          break;
      }
    }
  }, [currentStep]);

  const steps = ['Order Summary', 'Shipping Address'];

  const handleNextStep = async (newFormData, finalStep = false) => {
    console.log('hello');
    setFormData((prev) => ({ ...prev, ...newFormData }));
    if (finalStep) {
      setLoading(true);
      await orderPlaced();
    } else {
      setCurrentStep((prev) => prev + 1);
    }
  }

  const handleStep = (step) => () => {
    setCurrentStep(step);
  };

  const handlePrevStep = (newFormData) => {
    setFormData((prev) => ({ ...prev, ...newFormData }));
    setCurrentStep((prev) => prev - 1);
  }

  const formSteps = [
    <CheckoutOrderSummary currentStep={currentStep} next={handleNextStep} formData={formData} />,
    <Address currentStep={currentStep} prev={handlePrevStep} formData={formData} />,
    // <PlaceOrder currentStep={currentStep} next={handleNextStep} prev={handlePrevStep} formData={formData} />,
    // <Payment currentStep={currentStep} next={handleNextStep} prev={handlePrevStep} formData={formData} />,
  ];
  return (
    <>
      <Header />
      <div className="breadcrumb_section  page-title-mini" style={{ padding: '30px 0px' }}>
        <div className="container">
          <div className="row">
            <div className="col-md-6">
              <ol className="breadcrumb ">
                <li className="breadcrumb-item"><Link to="#">Home</Link></li>
                <li className="breadcrumb-item"><Link to="#">Product</Link></li>
                <li className="breadcrumb-item active">Checkout</li>
              </ol>
            </div>
          </div>
        </div>
      </div>

      <div className="section order_page" style={{ marginBottom: "60px" }}>
        <div className="container" style={{ position: "relative", minHeight: "400px" }}>
          <h1>Checkout</h1>
          <Divider />
          {(props.selected_step === 3) ? (
            <>
              <Box sx={{ width: '100%' }}>
                <Stepper alternativeLabel activeStep={currentStep} sx={{ my: '30px', borderBottom: "2px solid", paddingBottom: "20px" }}>
                  {steps.map((label, index) => (
                    <Step key={label}>
                      <StepButton color="inherit" onClick={handleStep(index)}>
                        {label}
                      </StepButton>
                    </Step>
                  ))}
                </Stepper>
                <div>
                  {formSteps[currentStep]}
                </div>
              </Box>
            </>
          ) : (
            <>
              {totalCartItems ? (
                <>
                  <Box sx={{ width: '100%' }}>
                    <Stepper alternativeLabel activeStep={currentStep} sx={{ my: '30px', borderBottom: "2px solid", paddingBottom: "20px" }}>
                      {steps.map((label, index) => (
                        <Step key={label}>
                          <StepButton color="inherit" onClick={handleStep(index)}>
                            {label}
                          </StepButton>
                        </Step>
                      ))}
                    </Stepper>
                    <div>
                      {formSteps[currentStep]}
                    </div>
                  </Box>
                </>
              ) : (
                <>
                  <Alert severity="info">Your basket is empty !</Alert>
                  {/* <RelatedProducts /> */}
                  {/* <Box sx={{ width: '100%' }}>
                    <Stepper alternativeLabel activeStep={currentStep} sx={{ my: '30px', borderBottom: "2px solid", paddingBottom: "20px" }}>
                      {steps.map((label, index) => (
                        <Step key={label}>
                          <StepButton color="inherit" onClick={handleStep(index)}>
                            {label}
                          </StepButton>
                        </Step>
                      ))}
                    </Stepper>
                    <div>
                      {formSteps[currentStep]}
                    </div>
                  </Box> */}
                </>
              )}
            </>
          )}



        </div>
      </div >
      <Backdrop
        sx={{ color: '#015998', zIndex: (theme) => theme.zIndex.drawer + 1 }}
        open={loading}
        onClick={setLoading}
      >
        <CircularProgress color="inherit" />
      </Backdrop>
      <Footer />
    </>
  );
}
export default Checkout;
