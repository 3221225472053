import React, { useState, useEffect } from 'react';
import { Link, useNavigate, useParams } from 'react-router-dom';

import {
  Box, Stepper, Step, StepButton, Divider, Backdrop, CircularProgress

} from '@mui/material';

import * as Yup from 'yup';
import Header from './../Header/Header';
import Footer from './../Footer/Footer';
import { useCart } from 'app/contexts/CartContext';

import CheckoutOrderSummary from './CheckoutOrderSummary';
import Address from './Address';
import PlaceOrder from './PlaceOrder';
import Payment from './Payment';

const initialValues = {
};
const validationSchema = Yup.object().shape({
  organization_type_id: Yup.number()
    .required('Organization Type is required!'),
});

function CheckoutOrderSuccess({ props }) {
  const { order_id } = useParams();
  const steps = [
    'Order Summary',
    'Shipping Address',
    'Place Order',
    'Payment',
  ];

  const navigate = useNavigate();
  const [formData, setFormData] = useState(initialValues);
  const [loading, setLoading] = useState(false);
  const [currentStep, setCurrentStep] = useState(props.selected_step);
  const { totalCartItems, orderPlaced, order, getOrder } = useCart();
  useEffect(() => {
    if (order_id) {
      getOrder(order_id);
    }
  }, [order_id]);

  const handleNextStep = async (newFormData, finalStep = false) => {
    setFormData((prev) => ({ ...prev, ...newFormData }));
    if (finalStep) {
    } else {
      setCurrentStep((prev) => prev + 1);
    }
  }

  const handlePrevStep = (newFormData) => {
    setFormData((prev) => ({ ...prev, ...newFormData }));
    setCurrentStep((prev) => prev - 1);
  }

  const formSteps = [
    <CheckoutOrderSummary currentStep={currentStep} next={handleNextStep} formData={formData} />,
    <Address currentStep={currentStep} next={handleNextStep} prev={handlePrevStep} formData={formData} />,
    <PlaceOrder currentStep={currentStep} next={handleNextStep} prev={handlePrevStep} formData={formData} />,
    // <Payment currentStep={currentStep} next={handleNextStep} prev={handlePrevStep} formData={formData} />,
  ];
  return (
    <>
      <Header />
      <div className="breadcrumb_section  page-title-mini" style={{ padding: '30px 0px' }}>
        <div className="container">
          <div className="row">
            <div className="col-md-6">
              <ol className="breadcrumb ">
                <li className="breadcrumb-item"><Link to="#">Home</Link></li>
                <li className="breadcrumb-item"><Link to="#">Product</Link></li>
                <li className="breadcrumb-item active">Checkout</li>
              </ol>
            </div>
          </div>
        </div>
      </div>
      {!order && (
        <Backdrop
          sx={{ color: '#015998', zIndex: (theme) => theme.zIndex.drawer + 1 }}
          open={true}
          onClick={setLoading}
        >
          <CircularProgress color="inherit" />
        </Backdrop>
      )}
      <div className="section order_page" style={{ marginBottom: "60px" }}>
        <div className="container" style={{ position: "relative", minHeight: "400px" }}>
          <h1>Checkout</h1>
          <Divider />
          <Box sx={{ width: '100%' }}>
            <Stepper alternativeLabel activeStep={currentStep} sx={{ my: '30px', borderBottom: "2px solid", paddingBottom: "20px" }}>
              {steps.map((label, index) => (
                <Step key={label}>
                  <StepButton color="inherit">
                    {label}
                  </StepButton>
                </Step>
              ))}
            </Stepper>
            <div>
              {formSteps[currentStep]}
            </div>
          </Box>
        </div>
      </div >
      <Footer />
    </>
  );
}
export default CheckoutOrderSuccess;
