import React from 'react'
import {
    Button,

} from '@mui/material';
import sbi from './sbi_collect.png'

const PaymentMethod = ({ open, setOpen, }) => {

    const handleClose = () => {
        setOpen(false);
    };
    return (
        <>
            {open && <>
                <div style={{ position: 'absolute', right: '25rem', top: '24rem' }}>
                    <div style={{
                        display: 'flex',
                        flexDirection: 'column',
                        padding: '10px',
                        // position: 'absolute',
                        // top: '56px',
                        // right: '1px',
                        zIndex: '1',
                        // height: '20%',
                        width: '359px',
                        // border: '1px solid white',
                        background: '#3195F0',
                        boxShadow: '0px 2px 4px rgba(0, 0, 0, 0.25)',
                        borderRadius: '6px',
                        cursor: 'pointer',
                        fontFamily: 'Arial, Helvetica, sans-serif',
                    }}>
                        <h4 style={{ color: '#fff' }}>Confirm order and pay <span style={{ padding: '0 0 0 50px' }} onClick={handleClose}>x</span></h4>
                        <p style={{ color: '#fff' }}>Select a payment method from below and click on Pay Now</p>
                        <div style={{
                            display: 'flex',
                            justifyContent: 'space-between',
                            alignItems: 'center',
                            background: 'whiteSmoke',
                            borderRadius: '3px',
                            padding: '10px',
                        }}>
                            <label style={{ display: 'flex', alignItems: 'center' }}>
                                <input type="radio" name="paymentMethod" />
                                <span style={{ marginLeft: '8px' }}>SBI Collect</span>
                            </label>
                            <img src={sbi} style={{ width: '10%' }} />
                        </div>

                        <div style={{
                            display: 'flex',
                            justifyContent: 'space-between',
                            alignItems: 'center',
                            background: 'whiteSmoke',
                            borderRadius: '3px',
                            padding: '10px',
                        }}>
                            <label style={{ display: 'flex', alignItems: 'center' }}>
                                <input type="radio" name="paymentMethod" />
                                <span style={{ marginLeft: '8px' }}>Cash On Delivery</span>
                            </label>
                            <img src={sbi} style={{ width: '10%' }} />
                        </div>
                        <div style={{display:'flex',justifyContent:'flex-end',height:'2.5rem',margin:'13px 0 0 0',}}>
                            <Button type='submit' variant="contained" color="success" size='large' sx={{ fontSize: '20px', fontWeight: "700" }}>
                                Pay Now
                            </Button>

                        </div>



                    </div>

                </div></>}</>

    )
}

export default PaymentMethod