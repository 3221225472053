import { LoadingButton } from '@mui/lab';
import { Card, Checkbox, Grid, TextField, Button } from '@mui/material';
import { Box, styled, useTheme } from '@mui/system';
import { Paragraph } from 'app/components/Typography';
import useAuth from 'app/hooks/useAuth';
import { Formik } from 'formik';
import { useState } from 'react';
import { NavLink, useNavigate } from 'react-router-dom';
import * as Yup from 'yup';
import axios from 'axios';
import swal from 'sweetalert';
import Header from './../Header/Header';
import Footer from './../Footer/Footer';
import AppLinks from './AppLinks';
import "./partner.css";

const FlexBox = styled(Box)(() => ({ display: 'flex', alignItems: 'center' }));

const JustifyBox = styled(FlexBox)(() => ({ justifyContent: 'center' }));

const ContentBox = styled(Box)(() => ({
  height: '100%',
  padding: '32px',
  position: 'relative',
  background: 'rgba(0, 0, 0, 0.01)',
}));

const JWTRoot = styled(JustifyBox)(() => ({
  background: '#e7feff',
  minHeight: '100% !important',
  '& .card': {
    maxWidth: 950,
    minHeight: 500,
    margin: '1rem',
    display: 'flex',
    borderRadius: 2,
    alignItems: 'center',
  },
}));

// inital login credentials
const initialValues = {
  login_otp: '',
};

// form field validation schema
const validationSchema = Yup.object().shape({
  login_otp: Yup.string()
    .matches(/^0*\d{5}$/, 'Mobile OTP must be 5 digits.')
    .required('OTP field is required!'),
});

const PartnerLoginOtp = () => {
  const theme = useTheme();
  const navigate = useNavigate();
  const [loading, setLoading] = useState(false);
  const { login } = useAuth();
  const [mobile, setMobileNo] = useState(localStorage.getItem('login-mobile-no'));

  // Check if link is direct opened
  if (mobile == undefined || mobile == 'undefined' || mobile == '') {
    // console.log('✅ Login detail is NOT undefined or null');
    navigate('/login');
  }

  const handleResendOTP = async (values, errors) => {
    try {
      setLoading(true);
      // axios.get('/sanctum/csrf-cookie').then(response => {
      // axios.post('api/admin/auth/userLogin', {
      axios.post('api/user/otp', {
        mobile: mobile,
      })
        .then(response => {
          if (response.data.status === 200) {
            window.localStorage.clear();
            window.sessionStorage.clear();
            localStorage.setItem('login-mobile-no', response.data.user.mobile);
            // localStorage.setItem('accessToken', response.data.data);
            // localStorage.setItem('auth_name', response.data.user.firstName);
            // localStorage.setItem('register_as', response.data.user.registerType);
            // localStorage.setItem('user', JSON.stringify(response.data.user));
            sessionStorage.setItem('OTP', response.data.OTP);
            swal({
              title: "Good job!",
              text: "OTP Resent successfully!",
              icon: "success",
            });
            setLoading(false);
          } else if (response.data.status === 401) {
            swal({
              title: "Warning!",
              text: response.data.message,
              icon: "warning",
            });
            // errors.setErrors(response.data.errors);
          } else {
            errors.setErrors(response.data.errors);
          }
          setLoading(false);
        })
        .catch(function (error) {
          console.error(error);
          setLoading(false);
        });
      // });
    } catch (e) {
      // setLoading(false);
    }
  }
  const handleFormSubmit = async (values, errors) => {
    // alert(values.login_otp)
    // const response = login(mobile, values.login_otp);
    setLoading(true);
    try {
      // console.log(mobile,);
      // axios.get('/sanctum/csrf-cookie').then(response => {
      // axios.post('api/admin/auth/verifyOtp', {
      axios.post('api/admin/auth/verifyOtp', {
        mobile: localStorage.getItem('login-mobile-no'),
        otp: values.login_otp,
      })
        .then(response => {
          // console.log(response);
          if (response.data.status === 200) {
            // var responseData = response.data;
            // console.log(124,response.data.token);
            localStorage.setItem('accessToken', response.data.token);
            // console.log(126,response.data.userData.firstName);
            localStorage.setItem('auth_name', response.data.userData.firstName);
            // console.log(128,response.data.userData.registerType);
            localStorage.setItem('register_as', response.data.userData.registerType);
            // console.log(response.data.userData);
            let data = JSON.stringify(response.data.userData)
            localStorage.setItem('user', data);
            swal({
              title: "Good job!",
              text: "Logged In Successfully",
              icon: "success",
            });
            login(mobile, values.login_otp);
          } else if (response.data.status === 400) {
            swal({
              title: "Warning!",
              text: response.data.message,
              icon: "warning",
            });
            setLoading(false);
            // errors.setErrors(response.data.errors);
          } else if (response.data.status === 404) {
            swal({
              title: "Warning!",
              text: response.data.message,
              icon: "warning",
            });
            // errors.setErrors(response.data.errors);
          } else {
            errors.setErrors(response.data.errors);
          }
          // setLoading(false);
        })
        .catch(function (error) {
          console.error(error);
        });
      // });
    } catch (e) {
      setLoading(false);
    }
  };

  return (
    <>
      <Header />
      <div className="main_content" style={{ background: 'linear-gradient(90deg, #9E8FEC 0%, #0CB7FF 50%, #6DFFAC 100%)',}}>
        <div className="login_register_wrap section" style={{ background: 'linear-gradient(90deg, #9E8FEC 0%, #0CB7FF 50%, #6DFFAC 100%)',}}>
          <div className="container" style={{
            background: 'linear-gradient(90deg, #9E8FEC 0%, #0CB7FF 50%, #6DFFAC 100%)', maxWidth: "980px", margin: "0 auto",
            padding: "0px", boxShadow: "0px 4px 4px rgba(0, 0, 0, 0.25)"
          }}>
            <div className="row justify-content-center">
              <div className="col-xl-6 login_wrap">
                <div className=" text-center" style={{ padding: "8% 0% 0% 4%" }}>
                  <div className="heading_s1">
                    <h3>Welcome Back to Hegan</h3>
                  </div>
                  <img src={`${process.env.PUBLIC_URL}/assets/images/frontend/login-user.png`} />
                </div>
              </div>
              <div className="col-xl-6">
                <div className="login_wrap">
                  <div className="padding_eight_all bg-white">
                    <div className="heading_s1 text-center">
                      <h3>Login to your Account</h3>
                      <p>Get access to your Inventory, Order Details & Reports</p>
                    </div>
                    <Formik
                      onSubmit={(values, errors) => {
                        handleFormSubmit(values, errors);
                      }
                      }
                      initialValues={initialValues}
                      validationSchema={validationSchema}
                    >
                      {({ values, errors, touched, handleChange, handleBlur, handleSubmit }) => (
                        <form onSubmit={handleSubmit} style={{ marginTop: "40px" }}>
                          <div className="form-group">
                            <label htmlFor="login_otp" >Enter OTP</label>
                            <TextField
                              fullWidth
                              className="login_mobile_no form-control1"
                              size="medium"
                              type="text"
                              name="login_otp"
                              variant="filled"
                              placeholder="OTP"
                              onBlur={handleBlur}
                              value={values.login_otp}
                              onChange={handleChange}
                              helperText={touched.login_otp && errors.login_otp}
                              error={Boolean(errors.login_otp && touched.login_otp)}
                              inputProps={{ maxLength: 10 }}
                              sx={{ mb: 3, height: '1.5em' }}
                            />
                            <FlexBox className='resend-wrap'>
                              <Button onClick={handleResendOTP} variant="text" className='resend-otp-btn' style={{ color: theme.palette.primary.main }}>
                                Resend OTP
                              </Button>
                            </FlexBox>
                          </div>
                          <div className="form-group mb-3">
                            <LoadingButton
                              className="btn btn-fill-out btn-block"
                              type="submit"
                              color="primary"
                              loading={loading}
                              variant="contained"
                            >
                              Login
                            </LoadingButton>
                          </div>
                        </form>
                      )}
                    </Formik>

                    <div className="form-note text-center" style={{ marginTop: '150px' }}>
                      <NavLink
                        to="/partner-register"
                        style={{ color: '#4b5563', marginLeft: 5, fontWeight: 500, fontSize: '15px' }}
                      >
                        New to Hegan? Register (User / Partner)
                      </NavLink>
                      {/* New to Hegan?  <a href="#">Register (User / Partner)
                      </a> */}
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>

      {/* <AppLinks /> */}
      <Footer />
    </>

  );
};

export default PartnerLoginOtp;
