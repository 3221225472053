import React, { useEffect, useState } from 'react';

import {
  Link, NavLink, useLocation, useNavigate,
} from 'react-router-dom';

import swal from 'sweetalert';
import axios from 'axios';
import OwlCarousel from 'react-owl-carousel';
import Carousel from 'react-material-ui-carousel'
import { Paper, Button, Grid, Divider } from '@mui/material'

import 'owl.carousel/dist/assets/owl.carousel.css';
import 'owl.carousel/dist/assets/owl.theme.default.css';
// import './hotSellerSlider.css'
import './exploreCategories.css'
import heganlogo from './heganlogo.png'
import smi from './sm.jpeg'
import veti from './veti.jpeg'
import alli from './alli.jpeg'
import ayuri from './ayuri.jpeg'
import geni from './geni.jpeg'


function ExploreCategoriesSlider() {

  const [categories, setCategories] = useState(false);
  const [otc, setOtc] = useState([]);
  const [veterinary, setVeterinary] = useState([]);
  const [allopathic, setAllopathic] = useState([]);
  const [sm, setSm] = useState([]);
  const [ayurvedic, setAyurvedic] = useState([]);
  const [generic, setGeneric] = useState([]);
  const [smImage, setSmImage] = useState([{ 'uploadImg': heganlogo }, {
    'uploadImg': smi
  }])
  const [ayurImage, setAyurImage] = useState([{ 'uploadImg': heganlogo }, {
    'uploadImg': ayuri
  }])
  const [alImage, setallImage] = useState([{
    'uploadImg': alli
  }, { 'uploadImg': heganlogo },])
  const [vetImage, setvetImage] = useState([{ 'uploadImg': heganlogo }, {
    'uploadImg': veti
  }])
  const [genImage, setGenImage] = useState([{ 'uploadImg': heganlogo }, {
    'uploadImg': geni
  }])


  const [loading, setLoading] = useState(false);
  useEffect(() => {
    fetchData();
  }, []);

  /**
   * Fetching data from server.
   */
  const fetchData = async () => {
    setLoading(true);
    try {
      // await axios.get('/sanctum/csrf-cookie').then(response => {
      // axios.get('api/admin/medicineCategory/getList')
      //   .then(response => {
      //     if (response.data.status === 200) {
      //       setCategories(response.data.data);
      //     } else if (response.data.response_code === 401) {
      //     } else {
      //     }
      //     setLoading(false);
      //   })
      //   .catch(function (error) {
      //     setLoading(false);
      //     console.log(error);
      //   });
      // });
      axios.post('api/admin/medicineCategory/viewMedicineByFilter', {
        "medicineCategory": "OTC",
        pageSize: '5',
      })
        .then(response => {
          // console.log(response)
          if (response.data.status === 200) {
            setOtc(response.data.data);
          } else if (response.data.response_code === 401) {
          } else {
          }
          setLoading(false);
        })
        .catch(function (error) {
          setLoading(false);
          console.log(error);
        });

      axios.post('api/admin/medicineCategory/viewMedicineByFilter', {
        "medicineCategory": "Generic",
        pageSize: '5',
      })
        .then(response => {
          // console.log(response)
          if (response.data.status === 200) {
            setGeneric(response.data.data);
          } else if (response.data.response_code === 401) {
          } else {
          }
          setLoading(false);
        })
        .catch(function (error) {
          setLoading(false);
          console.log(error);
        });

      axios.post('api/admin/medicineCategory/viewMedicineByFilter', {
        "medicineCategory": "Veterinary",
        pageSize: '5',
      })
        .then(response => {
          // console.log(response)
          if (response.data.status === 200) {
            setVeterinary(response.data.data);
          } else if (response.data.response_code === 401) {
          } else {
          }
          setLoading(false);
        })
        .catch(function (error) {
          setLoading(false);
          console.log(error);
        });

      axios.post('api/admin/medicineCategory/viewMedicineByFilter', {
        "medicineCategory": "Surgical & Medical Equipment",
        pageSize: '5',
      })
        .then(response => {
          // console.log(response)
          if (response.data.status === 200) {
            setSm(response.data.data);
          } else if (response.data.response_code === 401) {
          } else {
          }
          setLoading(false);
        })
        .catch(function (error) {
          setLoading(false);
          console.log(error);
        });

      axios.post('api/admin/medicineCategory/viewMedicineByFilter', {
        "medicineCategory": "Allopathic",
        pageSize: '5',
      })
        .then(response => {
          // console.log(response)
          if (response.data.status === 200) {
            setAllopathic(response.data.data);
          } else if (response.data.response_code === 401) {
          } else {
          }
          setLoading(false);
        })
        .catch(function (error) {
          setLoading(false);
          console.log(error);
        });

      axios.post('api/admin/medicineCategory/viewMedicineByFilter', {
        "medicineCategory": "Ayurvedic",
        pageSize: '5',
      })
        .then(response => {
          // console.log(response)
          if (response.data.status === 200) {
            setAyurvedic(response.data.data);
          } else if (response.data.response_code === 401) {
          } else {
          }
          setLoading(false);
        })
        .catch(function (error) {
          setLoading(false);
          console.log(error);
        });

    } catch (e) {
      // setLoading(false);
    }
  }

  const options = {
    nav: false,
    dots: false,
    autoplay: true,
    loop: true,
    margin: 20,
    responsiveClass: true,
    // navText: ['<i class="ion-ios-arrow-left"></i>', '<i class="ion-ios-arrow-right"></i>'],
    smartSpeed: 1000,

    responsive: {
      0: {
        items: 1,

        // nav: false,
      },
      400: {
        items: 1,

        // nav: false,
      },
      600: {
        items: 1,
      },
      700: {
        items: 1,
      },
      1000: {
        items: 1,

      }
    },
  };
  return (
    <>
      <div className="section small_pt prodstyel prodcatl marg-btm30">
        <div >
          <div className="row">
            <div className="col-md-12">
              <div className="heading_tab_header">
                <h3 style={{ color: '#015998' }}>Explore Categories<Link to="/categories" className="vall">VIEW ALL</Link> </h3>
              </div>
            </div>
          </div>
          <div className="row" >
            <div className="col-md-12">

              <div className='categories-outer-div'>
                <div className='categories-inner-div' >
                  <div className="item " >
                    <div className="product" >
                      <div className="product_wrap" >
                        <div className="sale_banner" style={{ border: '2px solid #015998' }}>
                          <Carousel indicators={false}
                            autoPlay={true}
                            animation="slide"
                            duration={1500}>
                            {generic.length > 0 ?
                              (generic.map((item, idx) => (


                                <Link to={`/categories/Generic`} className="hover_effect1" key={idx + 1}>
                                  <div className='productImage_div' >
                                    <img src={item.uploadImg ? item.uploadImg : heganlogo} key={'gen' + idx} />
                                  </div>

                                </Link>

                              ))) :
                              (
                                genImage.map((item, idx) => (
                                  <Link to={`/categories/Generic`} className="hover_effect1">
                                    <div className='productImage_div' style={{ border: '2px solid #015998' }}>
                                      <img src={heganlogo} key={'gen' + idx} />
                                    </div>
                                  </Link>
                                )))
                            }
                          </Carousel>
                          <hr />
                          <h5 className='font_categories_class'>
                            <Link to={`/categories/Generic`} className="hover_effect1">
                              Generic
                            </Link>
                          </h5>
                        </div>
                        {/* <div className="product_cont">
                          <h5 >
                            <Link to={`/categories/Generic`} className="hover_effect1">
                              Generic
                            </Link>
                          </h5>
                        </div> */}
                      </div>
                    </div>
                  </div>
                </div>

                <div className='categories-inner-div'>
                  <div className="item" >
                    <div className="product">
                      <div className="product_wrap">
                        <div className="sale_banner" style={{ border: '2px solid #015998' }}>
                          <Carousel indicators={false}
                            autoPlay={true}
                            animation="slide"
                            duration={1500}>
                            {otc.map((item, idx) => (


                              <Link to={`/categories/OTC`} className="hover_effect1" key={idx + 1 + 'he'}>
                                <div className='productImage_div'>
                                  <img src={item.uploadImg ? item.uploadImg : heganlogo} key={'otc' + idx} />
                                </div>
                              </Link>

                            ))}
                          </Carousel>
                          <hr />
                          <h5 className='font_categories_class'>
                            <Link to={`/categories/OTC`} className="hover_effect1">
                              OTC
                            </Link>
                          </h5>
                        </div>

                      </div>
                    </div>
                  </div>
                </div>

                <div className='categories-inner-div'>
                  <div className="item" >
                    <div className="product">
                      <div className="product_wrap">
                        <div className="sale_banner" style={{ border: '2px solid #015998' }}>
                          <Carousel indicators={false}
                            autoPlay={true}
                            animation="slide"
                            duration={1500}>
                            {veterinary.length > 0 ?
                              (veterinary.map((item, idx) => (


                                <Link to={`/categories/Veterinary`} className="hover_effect1" key={idx + 'hee'}>
                                  <div className='productImage_div'>
                                    <img src={item.uploadImg ? item.uploadImg : heganlogo} key={'vet' + idx} />
                                  </div>
                                </Link>

                              ))) :
                              (
                                vetImage.map((item, idx) => (
                                  <Link to={`/categories/Veterinary`} className="hover_effect1">
                                    <div className='productImage_div'>
                                      <img src={heganlogo} key={'vet' + idx} />
                                    </div>
                                  </Link>
                                )))
                            }
                          </Carousel>
                          <hr />
                          <h5 className='font_categories_class'>
                            <Link to={`/categories/Veterinary}`} className="hover_effect1">
                              Veterinary
                            </Link>
                          </h5>
                        </div>

                      </div>
                    </div>
                  </div>
                </div>

                <div className='categories-inner-div'>
                  <div className="item" >
                    <div className="product">
                      <div className="product_wrap">
                        <div className="sale_banner" style={{ border: '2px solid #015998' }}>
                          <Carousel indicators={false}
                            autoPlay={true}
                            animation="slide"
                            duration={1500}>
                            {allopathic.length > 0 ?
                              (allopathic.map((item, idx) => (


                                <Link to={`/categories/Allopathic`} className="hover_effect1" key={idx + 'heee'}>
                                  <div className='productImage_div'>
                                    <img src={item.uploadImg ? item.uploadImg : heganlogo} key={'all' + idx} />
                                  </div>
                                </Link>

                              ))) :
                              (
                                alImage.map((item, idx) => (
                                  <Link to={`/categories/Allopathic`} className="hover_effect1">
                                    <div className='productImage_div'>
                                      <img src={item.uploadImg} key={'all' + idx} />
                                    </div>
                                  </Link>
                                )))
                            }
                          </Carousel>
                          <hr />
                          <h5 className='font_categories_class'>
                            <Link to={`/categories/Allopathic`} className="hover_effect1">
                              Allopathic
                            </Link>
                          </h5>
                        </div>

                      </div>
                    </div>
                  </div>
                </div>

                <div className='categories-inner-div'>
                  <div className="item" >
                    <div className="product">
                      <div className="product_wrap">
                        <div className="sale_banner" style={{ border: '2px solid #015998' }}>
                          <Carousel indicators={false}
                            autoPlay={true}
                            animation="slide"
                            duration={1500}>
                            {sm.length > 0 ?
                              (sm.map((item, idx) => (


                                <Link to={`/categories/Surgical & Medical Equipment`} className="hover_effect1" key={idx + 'heee'}>
                                  <div className='productImage_div'>
                                    <img src={item.uploadImg ? item.uploadImg : heganlogo} key={'sm' + idx} />
                                  </div>
                                </Link>

                              ))) :
                              (
                                smImage.map((item, idx) => (
                                  <Link to={`/categories/Surgical & Medical Equipment`} className="hover_effect1">
                                    <div className='productImage_div'>
                                      <img src={item.uploadImg} key={'sm' + idx} />
                                    </div>
                                  </Link>
                                )))
                            }
                          </Carousel>
                          <hr />
                          <h5 className='font_categories_class'>
                            <Link to={`/categories/Surgical & Medical Equipment`} className="hover_effect1">
                              Surgical & Medical Equipment
                            </Link>
                          </h5>
                        </div>

                      </div>
                    </div>
                  </div>
                </div>

                <div className='categories-inner-div'>
                  <div className="item" >
                    <div className="product">
                      <div className="sale_banner" style={{ border: '2px solid #015998' }}>
                        <Carousel indicators={false}
                          autoPlay={true}
                          animation="slide"
                          duration={1500} >
                          {ayurvedic.length > 0 ?
                            (ayurvedic.map((item, idx) => (


                              <Link to={`/categories/Ayurvedic`} className="hover_effect1" key={idx + 'heeee'}>
                                <div className='productImage_div'>
                                  <img src={item.uploadImg ? item.uploadImg : heganlogo} key={'ayur' + idx} />
                                </div>
                              </Link>

                            ))) :
                            (
                              ayurImage.map((item, idx) => (
                                <Link to={`/categories/Ayurvedic`} className="hover_effect1">
                                  <div className='productImage_div'>
                                    <img src={item.uploadImg} key={'ayur' + idx} />
                                  </div>
                                </Link>
                              )))
                          }
                        </Carousel>
                        <hr />
                        <h5 className='font_categories_class'>
                          <Link to={`/categories/Ayurvedic`} className="hover_effect1">
                            Ayurvedic
                          </Link>
                        </h5>
                      </div>

                    </div>
                  </div>
                </div>
              </div >

            </div >
          </div >

        </div >
      </div >
    </>
  );
}
export default ExploreCategoriesSlider;
