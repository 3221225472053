import React from 'react'
import { Grid, Box, Stack } from "@mui/material";

import Header from './../Header/Header';
import Footer from './../Footer/Footer';
import aboutUs from './aboutUs.png'
import { Paper, } from '@mui/material'

import './aboutUs.css'

const AboutUs = () => {

    const containerStyle = {
        fontFamily: 'Arial, sans-serif',
        margin: 0,
        padding: '20px',
        textAlign: 'justify',
    };

    const paragraphStyle = {
        marginBottom: '10px',
        fontWeight: 'bold',
        textAlign: 'justify',
    };


    return (
        <>

            <Header />




            <Paper style={{ margin: '2% 10% 5% 10% ' }} elevation={4}>
                <div className='au_topdiv' style={{ ...containerStyle, }} >
                    <p style={paragraphStyle}>Hegan.in is your dedicated B2B Pharma Marketplace, designed to streamline your Pharmaceutical industry transactions. Our platform boasts an intuitive interface, ensuring effortless navigation for users across manufacturers, distributors, wholesalers, and retailers. We prioritize user experience by offering real-time inventory updates, empowering informed decision-making based on the latest stock availability. With secure payment gateways, every transaction is safeguarded, providing peace of mind throughout your purchasing journey.
                        Quality assurance is fundamental at Hegan.in. We uphold the authenticity of our products through rigorous quality control measures and certifications, guaranteeing reliable pharmaceutical supplies for your business needs. Our aim is to be your strategic partner, not just a marketplace, facilitating efficient and seamless B2B transactions within the Pharmaceutical industry.
                    </p>

                    <img src={aboutUs} alt='about us' className='au_img' />


                </div>
                <div style={containerStyle}>

                    <p style={paragraphStyle}>We hope you will enjoy Your B2B Pharma Marketplace.</p>
                    <p style={paragraphStyle}>Thank you for visiting us.</p>
                    {/* <p style={paragraphStyle}>Dhanyawad.</p> */}
                </div>
            </Paper>

            <Footer />

        </>
    )
}

export default AboutUs