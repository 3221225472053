import * as React from 'react';
import { useNavigate, Link } from 'react-router-dom';
import useAuth from 'app/hooks/useAuth';
import Stack from '@mui/material/Stack';

const ProfileLink = () => {
  const { logout, user } = useAuth();
  const [open, setOpen] = React.useState(false);

  const handleToggleMenu = () => {
    if (open) {
      setOpen(false)
    } else {
      setOpen(true)
    }
  };

  return (
    <Stack direction="row" spacing={2}>
      <div className="btn-group"
      >
        {/* className="dropdown-toggle" */}
        <Link to="#" title="Dashboard"
          onClick={handleToggleMenu}
        >
          <i className="ti-user" ></i>
        </Link>
        {open && (
          <div className="dropdown-menu">
            <Link to="/dashboard" title="Dashboard" className="dropdown-item" href="#" style={{ fontSize: "17px" }}>
              Dashboard
            </Link>
            <div className="dropdown-divider"></div>
            <Link className="dropdown-item" to="/" onClick={logout} title="Logout" style={{ fontSize: "17px" }}>
              Logout
            </Link>
          </div>
        )
        }
      </div>
    </Stack >
  );
}

export default ProfileLink;