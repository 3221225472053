import React, { useEffect, useState, SyntheticEvent } from 'react';
import { Link, useParams, NavLink, useNavigate } from 'react-router-dom';
import useAuth from 'app/hooks/useAuth';
import { useCart } from 'app/contexts/CartContext';
import Header from './../Header/Header';
import Footer from './../Footer/Footer';
import RelatedProducts from './RelatedProducts';
import { makeStyles } from '@material-ui/core/styles';
import Accordion from '@mui/material/Accordion';
import AccordionSummary from '@mui/material/AccordionSummary';
import AccordionDetails from '@mui/material/AccordionDetails';
import Typography from '@mui/material/Typography';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import AddIcon from '@mui/icons-material/Add';
import RemoveIcon from '@mui/icons-material/Remove';
import sheild from './shield.png'

import { Formik } from 'formik';
import { LoadingButton } from '@mui/lab';

import * as Yup from 'yup';
import {
  Grid, Tab, Box, Card, CardContent, Badge,
  TextField,
  Table, TableBody, TableCell, TableContainer, TableHead, TableRow, Paper,
  Backdrop, CircularProgress
} from '@mui/material';

import TabContext from '@mui/lab/TabContext';
import TabList from '@mui/lab/TabList';
import TabPanel from '@mui/lab/TabPanel';

import axios from 'axios';
import swal from 'sweetalert';
import Moment from 'moment';
import './productDetails.css'
import heganlogo from '../HomePage/heganlogo.png'
// form field validation schema


const useStyles = makeStyles({
  payment_table_heads: {
    fontFamily: 'Poppins, sans-serif',
    fontWeight: 'bold',
  },
});
function ProductDetail() {

  const classes = useStyles();
  let { isAuthenticated,
    // user
  } = useAuth();
  const params = useParams();
  const navigate = useNavigate();
  const [allowedMinQty, setAllowedMinQty] = useState(0)

  const validationSchema = Yup.object().shape({
    quantity: Yup.number().min(allowedMinQty, `Minimum Quantity is ${allowedMinQty}`)
      .required('Quantity is required!'),
  });

  const [product, setProduct] = useState(false);
  const [loading, setLoading] = useState(false);
  const [slug, setSlug] = useState(params.slug);
  const [row_id, setRowId] = useState(params.row_id);
  const [initialValues, setInitialValues] = useState({
    quantity: '',
  });
  const { increaseCartQuantity } = useCart();
  useEffect(() => {
    if (row_id !== params.row_id) {
      setRowId(params.row_id);
    } else {
      // window.scrollTo({ top: 0, left: 0, behavior: 'smooth' });
      fetchData(slug);
    }
  }, [slug, row_id, navigate]);

  /**
   * Fetching data from server.
   */
  const fetchData = async () => {

    setLoading(true);
    try {
      // await axios.get('/sanctum/csrf-cookie').then(response => {
      await axios.get(`api/user/product/getProduct/${row_id}`, { 'slug': slug })
        .then(response => {
          console.log(response.data);
          if (response.data.status === 200) {
            setAllowedMinQty(response.data.data.minQty)
            setInitialValues({
              quantity: response.minQty,
            });
            setProduct(response.data.data);


          } else if (response.data.response_code === 401) {

          } else {

          }
          setLoading(false);
        })
        .catch(function (error) {
          setLoading(false);
          console.log(error);
        });
      // });
    } catch (e) {
      setLoading(false);
    }
  }

  const [value, setValue] = useState('1');

  const handleChange = (event, newValue) => {
    setValue(newValue);
  };

  const handleFormSubmit = async (values, errors) => {
    if (!isAuthenticated) {
      navigate('/login');
      return false;
    }

    if (values.quantity > product.availableQty) {
      return errors.setErrors({ 'quantity': 'Quantity should be less than or equal to available quantity!' });
    }
    setLoading(true);
    increaseCartQuantity(row_id, values.quantity);
    setLoading(false);
  };
  return (
    <>
      <Header />
      <div className="breadcrumb_section  page-title-mini" style={{ padding: '30px 0px' }}>
        <div className="container">
          <div className="row">
            <div className="col-md-6">
              <ol className="breadcrumb ">
                <li className="breadcrumb-item"><Link to="/">Home</Link></li>
                <li className="breadcrumb-item"><Link to="/categories?q=">Category</Link></li>
                <li className="breadcrumb-item active">{product.productName}</li>
              </ol>
            </div>
          </div>
        </div>
      </div>
      <Backdrop
        sx={{ color: '#015998', zIndex: (theme) => theme.zIndex.drawer + 1 }}
        open={loading}
        onClick={setLoading}
      >
        <CircularProgress color="inherit" />
      </Backdrop>

      <div className="section marg-btm30">

        <div className="container" style={{ position: "relative", minHeight: "400px" }}>
          {product && (
            <Grid container item rowSpacing={3} columnSpacing={5}>
              <Grid item lg={7} sm={12} xs={12}>
                <div className={classes.payment_table_heads} style={{ backgroundColor: '#015998', padding: '4px', borderRadius: '10px', color: 'white', width: '123px', height: '30px', margin: '0 0 14px 0' }}>Product Details</div>
                <div className="align-items-center prod-detail prod-detail-thumb marg-btm30" style={{ background: product.minQty > product.availableQty ? 'grayscale(100%)' :'#E2EEF4', border: '2px solid #015998', borderRadius: '10px', }}>
                  <div className="col-sm-12" >
                    <div  >
                      <img className='mySecondStyle' src={`${process.env.PUBLIC_URL}/assets/images/frontend/hint.png`} alt="Discount" />
                      <span className={`classes.payment_table_heads myFirstStyle`}>{(product.marginPercentage) ? product.marginPercentage : '0'}%</span>
                    </div>
                    <img style={{ filter: product.minQty > product.availableQty ? 'grayscale(100%)' : 'none', }} src={product.uploadImg ? `${product.uploadImg}` : heganlogo} />

                  </div>
                </div>
                <div>
                  <Accordion style={{ background: '#015998', borderRadius: '15px', }}>
                    <AccordionSummary
                      expandIcon={<AddIcon style={{ color: 'white' }} />}
                      collapseIcon={<RemoveIcon />}
                      aria-controls="panel1a-content"
                      id="panel1a-header"
                      style={{ height: '39px', minHeight: '39px' }}
                    >
                      <Typography className={`classes.payment_table_heads`} style={{ color: 'white' }}>Description</Typography>
                    </AccordionSummary>
                    <AccordionDetails style={{ background: 'white', border: '1px solid #015998', borderRadius: '15px', }}>
                      <Typography style={{ fontWeight: 'bold', }} >
                        {product.description}

                      </Typography>
                    </AccordionDetails>
                  </Accordion>
                  <Accordion style={{ background: '#015998', margin: '5px 0 0 0', borderRadius: '15px', }}>
                    <AccordionSummary
                      expandIcon={<AddIcon style={{ color: 'white' }} />}
                      aria-controls="panel2a-content"
                      id="panel2a-header"
                      style={{ height: '39px', minHeight: '39px' }}
                    >
                      <Typography className={`classes.payment_table_heads`} style={{ color: 'white' }}>Reveiw</Typography>
                    </AccordionSummary>
                    <AccordionDetails style={{ background: 'white', border: '1px solid #015998', borderRadius: '15px', }}>
                      <Typography style={{ fontWeight: 'bold', }}>
                        {product.review ? product.review : "No Reveiw Available!"}
                      </Typography>
                    </AccordionDetails>
                  </Accordion>

                </div>
              </Grid>
              <Grid item lg={5} sm={12} xs={12}>
                <div className="row align-items-center prod-detail ">
                  <div className="col-sm-12" style={{ marginTop: '35px', }}>
                    <h1 className="btn-shine">{product.productName}</h1>
                    <p><b>Formulation:</b> {product.formulation}</p>

                    <p><b>Category:</b> {product.category}</p>
                    <p><b>Sold By Seller: </b>  {product.userName}</p>
                    {product.showManufacturer == 'show' &&
                      <p><b>Firm Name:</b> {product.brand} </p>
                    }
                    {product.minQty > product.availableQty && <p style={{ color: 'red', }}><b>Out Of Stock </b></p>}

                    {product.verifiedByHegan == 'yes' &&
                      <div style={{ display: 'flex', width: '18%', justifyContent: 'space-between', margin: '0 0 1% 0', alignItems: 'center' }}>
                        <img src={sheild} style={{ width: '30%' }} />
                        {/* <Badge badgeContent={'H'} color="primary"></Badge> */}
                        <b style={{ color: 'darkgreen' }}>Verified</b>
                      </div>
                    }

                    <Card variant="outlined" /*style={{ backgroundColor: "#F4F3F3" }}*/ style={{ background: '#E2EEF4', border: '2px solid #015998', }}>
                      <CardContent>
                        <div style={{ width: '320px' }}>
                          {/* <Badge badgeContent={''} color="primary"></Badge> */}
                          {/* <div style={{ display: 'flex', width: '18%', justifyContent: 'space-between', margin: '0 0 1% 0' }}><img src={heganCheckmark} style={{ width: '26%' }} />
                            <b>Verified</b></div> */}

                          <div className={`product_info_div`} >
                            <div className={`product_info_inner_div`}>
                              <p className={`classes.payment_table_heads values_margin only_span_values`}> {'₹ Net Rate'}</p>
                              <p className={`classes.payment_table_heads values_margin only_span_values`}> {'Available Quantity'}</p>
                              <p className={`classes.payment_table_heads values_margin only_span_values`}> {'Minimum Quantity'}</p>
                              <p className={`classes.payment_table_heads values_margin only_span_values`}> {'Bonus Deal'}</p>
                            </div>


                            <div className={`product_info_inner_div`} style={{ marginLeft: '10px', }}>
                              <p className={`classes.payment_table_heads values_margin`}> <span className={`classes.payment_table_heads  span_values`}>   {(product.netRate / product.minQty).toFixed(2)}</span></p>
                              <p className={`classes.payment_table_heads values_margin`}> <span className={`classes.payment_table_heads  span_values`}>  {product.availableQty}</span></p>
                              <p className={`classes.payment_table_heads values_margin`}> <span className={`classes.payment_table_heads  span_values`}>  {(product.minQty)}</span></p>
                              <p className={`classes.payment_table_heads values_margin`}> <span className={`classes.payment_table_heads  span_values`}>  {product.bonusDeal ? product.bonusDeal : '-'}</span></p>
                            </div>
                            <div style={{ marginTop: '12px', marginLeft: '2px', }}>
                              <span style={{ fontWeight: 'bold', }} >(Unit Quantity)</span>
                            </div>
                          </div>


                          <div className={`product_info_div`} style={{ justifyContent: 'center', }}>
                            <div className={`product_info_inner_div`} style={{ width: '15rem', marginLeft: '40px', }}>

                              <p className={`classes.payment_table_heads values_margin text_color_class `} style={{ background: '#015998', color: 'white', padding: '5px', borderRadius: '9px', border: '2px solid black', textAlign: 'center', }}> {'Unit Pack:'} <span className={`classes.payment_table_heads  `}>  {product.unitPacking}</span></p>

                              {(product.expiryDate === '00/00/0000' || product.expiryDate === 'NA') ?
                                (<p className={`classes.payment_table_heads values_margin text_color_class text_color_class`} style={{ background: '#015998', color: 'white', padding: '5px', borderRadius: '9px', border: '2px solid black', textAlign: 'center', }}> {'Expiry Date:'} <span className={`classes.payment_table_heads  `}>  {'NA'}</span></p>) :
                                (
                                  <p className={`classes.payment_table_heads values_margin text_color_class text_color_class`} style={{ background: '#015998', color: 'white', padding: '5px', borderRadius: '9px', border: '2px solid black', textAlign: 'center', }}> {'Expiry Date:'} <span className={`classes.payment_table_heads  `}>  {product.expiryDate}</span></p>
                                )
                              }


                              <p className={`classes.payment_table_heads values_margin text_color_class `} style={{ background: '#015998', color: 'white', padding: '5px', borderRadius: '9px', border: '2px solid black', textAlign: 'center', }}> {'M.R.P.:'} <span className={`classes.payment_table_heads  `}>  {product.mrp}</span></p>

                            </div>
                            {/* <div className={`product_info_inner_div`}>


                              <p className={`classes.payment_table_heads values_margin text_color_class`}> <span className={`classes.payment_table_heads  `}>   {(product.netRate / product.minQty).toFixed(2)}</span></p>
                              <p className={`classes.payment_table_heads values_margin text_color_class`}> <span className={`classes.payment_table_heads  `}>  {product.availableQty}</span></p>
                              <p className={`classes.payment_table_heads values_margin text_color_class`}> <span className={`classes.payment_table_heads  `}>  {(product.minQty)}</span></p>
                            
                            </div>
                          */}
                          </div>
                        </div>

                        {/* <div>
                          <span style={{ padding: '0px 20px' }}>Earliest delivery by Sunday, 8 January</span>
                        </div> */}
                        <div style={{ padding: '20px 20px 0px 20px' }}>
                          <Formik
                            onSubmit={(values, errors) => {
                              handleFormSubmit(values, errors);
                            }
                            }
                            initialValues={initialValues}
                            validationSchema={validationSchema}
                          >
                            {({ values, errors, touched, handleChange, handleBlur, handleSubmit }) => (
                              <form onSubmit={handleSubmit} style={{ display: 'flex', alignItems: 'baseline', justifyContent: 'space-around', }}>
                                <div className="form-group" >
                                  <TextField
                                    className="login_mobile_no122 form-control1"
                                    size="small"
                                    type="number"
                                    placeholder="Enter Quantity"
                                    name="quantity"
                                    onBlur={handleBlur}
                                    value={values.quantity || ''}
                                    onChange={handleChange}
                                    helperText={touched.quantity && errors.quantity}
                                    error={Boolean(errors.quantity && touched.quantity)}
                                    inputProps={{ maxLength: 10 }}
                                    sx={{ width: '130px', background: "#FFF" }}
                                  />
                                  {/* <span className='avail-qty'>Available Quantity: {product.availableQty}</span> */}
                                </div>
                                <div className="form-group" style={{ textAlign: 'center', }}>
                                  <LoadingButton
                                    className="btn btn-fill-out btn-block"
                                    type="submit"
                                    color="primary"
                                    loading={loading}
                                    variant="contained"
                                    sx={{ mt: 2, width: '180px', }}
                                    disabled={product.minQty > product.availableQty ? true : false}
                                  >
                                    Add to cart
                                  </LoadingButton>
                                </div>
                              </form>
                            )}
                          </Formik>
                        </div>
                      </CardContent>
                    </Card>
                    {/* <div className='row sel-detail'>
                      {

                      }
                      <div className='col-sm-12 col-md-6'>Sold By Seller: {product.userName}</div>
                      <div className='col-sm-12 col-md-6' style={{ textAlign: "right" }}></div>
                      {(product.expiryDate === '00/00/0000' || product.expiryDate === 'NA') ? (<div className='col-sm-12 col-md-6'>Expiry Date: NA</div>) : (<div className='col-sm-12 col-md-6'>Expiry Date: {Moment(product.expiryDate).format('DD MMM YYYY')}</div>
                      )}

                      <div className='col-sm-12 col-md-6' style={{ textAlign: "right" }}>
                        MRP: {process.env.REACT_APP_CURRENCY} {product.mrp}
                      </div>
                    </div>

                    <TableContainer component={Paper}>
                      <Table aria-label="simple table" >
                        <TableHead>
                          <TableRow sx={{ background: "#f4f3f3" }}>
                            <TableCell align="center" sx={{ wordBreak: "unset" }}>Minimum Quantity</TableCell>
                            <TableCell align="center">Discount</TableCell>
                            <TableCell align="center" sx={{ width: "100px", wordBreak: "unset", }}>Bonus Deal</TableCell>
                            <TableCell align="center" sx={{ wordBreak: "unset" }}>Trade Rate</TableCell>
                            <TableCell align="center" sx={{ wordBreak: "unset" }}>Net Rate</TableCell>
                            <TableCell align="center">Margin%</TableCell>
                          </TableRow>
                        </TableHead>
                        <TableBody>
                          <TableRow
                            sx={{ '&:last-child td, &:last-child th': { border: 0 } }}
                          >
                            <TableCell align="center">{product.minQty}</TableCell>
                            <TableCell align="center">{product.discount}%</TableCell>
                            {product.bonusDeal ? (<TableCell align="center">{product.bonusDeal}</TableCell>) : (
                              <TableCell align="center">0</TableCell>
                            )}

                            <TableCell align="center">{product.tradeRate}</TableCell>
                            <TableCell align="center">{(product.netRate / product.minQty).toFixed(2)}</TableCell>
                            <TableCell align="center">{(((product.mrp - (product.netRate / product.minQty)) / product.mrp) * 100).toFixed(2)}%</TableCell>
                          </TableRow>
                        </TableBody>
                      </Table>
                    </TableContainer> */}
                  </div>
                </div>
              </Grid>
              {/* <Grid item lg={12} sm={12} xs={12}>
                <br />
                <TabContext value={value}>
                  <Box sx={{ borderBottom: 1, borderColor: 'divider' }}>
                    <TabList onChange={handleChange} aria-label="Product details">
                      <Tab label="Description" value="1" />
                      <Tab label="Additional information" value="2" />
                      <Tab label="Reviews" value="3" />
                    </TabList>
                  </Box>
                  <TabPanel value="1">{product.description}</TabPanel>
                  <TabPanel value="2"></TabPanel>
                  <TabPanel value="3"></TabPanel>
                </TabContext>
              </Grid> */}
            </Grid>
          )}

          <RelatedProducts />
        </div>
      </div >
      <Footer />
    </>
  );
}
export default ProductDetail;
