import React, { useEffect, useState } from 'react';

import {
    Link, NavLink, useLocation, useNavigate,
} from 'react-router-dom';
import useAuth from 'app/hooks/useAuth';
import swal from 'sweetalert';
import axios from 'axios';
import Moment from 'moment';
import Carousel from 'react-material-ui-carousel'
import { Paper, Button, Grid, Divider } from '@mui/material'


import OwlCarousel from 'react-owl-carousel';

import 'owl.carousel/dist/assets/owl.carousel.css';
import 'owl.carousel/dist/assets/owl.theme.default.css';
// import './hotSellerSlider.css'
import heganlogo from './heganlogo.png'
import formulation_bg from './formularion_blue_bg.jpg'


function FormulationSlider() {

    const [formulations, set_formulations] = useState(false);
    const [loading, setLoading] = useState(false);
    const [page, set_page] = useState(1);
    const location = useLocation();
    let { logout, user, isAuthenticated } = useAuth();

    useEffect(() => {

        if (isAuthenticated) {
            fetchProductData();
        } else {
            fetchData();
        }
    }, [location]);




    const getNextPaginatedProductData = () => {
        console.log(page);
        let nextPage = page + 1;
        set_page(nextPage)
        if (isAuthenticated) {
            fetchProductData(nextPage);
        } else {
            fetchData(nextPage);
        }
    }

    const getPrevPaginatedProductData = () => {
        console.log(page);

        let prevPage = page - 1;
        set_page(prevPage)
        if (isAuthenticated) {
            fetchProductData(prevPage);
        } else {
            fetchData(prevPage);
        }
    }

    /**
     * Fetching data from server.
     */
    const getProductsToShow = () => {
        const screenWidth = window.innerWidth;
        if (screenWidth >= 800) {
            return 6;
        } else if (screenWidth >= 500) {
            return 3;
        } else {
            return 2;
        }
    };

    const numberOfProducts = getProductsToShow();

    const fetchData = async (page_no = 1) => {
        setLoading(true);
        try {
            // await axios.get('/sanctum/csrf-cookie').then(response => {
            axios.post('api/admin/subCategory/getAllformulationCategory', {
                page: page_no, pageSize: numberOfProducts,
            })
                .then(response => {
                    console.log(response);
                    if (response.data.status === 200) {
                        set_formulations(response.data.category);
                    } else {
                    }
                    setLoading(false);
                })
                .catch(function (error) {
                    setLoading(false);
                    console.log(error);
                });
            // });
        } catch (e) {
            setLoading(false);
        }
    }

    /**
     * Fetching data from server.
     */



    const fetchProductData = async (page_no = 1) => {
        setLoading(true);
        try {
            // await axios.get('/sanctum/csrf-cookie').then(response => {
            // axios.get('api/user/product/getAll')
            axios.post('api/admin/subCategory/getAllformulationCategory', {
                page: page_no, pageSize: numberOfProducts,
            })
                .then(response => {
                    console.log(response);
                    if (response.data.status === 200) {
                        set_formulations(response.data.category);
                    } else {
                    }
                    setLoading(false);
                })
                .catch(function (error) {
                    setLoading(false);
                    console.log(error);
                });
            // });
        } catch (e) {
            // setLoading(false);
        }
    }


    const options = {
        nav: true,
        dots: false,
        autoplay: false,
        loop: true,
        margin: 20,
        responsiveClass: true,
        // navText: ['<i class="ion-ios-arrow-left"></i>', '<i class="ion-ios-arrow-right"></i>'],
        smartSpeed: 1000,
        responsive: {
            0: {
                items: 2,

            },
            400: {
                items: 2,
            },
            600: {
                items: 2,
            },
            700: {
                items: 3,
            },
            1000: {
                items: 6,

            }
        },
    };
    return (
        <>
            <div className="section small_pt prodstyel marg-btm30">
                <div >
                    <div className="row">
                        <div className="col-md-12">
                            <div className="heading_tab_header">
                                <h3 style={{ color: '#015998' }}>Shop By Formulations<Link to="/formulation" className="vall">VIEW ALL</Link></h3>
                             
                            </div>
                        </div>
                    </div>

                    <div className="row">
                        <div className="col-md-12">


                            <Carousel indicators={false}
                                // next={(next, active) => getNextPaginatedProductData()}
                                // prev={(prev, active) => getPrevPaginatedProductData()}
                                autoPlay={false} // Enable auto-play
                                animation="slide" // Set the animation to slide
                                duration={1500}
                            >
                                {formulations &&
                                    Array.from({ length: Math.ceil(formulations.length / numberOfProducts) }).map((_, rowIndex) => (
                                        <Grid container key={rowIndex} spacing={2}>
                                            {formulations.slice(rowIndex * numberOfProducts, rowIndex * numberOfProducts + numberOfProducts).map((row, index) => (
                                                <Grid item xs={6} sm={4} md={4} lg={2} key={index} >
                                                    <Paper key={'pa' + index} style={{
                                                        border
                                                            : '2px solid #015998', borderTop: '8px solid #015998'
                                                    }}>


                                                        {/* <div></div> */}
                                                        <Link to={`/formulationproduct/${row.name}`} className="hover_effect1" title={row.name}>
                                                            <div className='productImage_div' style={{
                                                                backgroundImage: `url(${formulation_bg})`, backgroundSize: 'contain',
                                                                backgroundRepeat: 'repeat',
                                                                display: 'flex',
                                                                alignItems: 'center',
                                                                textAlign: 'center',
                                                                fontSize: '21px',
                                                                justifyContent: 'center',
                                                            }}>

                                                                <p style={{ color: 'white', fontWeight: 'bold' }} key={"h2" + index}>{row.name}</p>
                                                            </div>
                                                        </Link>



                                                    </Paper>

                                                </Grid>
                                            ))}
                                        </Grid>
                                    ))
                                }
                            </Carousel>

                        </div>
                    </div>
                </div>
            </div >
        </>
    );
}
export default FormulationSlider;
