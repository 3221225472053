import React from 'react'
import Carousel from 'react-material-ui-carousel'
import Item from './Item'
import { useState } from 'react'
// import Introduction from './introduction.png'



import { Link } from 'react-router-dom';
// import OwlCarousel from 'react-owl-carousel';


import './contactInfo.css'

// import boc from './boc.png'
// import heroBg from './heroBg.png'
import infoFirst from './infoFirst.png'
import infoSecond from './infoSecond.png'
// import phoneBg from './phoneBg.png'

import mob_mockup from './mob_mockup.mp4';

const CarouselFile = () => {

  const [slider, setSlider] = useState(
    [
      { "id": 100, "image": infoFirst, "title": 'infoFirst' },
      // { "id": 200, "image": boc, "title": 'intoduction' },
      { "id": 300, "image": infoSecond, "title": 'infoSecond' },
      // { "id": 400, "image": buyWithUs2, "title": 'buyWithUs2' },
    ]
  )

  const options = {
    nav: false,
    dots: false,
    autoplay: true,
    loop: true,
    margin: 0,
    responsiveClass: true,

    animateIn: 'fadeIn',
    animateOut: 'fadeOut',
    // navText: ['<i class="ion-ios-arrow-left"></i>', '<i class="ion-ios-arrow-right"></i>'],
    smartSpeed: 1000,
    responsive: {
      0: {
        items: 1,
      },
      400: {
        items: 1,
      },
      600: {
        items: 1,
      },
      700: {
        items: 1,
      },
      1000: {
        items: 1,

      }
    },
  };

  return (
    <>
      <div className='section div_img' >
        <div className='phone_bg' >
          <video autoPlay loop muted className='vid_style'>
            <source src={mob_mockup} />
          </video>

        </div>

        <div style={{ width: '40%', margin: '0 0 0 0' }}>


          <Carousel>
            {slider.map((item, index) => (


              <img className='info_carousel_img' src={item.image} alt={item.title} key={'img' + item.id} />



            ))}
          </Carousel>



        </div>

      </div>
    </>
  )
}

export default CarouselFile