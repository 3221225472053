import React, { useEffect, useState } from 'react';
import useAuth from 'app/hooks/useAuth';
import { useNavigate, Link, useLocation, useSearchParams, useParams } from 'react-router-dom';
import ProfileLink from './ProfileLink';
import MiniCart from './MiniCart';
import { Formik, FieldArray, ErrorMessage } from 'formik';
import AppBar from '@mui/material/AppBar';
import Box from '@mui/material/Box';
import Toolbar from '@mui/material/Toolbar';
import IconButton from '@mui/material/IconButton';
import Typography from '@mui/material/Typography';
import Menu from '@mui/material/Menu';
import MenuIcon from '@mui/icons-material/Menu';
import Container from '@mui/material/Container';
import Avatar from '@mui/material/Avatar';
import Button from '@mui/material/Button';
import Tooltip from '@mui/material/Tooltip';
import MenuItem from '@mui/material/MenuItem';
import AdbIcon from '@mui/icons-material/Adb';
import SendOutlinedIcon from '@mui/icons-material/SendOutlined';


import "./header.css"
import "./../assets/css/animate.css";
import "./../assets/bootstrap/css/bootstrap.min.css";
import "./../assets/css/all.min.css";
import "./../assets/css/ionicons.min.css";
import "./../assets/css/themify-icons.css";
import "./../assets/css/linearicons.css";
import "./../assets/css/flaticon.css";
import "./../assets/css/simple-line-icons.css";

import "./../assets/css/magnific-popup.css";
import "./../assets/css/slick.css";
import "./../assets/css/slick-theme.css";

import "./../assets/css/style.css";
import "./../assets/css/custom.css";
import "./../assets/css/responsive.css";

import heganlogo from './heganlogo.png'
import heganNameLogo from './heganNameLogo.png'
import hamburgermenu from './hamburgermenu.png'
import { Divider } from '@mui/material';


const pages = ['Products', 'Pricing', 'Blog'];
const settings = ['Profile', 'Account', 'Dashboard', 'Logout'];

const Header = ({ searchTest = '', setSearchTest }) => {

  let { forceLogout, user, isAuthenticated } = useAuth();
  const navigate = useNavigate();
  const location = useLocation();
  const { category_id, brand, margin_id, sub_category, formulation } = useParams();
  const [searchParams, setSearchParams] = useSearchParams();

  const [anchorElNav, setAnchorElNav] = React.useState(null);
  const [anchorElUser, setAnchorElUser] = React.useState(null);


  const [windowWidth, setWindowWidth] = useState(window.innerWidth);
  const [showHamBurger, setShowHamBurger] = useState(true);
  const [isMenuOpen, setMenuOpen] = useState(false);

  useEffect(() => {
    const handleResize = () => {
      setWindowWidth(window.innerWidth);
    };

    window.addEventListener('resize', handleResize);

    return () => {
      window.removeEventListener('resize', handleResize);
    };
  }, []);

  const toggleMenu = () => {
    setMenuOpen(!isMenuOpen);

  };
  const handleOpenNavMenu = (event) => {
    setAnchorElNav(event.currentTarget);
  };
  const handleOpenUserMenu = (event) => {
    setAnchorElUser(event.currentTarget);
  };

  const handleCloseNavMenu = () => {
    setAnchorElNav(null);
  };

  const handleCloseUserMenu = () => {
    setAnchorElUser(null);
  };
  
  const logthemOut = () => {
    if (localStorage.getItem('login-mobile-no') == null) {
      forceLogout();
    }
  }
  useEffect(() => {
    logthemOut();
  }, [])

  useEffect(() => {
    if (isAuthenticated) {
      if (location.pathname === '/partner-register' ||
        location.pathname === '/login' ||
        location.pathname === '/login-otp') {
        navigate('/')
      }
    } else {
      // if (location.pathname === '/partner-register' ||
      //   location.pathname === '/login' ||
      //   location.pathname === '/login-otp'
      // ) {

      // } else {
      //   if (location.pathname !== '/') {
      //     navigate('/');
      //   }
      // }
    }
  }, [location]);
  // inital login credentials
  const initialValues = {
    q: (searchParams.get("q")) ? searchParams.get("q") : ''
  };

  const handleFormSubmit = (values, errors) => {
    // console.log(values);
    // console.log(location.pathname);
    // console.log(category_id);
    // console.log(searchParams);
    if (typeof (setSearchTest) === 'function') {
      // console.log(searchTest, values.q);
      setSearchTest(values.q);
    }
    if ((location.pathname == '/categories') || (location.pathname == '/')) {
      if (category_id) {
        navigate(`/categories/${category_id}?q=${values.q}`);
      } else {
        navigate(`/categories?q=${values.q}`);
      }
    }
    else if (location.pathname == '/specialOffersViewAll') {
      if (category_id) {
        navigate(`/specialOffersViewAll/${category_id}?q=${values.q}`);
      } else {
        navigate(`/specialOffersViewAll?q=${values.q}`);
      }
    } else if (location.pathname == '/brand' || location.pathname.split('/')[1] == 'brand') {
      // if (brand) {
      //   navigate(`/brand/${brand}?q=${values.q}`);
      // } else {
      navigate(`/brand?q=${values.q}`);
      // }
    } else if (location.pathname == '/subCategories' || location.pathname.split('/')[1] == 'subCategories') {
      // if (brand) {
      //   navigate(`/brand/${brand}?q=${values.q}`);
      // } else {
      navigate(`/subCategories?q=${values.q}`);
      // }
    } else {
      if (category_id) {
        navigate(`/categories/${category_id}?q=${values.q}`);
      } else {
        navigate(`/categories?q=${values.q}`);
      }
    }


  };



  return (
    <>
      {(isAuthenticated ) ?
        (isMenuOpen && windowWidth < 800 &&
          (<div className='menu-items-header-2'>

            <Link to="#" title="Dashboard">
              <div>
                <ProfileLink />
              </div>

            </Link>
            <div
            ><Divider />
            </div>



            <MiniCart />


          </div>))
        :
        (isMenuOpen && windowWidth < 800 && (
          <div className='menu-items-header'>
            <Link to="/login" title="Login">
              <Button variant='contained' style={{ width: '100%' }}>Login</Button>
            </Link>
            {/* <hr /> */}
            <Link to="/partner-register" title="Register">
              <Button variant='contained' style={{ width: '100%' }} >Register</Button>
            </Link>
            {/* <hr /> */}
            <Link to='/about-us' style={{ cursor: 'pointer', color: 'white' }} title='About Us'>
              <Button variant='contained' style={{ width: '100%' }}>About Us</Button>
            </Link>
          </div>
        ))}



      <div>
        <div style={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center', margin: '5px 10px ' }}>
          {/* <div >
            <Link to="/">
              <img height="50px" src={heganlogo} alt="logo" />
            </Link>
          </div> */}

          {/* <div className='hide-this socialmedia-header-div' >
            <div><a href="https://www.facebook.com/Hegan.in" target="_blank" title='Facebook'>
              <i className="ion-social-facebook" style={{ fontSize: '2em' }}></i>
            </a></div>
            <div><a href="https://instagram.com/hegan.in" target="_blank" title='Instagram'>
              <i className="ion-social-instagram-outline" style={{ fontSize: '2em' }}></i>
            </a></div>
            <div><a href="https://twitter.com/Hegan_in" target="_blank" title='Twitter'>
              <i className="ion-social-twitter" style={{ fontSize: '2em' }}></i>
            </a></div>
            <div><a href="http://wa.aisensy.com/+919670767909" target="_blank" title='WhatsApp'>
              <i className="icon ion-social-whatsapp" style={{ fontSize: '2em' }}></i>
            </a></div>
            <div><a href="https://www.linkedin.com/company/hegan/" target="_blank" title='LinkedIn'>
              <i className="ion-social-linkedin" style={{ fontSize: '2em' }}></i>
            </a></div>
          </div> */}
          <div style={{ display: 'flex', justifyContent: 'space-between' }} >
            <Link to="/">
              <img height="50px" src={heganlogo} alt="logo" />
            </Link>

            <img src={heganNameLogo} className='heganNameLogo' />

            {showHamBurger && windowWidth < 800 && (
              <div className='hamburger-menu' onClick={toggleMenu}>
                <img src={hamburgermenu} alt='Hamburger Menu' />
              </div>
            )}
          </div>




          {isAuthenticated ? (
            <>
              <div className='hide-this profile-header-div' >
                <div>
                  {/* <li> */}
                  <ProfileLink />
                  {/* </li> */}
                </div>
                <div>
                  {/* <li> */}
                  <MiniCart />
                  {/* </li> */}
                </div>
              </div>

            </>
          ) : (
            <>
              <div className='hide-this login-register-header-div'>
                <Link to='/about-us' style={{ cursor: 'pointer', color: 'white' }} title='About Us'>
                  <div>
                    <Button variant='contained' className='buttonWithBorderRadius' style={{ borderRadius: '10px' }}>About Us</Button>
                  </div>
                </Link>
                <Link to="/login" title="Login">
                  <div>
                    <Button variant='contained' className='buttonWithBorderRadius' style={{ borderRadius: '10px' }}>Login</Button>
                  </div>
                </Link>

                <Link to="/partner-register" title="Register">
                  <div>
                    <Button variant='contained' className='buttonWithBorderRadius' style={{ borderRadius: '10px' }}>Register</Button>
                  </div>
                </Link>

              </div>


            </>
          )}

        </div>
      </div >
      <div className='search-header-div'  >
        <Formik
          onSubmit={(values, errors) => {
            handleFormSubmit(values, errors);
          }
          }
          initialValues={initialValues}
        // validationSchema={validationSchema}
        >
          {({ values, errors, touched, handleChange, handleBlur, handleSubmit, setFieldValue }) => (
            <form onSubmit={handleSubmit} style={{ display: 'flex', position: 'relative' }}>


              {/* <i
                    className="linearicons-magnifier"
                    style={{
                      position: 'absolute',
                      left: '10px',
                      top: '50%',
                      transform: 'translateY(-50%)',
                    }}
                  ></i> */}

              {location.pathname.split('/')[1] == 'formulation' ? (
                <>



                </>
              ) :
                (
                  <>
                    <input
                      className="searchInput"
                      name="q"
                      onBlur={handleBlur}
                      value={values.q}
                      onChange={(e) => {
                        handleChange(e);
                        // setSearchTest(e.target.value);
                        // handleFormSubmit(values, errors)
                      }}
                      placeholder="Search for Products, Categories and more"
                      required=""
                      type="text"
                      style={{ color: 'grey' }}
                    />

                    <Button type="submit" style={{
                      position: 'absolute',
                      right: '1px',
                      height: '100%', backgroundColor: '#015998',
                      borderRadius: '0 12px 12px 0', border: 'none', width: '50px'
                    }}>
                      <i className="linearicons-magnifier" style={{
                        fontSize: '1.2rem', color: 'white', backgroundColor: '#015998', position: 'absolute', right: '16px',
                        top: '50%',
                        transform: 'translateY(-50%)',

                      }}>
                      </i>


                    </Button>
                  </>

                )}






            </form>
          )}
        </Formik>


      </div>





    </>
  );
};

export default Header;
