import { useState, useEffect } from "react";
import { Link, useParams, NavLink, useNavigate } from 'react-router-dom';
import { Breadcrumb, SimpleCard } from "app/components";
import { LoadingButton } from '@mui/lab';
import { Formik, Form, Field, FieldArray } from 'formik';
import * as Yup from 'yup';
import axios from 'axios';
import swal from 'sweetalert';
import {
  Card, CardContent, CardHeader,
  Divider,
  Box,
  Grid,
  Button,
  styled,
  TextField,
  useTheme,
  RadioGroup, FormControlLabel, Radio,
  FormControl, Select, MenuItem,
  FormGroup, Checkbox

} from "@mui/material";


const Container = styled("div")(({ theme }) => (
  {
    margin: "30px",
    [theme.breakpoints.down("sm")]: { margin: "16px" },
    "& .breadcrumb": {
      marginBottom: "30px",
      [theme.breakpoints.down("sm")]: { marginBottom: "16px" },
    },
  }
));

const ContentBox = styled(Box)(() => ({
  height: '100%',
  padding: '32px',
  position: 'relative',
  // background: 'rgba(0, 0, 0, 0.01)',
}));

// inital login credentials
const initialValues = {
  registerType: '',
  plan: [],
  firstName: '',
  lastName: '',
  email: '',
  firmName: '',
  userName: '',
  mobile: '',
  categories: [],
  address: '',
  pinCode: '',
  dl_no_20b: '',
  dl_no_21b: '',
  gst_no: '',
  panCard: '',
  fssai_no: '',
  account_name: '',
  account_no: '',
  ifsc_code: '',
  branch: '',
  state: '',
  uploadSign: '',
};
// form field validation schema
// .matches(/(\+91\ )[6-9]{1}[0-9 ]{4}[0-9 ]{4}[0-9]{3}/, {
const validationSchema = Yup.object().shape({
  firstName: Yup.string()
    .required('First Name is required!'),
  lastName: Yup.string()
    .required('Last Name is required!'),
  firmName: Yup.string()
    .required('Firm Name is required!'),
  gst_no: Yup.string()
    .min(15, 'GST number must be 15 digit length!')
    .max(15, 'GST number must be 15 digit length!')
    .matches(/^([0-9]{2}[a-zA-Z]{4}([a-zA-Z]{1}|[0-9]{1})[0-9]{4}[a-zA-Z]{1}([a-zA-Z]|[0-9]){3}){0,15}$/, { message: "Please enter valid GST number.", excludeEmptyString: false }),
  mobile: Yup.string()
    .required('Mobile number is required!')
    .min(10, 'Mobile number must be 10 digit length!')
    .max(10, 'Mobile number must be 10 digit length!')
    .matches(/^[6-9]\d{9}$/, { message: "Please enter valid mobile number!", excludeEmptyString: false }),
  address: Yup.string()
    .required('Address is required!'),
  email: Yup.string().email('Invalid Email address').required('Email is required!'),
  // address: Yup.string()
  //   .required('Address is required!'),
  state: Yup.array()
    .required('State is required!'),
  pinCode: Yup.string()
    .min(6, "Pin Code must be 6 digit!")
    .max(6, "Pin Code must be 6 digit!")
    .required('Pin Code is required!'),
  // categories: Yup
  //   .string()
  //   .required("Select at least one category"),
  account_name: Yup.string().when('registerType', {
    is: (registerType) => registerType == 'Manufacturer' || registerType == 'Distributor' || registerType == 'Wholesaler',
    then: Yup.string().required('Account Name is required!'),
    otherwise: Yup.string(),
  }),
  account_no: Yup.string().when('registerType', {
    is: (registerType) => registerType === 'Manufacturer' || registerType === 'Distributor' || registerType === 'Wholesaler',
    then: Yup.string()
      .min(10, "Account Number must be 10 to 16 digit!")
      .max(16, "Account Number must be 10 to 16 digit!")
      .required('Account Number is required!'),
    otherwise: Yup.string(),
  }),
  ifsc_code: Yup.string().when('registerType', {
    is: (registerType) => registerType === 'Manufacturer' || registerType === 'Distributor' || registerType === 'Wholesaler',
    then: Yup.string()
      .min(8, "IFSC Code must be 8 to 13 alphanumeric!")
      .max(13, "IFSC Code must be 8 to 13 alphanumeric!")
      .required('IFSC Code is required!'),
    otherwise: Yup.string(),
  }),
  branch: Yup.string().when('registerType', {
    is: (registerType) => registerType === 'Manufacturer' || registerType === 'Distributor' || registerType === 'Wholesaler',
    then: Yup.string()
      .min(3, "Branch must be 3 charectors!")
      .required('Branch is required!'),
    otherwise: Yup.string(),
  }),

});

const UpdateProfile = () => {

  const { user_id } = useParams();
  const theme = useTheme();
  const navigate = useNavigate();
  const [loading, setLoading] = useState(false);
  const [user, setUser] = useState(initialValues);
  const [states, setStates] = useState([
    ["Andhra Pradesh,AP", "AP"],
    ["Arunachal Pradesh", "AR"],
    ["Assam", "AS"],
    ["Bihar", "BR"],
    ["Chhattisgarh", "CG"],
    ["Goa", "GA"],
    ["Gujarat", "GJ"],
    ["Haryana", "HR"],
    ["Himachal Pradesh", "HP"],
    ["Jammu and Kashmir", "JK"],
    ["Jharkhand", "JH"],
    ["Karnataka", "KA"],
    ["Kerala", "KL"],
    ["Madhya Pradesh", "MP"],
    ["Maharashtra", "MH"],
    ["Manipur", "MN"],
    ["Meghalaya", "ML"],
    ["Mizoram", "MZ"],
    ["Nagaland", "NL"],
    ["Orissa", "OR"],
    ["Punjab", "PB"],
    ["Rajasthan", "RJ"],
    ["Sikkim", "SK"],
    ["Uttarakhand", "UK"],
    ["Uttar Pradesh", "UP"],
    ["West Bengal", "WB"],
    ["Tamil Nadu", "TN"],
    ["Tripura", "TR"],
    ["Andaman and Nicobar Islands", "AN"],
    ["Chandigarh", "CH"],
    ["Dadra and Nagar Haveli", "DH"],
    ["Daman and Diu", "DD"],
    ["Delhi", "DL"],
    ["Lakshadweep", "LD"],
    ["Pondicherry", "PY"],
  ]);


  // state update logic
  const [userState, setUserState] = useState([])
  const handleUserStateChange = (event) => {
    console.log(event.target.value);
    let state = event.target.value;
    setUserState(state)
  }

  const setUserStateWithAbbreviation = (data) => {
    let state = data.state;
    // console.log(data.state,state);
    for (let i = 0; i < states.length; i++) {
      // console.log(state,states[i][0],'hi');
      if (state == states[i][0]) {
        data.state = states[i];
        // console.log(data.state,states[i]);
      }
    }
    // console.log(data);
    setFormData(data);
    setUser(data);
  }


  const [plans, setPlans] = useState(false);
  const [categories, setCategory] = useState(false);
  const [formData, setFormData] = useState(initialValues);

  const [DLOpen, setDLOpen] = useState(false);
  const [fssaiOpen, setFssaiOpen] = useState(false);
  const [userCategories, setUserCategories] = useState([]);

  const addCategoriesId = (data) => {
    for (let i = 0; i < data.length; i++) {
      // userCategories.push(data[i].id);
      // console.log(187,data[i]);
      setUserCategories([data[i].categoryName, ...userCategories])
    }
    // console.log(userCategories);

  }

  useEffect(() => {
    fetchData();
  }, []);

  /**
   * Fetching data from server.
   */
  const fetchData = async () => {
    setLoading(true);
    try {
      // await axios.get('/sanctum/csrf-cookie').then(response => {
      // axios.post('/api/client/get-states')
      //   .then(response => {
      //     if (response.data.response_code === 200) {
      //       setStates(response.data.data);
      //     } else if (response.data.response_code === 401) {

      //     }
      //     setLoading(false);
      //   })
      //   .catch(function (error) {
      //     setLoading(false);
      //     console.error(error);
      //   });

      const token = localStorage.getItem('accessToken')
      const config = { headers: { "x-api-key": token } };

      axios.get('api/admin/medicineCategory/getList', config)
        .then(response => {
          // console.log(response);
          if (response.data.status === 200) {
            setCategory(response.data.data);
          } else if (response.data.status === 401) {

          }
          setLoading(false);
        })
        .catch(function (error) {
          setLoading(false);
          console.error(error);
        });

      axios.get('api/admin/plan/getAll', config)
        .then(response => {
          // console.log(response);
          if (response.data.status === 200) {
            // console.log(response.data.status);
            setPlans(response.data.data);
          } else if (response.data.status === 401) {

          }
          setLoading(false);
        })
        .catch(function (error) {
          setLoading(false);
          console.error(error);
        });

      axios.get(`api/user/getUserByid/${user_id}`, config)
        .then(response => {
          console.log(response);
          if (response.data.status === 200) {
            // setFormData(response.data.data);
            // setUser(response.data.data);
            setUserStateWithAbbreviation(response.data.data);
            // console.log([response.data.data.state,response.data.data.indianStateAbbreviation]);
            setUserState([response.data.data.state, response.data.data.indianStateAbbreviation])
            // setUserCategories(response.data.data.userMedicinecommissions);
            addCategoriesId(response.data.data.userMedicinecommissions)
            // for(let i=0)
            // console.log(response.data.data.userMedicinecommissions)
            var selectedUserCat = response.data.data.userMedicinecommissions;
            // Set Default Cat Option
            if (selectedUserCat.length == 1) {
              if (selectedUserCat[0] == 5) {
                setDLOpen(false);
                setFssaiOpen(false);
              } else if (selectedUserCat[0] == 4) {
                setDLOpen(false);
                setFssaiOpen(true);
              } else {
                setDLOpen(true);
                setFssaiOpen(true);
              }
            } else if (selectedUserCat.length) {
              setDLOpen(true);
              setFssaiOpen(true);
            } else {
              setDLOpen(false);
              setFssaiOpen(false);
            }

          } else if (response.data.response_code === 401) {

          } else {

          }
          setLoading(false);
        })
        .catch(function (error) {
          setLoading(false);
          console.error(error);
        });
      // });
    } catch (e) {
      setLoading(false);
    }
  }

  const handleChange = (e) => {
    // console.log(e.target.name + '-' + e.target.value);
    setFormData({ ...formData, [e.target.name]: e.target.value });
    // console.log(formData);
  };

  const changeHandler = (e) => {
    setFormData({ ...formData, [e.target.name]: e.target.files[0] });
  };

  const handleFormSubmit = (values, errors) => {
    // try {
    //   axios.get('/sanctum/csrf-cookie').then(response => {
    //     const uploadFormData = new FormData();
    //     uploadFormData.append(user_id, user_id)
    //     for (let iKey in values) {
    //       if (iKey === 'update_signature' || iKey === 'user_categories') {
    //         uploadFormData.append(iKey, formData[iKey])
    //         // for (let file of formData[iKey]) {
    //         //   uploadFormData.append('picture', file);
    //         // }
    //       } else {
    //         uploadFormData.append(iKey, values[iKey])
    //       }

    //     }
    //     const config = { headers: { "Content-Type": "multipart/form-data" } };
    //     axios.post('/api/client/update-profile', uploadFormData, config)
    //       .then(response => {
    //         if (response.data.response_code === 200) {
    //           swal({
    //             title: "Good job!",
    //             text: response.data.message,
    //             icon: "success",
    //           });
    //           // navigate('/');
    //         } else {
    //           swal({
    //             title: "Warning!",
    //             text: response.data.message,
    //             icon: "warning",
    //           });
    //           errors.setErrors(response.data.errors);
    //         }
    //         setLoading(false);
    //       })
    //       .catch(function (error) {
    //         console.error(error);
    //       });
    //   });
    // } catch (e) {
    //   console.log(e);
    //   setLoading(false);
    // }

    try {
      // axios.get('/sanctum/csrf-cookie').then(response => {
      console.log(values);
      const uploadFormData = new FormData();
      uploadFormData.append(user_id, user_id)
      for (let iKey in values) {
        if (iKey === 'uploadSign') {
          uploadFormData.append(iKey, formData[iKey])
          // for (let file of formData[iKey]) {
          //   uploadFormData.append('picture', file);
          // }
        } else if (iKey === 'userMedicinecommissions') {
          uploadFormData.append(iKey, userCategories)
        }
        else if (iKey === 'state') {
          uploadFormData.append(iKey, userState)
        }
        else if (iKey === 'plans') {
          let userPlans = values.plans;
          console.log(values.plans);
          let planNames = [];
          for (let i = 0; i < values.plans.length; i++) {
            planNames.push(values.plans[i].plan)
          }
          uploadFormData.append(iKey, JSON.stringify(planNames));

        } else {
          uploadFormData.append(iKey, values[iKey])
        }

      }

      const token = localStorage.getItem('accessToken')
      const config = { headers: { "Content-Type": "multipart/form-data", "x-api-key": token } };
      axios.put(`api/user/updateUser/${user_id}`, uploadFormData, config)
        .then(response => {
          console.log(response);
          if (response.data.status === 200) {
            swal({
              title: "Good job!",
              text: "Profile updated Successfully",
              icon: "success",
            });
            // navigate('/');
          } else {
            swal({
              title: "Warning!",
              text: response.data.message,
              icon: "warning",
            });
            // errors.setErrors(response.data.errors);
          }
          setLoading(false);
        })
        .catch(function (error) {
          console.error(error);
        });
      // });
    } catch (e) {
      console.log(e);
      setLoading(false);
    }
  };

  return (
    <>
      <Container>
        <Box className="breadcrumb">
          <Breadcrumb routeSegments={[{ name: "dashboard", path: "/dashboard" }, { name: "Update Profile" }]} />
        </Box>

        <Card title="Update Profile">
          <ContentBox>
            <CardHeader
              title="Update Profile"
              titleTypographyProps={{
                variant: "h4",
                fontSize: '18px',
                fontWeight: '600',
                // textTransform: 'uppercase',
                color: theme.palette.primary.main
              }}
              action={
                <>
                  {/* <Button title="Edit" component={Link}
                    to={`/dashboard/profile/edit`}
                    color="info" variant="outlined" size="small" sx={{ m: .5 }}>
                    Edit
                  </Button> */}
                  <Button title="Back" component={Link}
                    to={`/dashboard`}
                    color="primary" variant="outlined" size="small" sx={{ m: .5 }}>
                    Back
                  </Button>
                </>
              }
            />
            <Divider />
            <CardContent>
              <Box width="100%" overflow="auto">
              {user && (
                  <>
                    <Formik
                      onSubmit={(values, errors) => {
                        // console.log(values,userCategories);
                        handleFormSubmit(values, errors);
                      }
                      }
                      enableReinitialize={true}
                      initialValues={user}
                      validationSchema={validationSchema}
                    >
                      {({ values, errors, touched, handleChange, handleBlur, handleSubmit }) => (
                        <form onSubmit={handleSubmit}>
                          <Grid container style={{ padding: '20px' }} spacing={2}>
                            <Grid item sm={6} xs={12}>
                              <label htmlFor="firstName" className='form-label'>First Name</label>
                              <TextField
                                fullWidth
                                size="small"
                                type="text"
                                name="firstName"
                                // label="Name"
                                variant="outlined"
                                onBlur={handleBlur}
                                value={values.firstName}
                                onChange={handleChange}
                                helperText={touched.firstName && errors.firstName}
                                error={Boolean(errors.firstName && touched.firstName)}
                                sx={{ mb: 3 }}
                              />
                            </Grid>
                            <Grid item sm={6} xs={12}>
                              <label htmlFor="lastName" className='form-label'>Last Name</label>
                              <TextField
                                fullWidth
                                size="small"
                                type="text"
                                name="lastName"
                                // label="Name"
                                variant="outlined"
                                onBlur={handleBlur}
                                value={values.lastName}
                                onChange={handleChange}
                                helperText={touched.lastName && errors.lastName}
                                error={Boolean(errors.lastName && touched.lastName)}
                                sx={{ mb: 3 }}
                              />
                            </Grid>
                            <Grid item sm={6} xs={12} lg={12}>
                              <label htmlFor="firmName" className='form-label'>Firm Name</label>
                              <TextField
                                fullWidth
                                size="small"
                                type="text"
                                name="firmName"
                                // label="Name"
                                variant="outlined"
                                onBlur={handleBlur}
                                value={values.firmName}
                                onChange={handleChange}
                                helperText={touched.firmName && errors.firmName}
                                error={Boolean(errors.firmName && touched.firmName)}
                                sx={{ mb: 3 }}
                              />
                            </Grid>

                            <Grid item sm={6} xs={12}>
                              <label htmlFor="email" className='form-label'>Email ID</label>
                              <TextField
                                fullWidth
                                size="small"
                                type="email"
                                name="email"
                                // label="Name"
                                variant="outlined"
                                onBlur={handleBlur}
                                value={values.email}
                                onChange={handleChange}
                                helperText={touched.email && errors.email}
                                error={Boolean(errors.email && touched.email)}
                                sx={{ mb: 3 }}
                              />
                            </Grid>
                            <Grid item sm={6} xs={12}>
                              <label htmlFor="mobile" className='form-label'>Mobile No.</label>
                              {/* <PhoneInput
                        country={"in"}
                        value={values.mobile}
                        name="mobile"
                        onChange={handleChange}
                        onBlur={(e) => handleBlur(e)}
                        defaultMask=".... ... ..."
                        masks={{ in: ".........." }}
                        onlyCountries={["in"]}
                        inputProps={{
                          name: "mobile",
                          required: true,
                        }}
                        disableSearchIcon={true}
                        disableDropdown={true}
                        containerClass="h-12 text-sm focus:outline-none block w-full bg-gray-100 dark:bg-transparent border-transparent focus:bg-white"

                        // containerClass="h-12 text-sm focus:outline-none block w-full bg-gray-100 dark:bg-transparent border-transparent focus:bg-white"
                        inputClass="border h-12 text-sm focus:outline-none block w-full bg-gray-100 dark:bg-transparent border-transparent focus:bg-white"
                        inputStyle={{
                          background: "transparent",
                          // border: "1px solid grey",
                          height: "2.70em",
                          width: "100%",
                          outline: "none",

                        }}
                        buttonStyle={{
                          // height: "3em",
                          // background: "transparent",
                          // outline: "none",
                          // border: "none",
                        }}
                        style={{ marginBottom: "22px" }}
                      /> */}
                              <TextField
                                fullWidth
                                size="small"
                                type="text"
                                name="mobile"
                                // label="Name"
                                variant="outlined"
                                onBlur={handleBlur}
                                value={values.mobile}
                                onChange={handleChange}
                                helperText={touched.mobile && errors.mobile}
                                error={Boolean(errors.mobile && touched.mobile)}
                                sx={{ mb: 3 }}
                                inputProps={
                                  { maxLength: 10 }
                                }
                              />
                            </Grid>
                            <Grid item sm={6} xs={12}>
                              <label htmlFor="address" className='form-label'>Address</label>
                              <TextField
                                fullWidth
                                size="small"
                                type="text"
                                name="address"
                                // label="Name"
                                variant="outlined"
                                onBlur={handleBlur}
                                value={values.address}
                                onChange={handleChange}
                                helperText={touched.address && errors.address}
                                error={Boolean(errors.address && touched.address)}
                                sx={{ mb: 3 }}
                              />
                            </Grid>

                            <Grid item sm={6} xs={12}>
                              <label htmlFor="state" className='form-label'>State</label>
                              <FormControl fullWidth
                                sx={{ mb: 3 }}
                                size="small"
                              >
                                <Select
                                  labelId="state-label"
                                  id="brand_id"
                                  name="state"
                                  // label="Select States"
                                  // value={values.state}
                                  onChange={(event) => { handleUserStateChange(event) }}
                                  variant="outlined"
                                  onBlur={handleBlur}
                                  // defaultValue={values.state.toString()}
                                  error={Boolean(errors.state && touched.state)}
                                >
                                  <MenuItem value="" key="sg">Select State</MenuItem>
                                  {states && states.map((row) =>
                                    <MenuItem value={row} key={row[1]}>{row[0]} ({row[1]})</MenuItem>
                                  )}
                                </Select>
                              </FormControl>
                            </Grid>
                            <Grid item sm={6} xs={12}>
                              <label htmlFor="pinCode" className='form-label'>Pin Code</label>
                              <TextField
                                fullWidth
                                size="small"
                                type="number"
                                name="pinCode"
                                // label="Name"
                                variant="outlined"
                                onBlur={handleBlur}
                                value={values.pinCode}
                                onChange={handleChange}
                                helperText={touched.pinCode && errors.pinCode}
                                error={Boolean(errors.pinCode && touched.pinCode)}
                                sx={{ mb: 3 }}
                                inputProps={{ min: 6 }}
                              />
                            </Grid>
                            <Grid item sm={6} xs={12}>
                              <label htmlFor="gst_no" className='form-label'>GST No</label>
                              <TextField
                                fullWidth
                                size="small"
                                type="text"
                                name="gst_no"
                                // label="Name"
                                variant="outlined"
                                onBlur={handleBlur}
                                value={values.gst_no}
                                onChange={handleChange}
                                helperText={touched.gst_no && errors.gst_no}
                                error={Boolean(errors.gst_no && touched.gst_no)}
                                sx={{ mb: 3 }}
                              />
                            </Grid>
                          </Grid>
                          <Grid container style={{ padding: '0px 20px' }} spacing={2}>
                            <Grid item sm={12} xs={12} lg={12}>
                              <h3 style={{ marginLeft: 0, fontSize: '17px' }}>Categories</h3>
                            </Grid>
                            <FormControl sx={{ mb: 3 }} component="fieldset" variant="standard" style={{ padding: "0px 0px 0px 16px" }}>
                              <FormGroup row={true}>
                                {categories ? categories
                                  .map((row, index) => {
                                    // console.log(614,row,row.id,userCategories);
                                    return (<Grid item sm={6} xs={12} lg={4} key={index}>
                                      <FormControlLabel
                                        control={
                                          <Checkbox
                                            checked={userCategories.includes(row.categoryName)}
                                            name={`userMedicinecommissions[${index}].categoryName`}
                                            value={row.categoryName ? row.categoryName : ''}
                                            onChange={e => {
                                              if (e.target.checked) {
                                                userCategories.push(row.categoryName);
                                                console.log(userCategories);
                                                setFormData({ ...formData, ['userMedicinecommissions']: userCategories });
                                                // console.log(userCategories[0]);
                                                if (userCategories.length == 1) {
                                                  if (userCategories[0] == 'Ayurvedic') {
                                                    setDLOpen(false);
                                                    setFssaiOpen(false);
                                                  } else if (userCategories[0] == 'OTC') {
                                                    setDLOpen(false);
                                                    setFssaiOpen(true);
                                                  } else {
                                                    setDLOpen(true);
                                                    setFssaiOpen(true);
                                                  }
                                                } else if (userCategories.length) {
                                                  if (userCategories.length == 2) {
                                                    if ((userCategories[0] == 'Ayurvedic' && userCategories[1] == 'OTC') || (
                                                      userCategories[0] == 'OTC' && userCategories[1] == 'Ayurvedic'
                                                    )) {
                                                      setDLOpen(false);
                                                      setFssaiOpen(true);
                                                    } else {
                                                      setDLOpen(true);
                                                      setFssaiOpen(true);
                                                    }
                                                  } else if (userCategories.length > 2) {
                                                    setDLOpen(true);
                                                    setFssaiOpen(true);
                                                  }

                                                } else {
                                                  setDLOpen(false);
                                                  setFssaiOpen(false);
                                                }
                                              } else {
                                                for (let i = 0; i < userCategories.length; i++) {
                                                  // console.log(row.id,values.categories[i].id);
                                                  if (row.categoryName == userCategories[i]) {
                                                    userCategories.splice(i)
                                                  }

                                                }
                                                // const updateUserCat = formData.userMedicinecommissions.filter((rowLal) => rowLal !== (row.id));
                                                setUserCategories(userCategories);
                                                setFormData({ ...formData, ['userMedicinecommissions']: userCategories });
                                                console.log(userCategories);
                                                if (userCategories.length == 1) {
                                                  if (userCategories[0] == 'Ayurvedic') {
                                                    setDLOpen(false);
                                                    setFssaiOpen(false);
                                                  } else if (userCategories[0] == 'OTC') {
                                                    setDLOpen(false);
                                                    setFssaiOpen(true);
                                                  } else {
                                                    setDLOpen(true);
                                                    setFssaiOpen(true);
                                                  }
                                                } else if (userCategories.length) {
                                                  if (userCategories.length == 2) {
                                                    if ((userCategories[0] == 'Ayurvedic' && userCategories[1] == 'OTC') || (
                                                      userCategories[0] == 'OTC' && userCategories[1] == 'Ayurvedic'
                                                    )) {
                                                      setDLOpen(false);
                                                      setFssaiOpen(true);
                                                    } else {
                                                      setDLOpen(true);
                                                      setFssaiOpen(true);
                                                    }
                                                  } else if (userCategories.length > 2) {
                                                    setDLOpen(true);
                                                    setFssaiOpen(true);
                                                  }

                                                } else {
                                                  setDLOpen(false);
                                                  setFssaiOpen(false);
                                                }
                                              }
                                            }}
                                          />
                                        }
                                        label={row.categoryName}
                                      />

                                    </Grid>)

                                  }) : <></>}
                              </FormGroup>

                              {/* {(!formData.categories.length) && (errors.categories)
                                && (touched.categories)
                                && <div style={{ color: '#ff3d57' }}>{`${errors.categories}`}</div>} */}
                            </FormControl>
                          </Grid>
                          {DLOpen && (
                            <Grid container style={{ padding: '0px 20px' }} spacing={2}>
                              <Grid item sm={6} xs={12}>
                                <label htmlFor="dl_no_20b" className='form-label'>DL NO 1</label>
                                <TextField
                                  fullWidth
                                  size="small"
                                  type="text"
                                  name="dl_no_20b"
                                  // label="Name"
                                  variant="outlined"
                                  onBlur={handleBlur}
                                  value={values.dl_no_20b}
                                  onChange={handleChange}
                                  helperText={touched.dl_no_20b && errors.dl_no_20b}
                                  error={Boolean(errors.dl_no_20b && touched.dl_no_20b)}
                                  sx={{ mb: 3 }}
                                />
                              </Grid>
                              <Grid item sm={6} xs={12}>
                                <label htmlFor="dl_no_21b" className='form-label'>DL NO 2</label>
                                <TextField
                                  fullWidth
                                  size="small"
                                  type="text"
                                  name="dl_no_21b"
                                  // label="Name"
                                  variant="outlined"
                                  onBlur={handleBlur}
                                  value={values.dl_no_21b}
                                  onChange={handleChange}
                                  helperText={touched.dl_no_21b && errors.dl_no_21b}
                                  error={Boolean(errors.dl_no_21b && touched.dl_no_21b)}
                                  sx={{ mb: 3 }}
                                />
                              </Grid>
                            </Grid>
                          )}
                          {fssaiOpen && (<Grid container style={{ padding: '0px 20px' }} spacing={2}>
                            <Grid item sm={6} xs={12}>
                              <label htmlFor="fssai_no" className='form-label'>FSSAI No</label>
                              <TextField
                                fullWidth
                                size="small"
                                type="text"
                                name="fssai_no"
                                // label="Name"
                                variant="outlined"
                                onBlur={handleBlur}
                                value={values.fssai_no}
                                onChange={handleChange}
                                helperText={touched.fssai_no && errors.fssai_no}
                                error={Boolean(errors.fssai_no && touched.fssai_no)}
                                sx={{ mb: 3 }}
                              />
                            </Grid>
                          </Grid>
                          )}
                          <Grid container style={{ padding: '0px 20px' }} spacing={2}>
                            <Grid item sm={6} xs={12} lg={12}>
                              <label htmlFor="lastName" className='form-label'>Register As</label>
                              <RadioGroup
                                row
                                aria-labelledby="demo-radio-buttons-group-label"
                                // defaultValue="M"
                                name="registerType"
                                sx={{ mb: 2 }}
                                value={values.registerType}
                                onChange={handleChange}
                              >
                                <FormControlLabel value="Manufacturer" control={<Radio />} label="Manufacturer" />
                                <FormControlLabel value="Distributor" control={<Radio />} label="Distributor" />
                                <FormControlLabel value="Wholesaler" control={<Radio />} label="Wholesaler" />
                                <FormControlLabel value="Retailer" control={<Radio />} label="Retailer" />
                                <FormControlLabel value="Hospital" control={<Radio />} label="Hospital" />
                              </RadioGroup>
                            </Grid>
                            {(values.registerType == 'Manufacturer' || values.registerType == 'Distributor' || values.registerType == 'Wholesaler') ? (
                              <>
                                <Grid item sm={12} xs={12} lg={12} style={{ paddingTop: "10px" }}>
                                  <h3 style={{ color: theme.palette.primary.main, marginLeft: 0, fontSize: '17px' }}>Bank Details</h3>
                                </Grid>
                                <Grid item sm={6} xs={12}>
                                  <label htmlFor="account_name" className='form-label'>Account Name</label>
                                  <TextField
                                    fullWidth
                                    size="small"
                                    type="text"
                                    name="account_name"
                                    // label="Name"
                                    variant="outlined"
                                    onBlur={handleBlur}
                                    value={values.account_name}
                                    onChange={handleChange}
                                    helperText={touched.account_name && errors.account_name}
                                    error={Boolean(errors.account_name && touched.account_name)}
                                    sx={{ mb: 3 }}
                                  />
                                </Grid>
                                <Grid item sm={6} xs={12}>
                                  <label htmlFor="account_no" className='form-label'>Account No</label>
                                  <TextField
                                    fullWidth
                                    size="small"
                                    type="text"
                                    name="account_no"
                                    // label="Name"
                                    variant="outlined"
                                    onBlur={handleBlur}
                                    value={values.account_no}
                                    onChange={handleChange}
                                    helperText={touched.account_no && errors.account_no}
                                    error={Boolean(errors.account_no && touched.account_no)}
                                    sx={{ mb: 3 }}
                                  />
                                </Grid>
                                <Grid item sm={6} xs={12}>
                                  <label htmlFor="ifsc_code" className='form-label'>IFSC Code</label>
                                  <TextField
                                    fullWidth
                                    size="small"
                                    type="text"
                                    name="ifsc_code"
                                    // label="Name"
                                    variant="outlined"
                                    onBlur={handleBlur}
                                    value={values.ifsc_code}
                                    onChange={handleChange}
                                    helperText={touched.ifsc_code && errors.ifsc_code}
                                    error={Boolean(errors.ifsc_code && touched.ifsc_code)}
                                    sx={{ mb: 3 }}
                                  />
                                </Grid>
                                <Grid item sm={6} xs={12}>
                                  <label htmlFor="branch" className='form-label'>Branch</label>
                                  <TextField
                                    fullWidth
                                    size="small"
                                    type="text"
                                    name="branch"
                                    // label="Name"
                                    variant="outlined"
                                    onBlur={handleBlur}
                                    value={values.branch}
                                    onChange={handleChange}
                                    helperText={touched.branch && errors.branch}
                                    error={Boolean(errors.branch && touched.branch)}
                                    sx={{ mb: 3 }}
                                  />
                                </Grid>
                                <Grid item sm={6} xs={12}>
                                  <label htmlFor="uploadSign" className='form-label'>Upload Signature</label>
                                  {values.uploadSign && (
                                    <>
                                      <br />
                                      <img src={values.uploadSign} style={{ width: '100px' }} alt="Signature" />
                                      <br />
                                    </>
                                  )}
                                  <FormControl fullWidth={true} sx={{ mb: 3 }}>
                                    <input
                                      id="uploadSign"
                                      name="uploadSign"
                                      type="file"
                                      onChange={changeHandler}
                                    />
                                  </FormControl>
                                  {(errors.uploadSign)
                                    && (touched.uploadSign)
                                    && <div style={{ color: '#ff3d57' }}>{`${errors.uploadSign}`}</div>}
                                </Grid>
                              </>
                            )
                              : <></>}
                          </Grid>
                          <Grid container style={{ padding: '30px 20px 0px' }} spacing={4}>
                            <Grid item sm={6} xs={12}>
                              <label htmlFor="plan" className='form-label'>Plan</label>
                              <FormControl fullWidth
                                sx={{ mb: 3 }}
                                size="small"
                              >
                                <Select
                                  labelId="status-label"
                                  id="plan"
                                  name="plan"
                                  value={values.plan || ''}
                                  onChange={handleChange}
                                  variant="outlined"
                                  onBlur={handleBlur}
                                  error={Boolean(errors.plan && touched.plan)}
                                >
                                  <MenuItem value="" key="sg">Select Plan</MenuItem>
                                  {plans && plans.map((row) =>
                                    <MenuItem value={row.plan} key={row.id}>{row.plan}</MenuItem>
                                  )}
                                </Select>
                              </FormControl>
                            </Grid>
                            <Grid item sm={6} xs={12}>
                              <LoadingButton
                                fullWidth
                                type="submit"
                                color="primary"
                                loading={loading}
                                variant="contained"
                                sx={{ mb: 2, mt: 3 }}
                              >
                                Update
                              </LoadingButton>
                            </Grid>
                          </Grid>
                          <br />
                        </form>
                      )}
                    </Formik>

                    {/* {user.userCategories ? user.userCategories
                      .map((row, index) => (
                        <Grid container spacing={2} key={'1_gc_' + (1 + index)}>
                          <Grid item sm={6} xs={12} lg={3} key={'1_g_' + (1 + index)}>
                            <Item key={'1_gi_' + (1 + index)}>Category Name</Item>
                            <ItemContent key={'1_gic_' + (1 + index)}>
                              {user.userCategories[index].medicine_category}
                            </ItemContent>
                          </Grid>
                          <Grid item sm={6} xs={12} lg={3} key={'2_g_' + (1 + index)}>
                            <Item key={'2_gi_' + (1 + index)}>Commission %</Item>
                            <ItemContent key={'2_gic_' + (1 + index)}>
                              {user.userCategories[index].commission}
                            </ItemContent>
                          </Grid>
                          <Grid item sm={6} xs={12} lg={3} key={'3_g_' + (1 + index)}>
                            <Item key={'3_gi_' + (1 + index)}>Default Commission %</Item>
                            <ItemContent key={'3_gic_' + (1 + index)}>
                              {user.userCategories[index].default_commission} %
                            </ItemContent>
                          </Grid>
                        </Grid>
                      )) : <></>} */}

                  </>
                )}
              </Box>
            </CardContent>
          </ContentBox>
        </Card>

      </Container>
    </>

  );
};

export default UpdateProfile;
