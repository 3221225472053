import React, { useEffect, useState, } from 'react';
import { useNavigate } from 'react-router-dom';
import {
    CardContent,
    Box,
    Dialog,
    DialogTitle, DialogContent, DialogActions,
    IconButton,
    Button, Grid, Alert, AlertTitle
} from "@mui/material";
import CloseIcon from '@mui/icons-material/Close';
import { useCart } from 'app/contexts/CartContext';
import CheckCircleIcon from '@mui/icons-material/CheckCircle';
import Moment from 'moment';
import happy from './happySmiley.jpeg'

function BootstrapDialogTitle(DialogTitleProps) {
    const { children, onClose, ...other } = DialogTitleProps;
    return (
        <DialogTitle sx={{ m: 0, p: 2 }} {...other}>
            {children}
            {onClose ? (
                <IconButton
                    aria-label="close"
                    onClick={onClose}
                    sx={{
                        position: 'absolute',
                        right: 8,
                        top: 8,
                        color: (theme) => theme.palette.grey[500],
                    }}
                >
                    <CloseIcon />
                </IconButton>
            ) : null}
        </DialogTitle>
    );
}

const CodPaymentProcess = ({ openCod, setOpenCod, orderId, payableAmount }) => {
    const [loading, setLoading] = useState();
    const { order, recent_order_id, getOrder } = useCart();
    const navigate = useNavigate();
    const handleClose = () => {
        setOpenCod(false);
    };
    const [notification_popup, set_notification_popup] = useState(false);
    const handlePaymentProcessContinue = () => {
        setOpenCod(false);
        navigate('/dashboard/order-history/purchase/record')
        // set_notification_popup(true)
        // setTimeout(() => {
        //     set_notification_popup(false);
        // }, 2000);
    };

    useEffect(() => {
        console.log(order)

    }, [])

    const popup_notification =
        <div
            style={{
                position: 'absolute',
                top: '50%',
                left: '50%',
                transform: 'translate(-50%, -50%)',
                display: 'flex',
                flexDirection: 'column',
                alignItems: 'center',
                borderRadius: '48px',
                width: '450.353px',
                height: '343.478px',
                flexShrink: 0,
                border: '10px solid #015998',
                background: '#FFF',
                boxShadow: '0px -3px 34px 2px rgba(1, 89, 152, 0.25) inset',
                padding: '18px',
                justifyContent: 'space-between',
                // WebkitTransitionProperty: '-webkit-transform',
                // WebkitTransitionDuration: '1s',
                // MozTransitionProperty: '-moz-transform',
                // MozTransitionDuration: '1s',
                // WebkitAnimationName: 'grow',
                // WebkitAnimationDuration: '2s',
                // WebkitAnimationIterationCount: 'infinite',
                // WebkitAnimationTimingFunction: 'linear',
                // MozAnimationName: 'grow',
                // MozAnimationDuration: '2s',
                // MozAnimationIterationCount: 'infinite',
                // MozAnimationTimingFunction: 'linear',
                // animationName: 'growKeyframes', 
                // animationDuration: '2s', 
                // animationIterationCount: 'infinite', 
                // animationTimingFunction: 'linear', 
            }}
        >
            {/* <style>
                {`
      @keyframes growKeyframes { // Renamed keyframes name
        from {
            width: 200px;
            height:262px ;
          }
          to {
            width: 450.353px; 
            height:343.478px ;
          }
      }
      `}
            </style> */}
            <img src={happy} />
            <div
                style={{
                    display: 'flex',
                    flexDirection: 'column',
                    alignItems: 'center',
                    justifyContent: 'end'
                }}
            >
                <h1 style={{ marginBottom: '0px' }}>Done!</h1>
                <h5>Order Placed Successfully.</h5>
            </div>
        </div>



    return (
        <>
            <Dialog
                maxWidth="md"
                open={openCod}
                onClose={handleClose}
            >
                <BootstrapDialogTitle id="customized-dialog-title" onClose={handleClose}>
                    Payment Details
                </BootstrapDialogTitle>
                <DialogContent dividers style={{ overflowY: 'auto', }}>
                    <CardContent>
                        <Box width="100%" overflow="auto" style={{ border: '2px solid #015998' }}>
                            <Alert severity="success" icon={false} sx={{ width: "100%", display: "block", overflow: 'hidden' }}>
                                <AlertTitle color="success" sx={{ fontSize: "20px" }}>
                                    Dear Customer,
                                </AlertTitle>
                                <p style={{ lineHeight: '15px' }}><strong>After delivery,One of our field executive will visit for collecting the payment.</strong></p>
                                <p style={{ lineHeight: '15px' }}>Your Order ID : <strong>{order.orderId ? order.orderId : orderId}</strong></p>
                                <p style={{ lineHeight: '15px', margin: '0 0 10px 0 ' }}>Your Amount Payable : <strong>{process.env.REACT_APP_CURRENCY}. {order.totalPayable ? order.totalPayable : payableAmount}</strong></p>
                            </Alert>
                        </Box>
                    </CardContent>
                </DialogContent>
                <DialogActions>
                    <Button onClick={handlePaymentProcessContinue} type='submit'
                        variant="contained" color="success" size='medium'
                        sx={{ fontSize: '15px', fontWeight: "700" }}>
                        Done
                    </Button>
                </DialogActions>
            </Dialog>
            {notification_popup && popup_notification}
        </>
    );
};
export default CodPaymentProcess;