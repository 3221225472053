import Loadable from 'app/components/Loadable';
import { lazy } from 'react';
import { authRoles } from '../../../../auth/authRoles';


const AddMedicinesStock = Loadable(lazy(() => import('./AddMedicinesStock')));
const EditMedicinesStock = Loadable(lazy(() => import('./EditMedicinesStock')));
const BulkStockEntry = Loadable(lazy(() => import('./BulkStockEntry')));
const MedicinesList = Loadable(lazy(() => import('./MedicinesList')));
const ViewMedicinesStock = Loadable(lazy(() => import('./ViewMedicinesStock')));
const NearExpiry = Loadable(lazy(() => import('./NearExpiry')))

const MedicineRoutes = [
  { path: '/dashboard/medicine/add-stock', element: <AddMedicinesStock />, auth: authRoles.admin },
  { path: '/dashboard/medicine/edit-stock/:row_id', element: <EditMedicinesStock />, auth: authRoles.admin },
  { path: '/dashboard/medicine/bulk-stock-entry', element: <BulkStockEntry />, auth: authRoles.admin },
  { path: '/dashboard/medicine/list', element: <MedicinesList />, auth: authRoles.admin },
  { path: '/dashboard/medicine/view/:row_id', element: <ViewMedicinesStock />, auth: authRoles.admin },
  { path: '/dashboard/medicine/expiryList', element: <NearExpiry />, auth: authRoles.admin },

];

export default MedicineRoutes;
