import React, { useState, useEffect, useRef } from 'react';
import { useNavigate } from 'react-router-dom';

import { Formik } from 'formik';
import {
  Button, Grid
} from '@mui/material';
import { makeStyles } from '@material-ui/core/styles';
import axios from 'axios';
import * as Yup from 'yup';
import PaymentDetails from './PaymentDetails';
import CartProducts from './CartProducts';
import './address.css'
import sbicollpm from './sbiCollectpm.png';
import codpm from './codpm.png'
import otherMethod from './otherPaymentMethods.jpeg'
import { useCart } from 'app/contexts/CartContext';
import swal from 'sweetalert';

import PaymentProcess from './PaymentProcess';
import CodPaymentProcess from './CodPaymentProcess';
import DownloadHeganApp from './DownloadHeganApp';

const useStyles = makeStyles({
  payment_table_heads: {
    fontFamily: 'Poppins, sans-serif',
    fontWeight: 'bold',
  },
});

const validationSchema = Yup.object().shape({
});
const Address = (props) => {
  const classes = useStyles();
  const navigate = useNavigate();
  const [profile, setProfile] = useState(false);
  const [loading, setLoading] = useState(false);
  const { order, recent_order_id, getOrder, orderPlaced, cod_response, getCodAvailability, } = useCart();

  useEffect(() => {
    fetchData();
    // console.log(cart);
  }, []);

  /**
  * Fetching data from server.
  */
  const fetchData = async () => {
    setLoading(true);
    try {
      // await axios.get('/sanctum/csrf-cookie').then(response => {
      const userData = JSON.parse(localStorage.getItem('user'));
      // console.log(userData);

      axios.get(`api/user/getUserByid/${userData.id}`)
        .then(response => {
          // console.log(response);
          if (response.status === 200) {
            setProfile(response.data.data);
          } else if (response.status === 401) {

          } else {

          }
          setLoading(false);
        })
        .catch(function (error) {
          setLoading(false);
          console.log(error);
        });
      // });
    } catch (e) {
      setLoading(false);
    }
  }
  const handleFormSubmit = (values, errors) => {
    props.next(values);
  }

  const [isOpen, setIsOpen] = useState(false);
  const [isOpenCod, setIsCodOpen] = useState(false);
  const infoIconRef = useRef(null);


  const [open, setOpen] = useState(false);
  const handleClickOpen = () => {

    swal({
      title: "Are you sure?",
      text: "Payment method once selected cannot be changed!",
      icon: "warning",
      buttons: [
        'No, cancel it!',
        'Yes, I am sure!'
      ],
      dangerMode: true,
    }).then(async function (isConfirm) {
      if (isConfirm) {
        // alert('done')
        await orderPlaced("SBI_COLLECT");
        setOpen(true)
      }
    });

    // if (props.currentStep == 1) {
    //   //   await orderPlaced();
    //   setOpen(true)

    // }
    // const recOrdId = localStorage.getItem('recentOrderId')
    // getOrder(recOrdId)
  }

  const [openCod, setOpenCod] = useState(false);
  const handleCodOpen = async () => {

    // if (props.currentStep == 1) {
    swal({
      title: "Are you sure?",
      text: "Payment method once selected cannot be changed!",
      icon: "warning",
      buttons: [
        'No, cancel it!',
        'Yes, I am sure!'
      ],
      dangerMode: true,
    }).then(async function (isConfirm) {
      if (isConfirm) {
        // alert('done')
        await orderPlaced("COD");

        setOpenCod(true)
      }
    });


    // }

  }

  const [openD, setOpenD] = useState(false);
  const handleDhOpen = () => {
    setOpenD(true)
  }


  const openPopup = () => {
    setIsOpen(true);
  };
  const openPopupCod = () => {
    // console.log('hi');
    setIsCodOpen(true);
  };

  const closePopup = (e) => {

    // console.log(infoIconRef.current);
    // console.log(e.target.classList);
    if (infoIconRef.current) {
      if (

        !infoIconRef.current.contains(e.target) &&
        !e.target.classList.contains('circleee')
      ) {
        setIsOpen(false);
        setIsCodOpen(false);

      }
    }

  };
  const callCodContext = async () => {
    await getCodAvailability();
  }

  useEffect(() => {
    document.addEventListener('click', closePopup);
    callCodContext();

    return () => {
      document.removeEventListener('click', closePopup);
    };
  }, []);

  return (
    <>
      {/* <Formik
        onSubmit={(values, errors) => {
          handleFormSubmit(values, errors);
        }
        }
        initialValues={props.formData}
        validationSchema={validationSchema}
      >
        {({ values, errors, touched, handleChange, handleBlur, handleSubmit, setFieldValue }) => ( */}
      {/* // <form onSubmit={handleSubmit}> */}
      <Grid container item rowSpacing={3} columnSpacing={3} style={{ display: 'flex', }}>

        <Grid item sm={12} xs={12} lg={8}>

          <CartProducts {...props} />

          <div className='order_summary1 os-left  marg-btm30' style={{ margin: '9px 0 0 0' }}>
            <div>

              <h4 style={{ backgroundColor: '#015998', color: 'white', padding: '5px', borderRadius: '7px', border: '1px solid black', }}>   <span >Shipping Details</span></h4>
              <div className='row' style={{ paddingTop: "13px" }}>
                <div className='col-sm-12 col-lg-10'>
                  <h5>{profile.firstName} {profile.lastName}</h5>
                  <p className={classes.payment_table_heads}>Firm name: {profile.firmName}</p>
                  <p className={classes.payment_table_heads}>Street: {profile.address}</p>
                  <p className={classes.payment_table_heads}>State/province/area: {profile.state}</p>
                  <p className={classes.payment_table_heads}>Pin code {profile.pinCode}</p>
                  <p className={classes.payment_table_heads}>{profile.mobile}</p>
                </div>

              </div>
            </div>


          </div>

        </Grid>
        <Grid item sm={12} xs={12} lg={4}>

          <PaymentDetails {...props} />

          <div className='order_summary1 os-left  marg-btm30' style={{ margin: '9px 0 0 0' }}>
            <div>

              <h4 style={{ backgroundColor: '#015998', color: 'white', padding: '5px', borderRadius: '7px', border: '1px solid black', }}>   <span >Select a payment method</span></h4>
              <div style={{ paddingTop: "13px", display: 'flex', backgroundColor: '#E2EEF4', border: '2px solid #015998', padding: '12px', borderRadius: '8px', }}>

                <div >
                  <div style={{ display: 'flex', alignItems: 'center' }}>
                    <input
                      type="checkbox"
                      id={2}
                      name="imageSelection"
                      value={1}
                 
                      onChange={(event) => {
                        if (event.target.checked) {
                          handleCodOpen();
                        }
                      }}
                      disabled={!cod_response.CODStatus}
                    />
                    <label htmlFor={1} style={{ margin: '0 3px 0 3px', }}>
                      <p >Cash On Delivery</p>
                    </label>
                    <span
                      className='circleee'
                      style={{ cursor: 'pointer', }}
                      onClick={openPopupCod}
                      ref={infoIconRef}>&#9432;</span>
                    {isOpenCod && (
                      <div
                        style={{
                          position: 'absolute',
                          backgroundColor: 'white',
                          color: 'black',
                          padding: '3px',
                          maxHeight: '70px',
                          border: '1px solid black',
                          borderRadius: '8px',
                          overflow: 'auto',
                          zIndex: '1',
                        }}
                      >
                        After delivery of your consignment by logistics partner, One of Hegan's Feild Sales Executive will reach out to you for collecting cash of your order.
                      </div>
                    )}

                  </div>


                  <div style={{ display: 'flex', alignItems: 'center', position: 'relative', }} >
                    <input
                      type="checkbox"
                      id={1}
                      name="imageSelection"
                      value={1}
                     
                      onChange={(event) => {
                        if (event.target.checked) {
                          handleClickOpen();
                        }
                      }}
                    />
                    <label htmlFor={1} style={{ margin: '0 3px 0 3px', }}>
                      <p>SBI Collect</p>
                    </label>
                    <span
                      className="circleee"
                      style={{ cursor: 'pointer', }}
                      onClick={openPopup}
                      ref={infoIconRef}
                    >
                      &#9432;
                    </span>
                    {isOpen && (
                      <div
                        style={{
                          position: 'absolute',
                          backgroundColor: 'white',
                          color: 'black',
                          padding: '3px',
                          maxHeight: '70px',
                          border: '1px solid black',
                          borderRadius: '8px',
                          overflow: 'auto',
                        }}
                      >
                        This method saves your 2% extra payment gateway charges.
                      </div>
                    )}

                  </div>

                  <div style={{ display: 'flex', alignItems: 'center' }}>
                    <input
                      type="checkbox"
                      id={2}
                      name="imageSelection"
                      value={1}
                  
                      onChange={(event) => {
                        if (event.target.checked) {
                          handleDhOpen();
                        }
                      }}

                    />
                    <label htmlFor={1} style={{ margin: '0 3px 0 3px', }}>
                      <p>
                        Buy Now Pay Later / UPI Gateway</p>
                    </label>

                  </div>
                </div>
              </div>
              {cod_response.CODStatus == false && <p style={{ color: 'red' }}>{cod_response.CODMsg}</p>}
            </div>


          </div>

          <PaymentProcess open={open} setOpen={setOpen} />
          <CodPaymentProcess openCod={openCod} setOpenCod={setOpenCod} />
         
        </Grid>





      </Grid>
      {/* <Grid container style={{ padding: '10px 20px 0px' }} spacing={4}>

              <Grid item sm={4} xs={12} lg={2}>
                <Button
                  fullWidth
                  variant="contained"
                  type='submit'
                  color='success'
                  sx={{ mb: 2, mt: 1 }}
                >
                  Pay Now
                </Button>
              </Grid>
              <Grid item sm={6} xs={12} lg={5}>
              </Grid>
            </Grid> */}
      {/* </form> */}
      {/* )}
      </Formik> */}
       <DownloadHeganApp openD={openD} setOpenD={setOpenD} />
    </>

  );
}

export default Address;
