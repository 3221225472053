import React, { useEffect, useState } from 'react';
import { Link, useParams, NavLink, useSearchParams, useLocation } from 'react-router-dom';
import { Grid, RadioGroup, FormControlLabel, Radio, Alert, Backdrop, CircularProgress, Button } from '@mui/material';
import { styled } from '@mui/material/styles';
import Paper from '@mui/material/Paper';
import FilterListIcon from '@mui/icons-material/FilterList';
import CloseIcon from '@mui/icons-material/Close';

import Header from '../Header/Header';
import Footer from '../Footer/Footer';
import './category.css'
import useAuth from 'app/hooks/useAuth';
import axios from 'axios';
import swal from 'sweetalert';
import Moment from 'moment';
import Pagination from "react-js-pagination";
import { Formik, FieldArray, ErrorMessage } from 'formik';

import "./../frontend.css";
import heganlogo from '../HomePage/heganlogo.png'


const Item = styled(Paper)(({ theme }) => ({
  backgroundColor: theme.palette.mode === 'dark' ? '#1A2027' : '#fff',
  ...theme.typography.body2,
  padding: theme.spacing(1),
  textAlign: 'center',
  color: theme.palette.text.secondary,
}));



function SpecialOffersViewAll() {
  const { category_id } = useParams();
  const location = useLocation();
  let { logout, user, isAuthenticated } = useAuth();
  const [searchProducts, setSearchProducts] = useState(false);
  const [categories, setCategories] = useState(false);
  const [brands, setBrands] = useState([]);
  const [searching, setSearching] = useState(false);
  const [searchTest, setSearchTest] = useState(false);
  const [loading, setLoading] = useState(false);
  const [searchParams, setSearchParams] = useSearchParams();

  const initialValues = {
    q: (searchParams.get("q")) ? searchParams.get("q") : '',
    selected_categories: (category_id) ? category_id : '',
    selected_brands: '',
  };

  const [formData, setFormData] = useState(initialValues);

  // filter on mobile screen logic

  const [showFilterButton, setShowFilterButton] = useState(false);
  const [showFilters, setShowFilters] = useState(false)

  useEffect(() => {
    const handleResize = () => {
      setShowFilterButton(window.innerWidth < 580);
    };

    // Set initial state based on window width
    handleResize();

    // Add event listener for window resize
    window.addEventListener('resize', handleResize);

    // Clean up the event listener on component unmount
    return () => {
      window.removeEventListener('resize', handleResize);
    };
  }, []);

  const handleFilterDisplay = () => {
    setShowFilters(!showFilters);
  }



  useEffect(() => {
    if (!categories) {
      fetchData();
      fetchSearchProduct();
    } else {
      fetchSearchProduct();
    }
  }, [formData, searchTest, location]);
  /**
   * Fetching data from server.
   */
  const fetchData = async () => {
    setLoading(true);
    try {
      // await axios.get('/sanctum/csrf-cookie').then(response => {
      axios.get('api/admin/medicineCategory/getList')
        .then(response => {
          // console.log(response);
          if (response.data.status === 200) {
            setCategories(response.data.data);
          } else if (response.data.response_code === 401) {

          } else {

          }
          setLoading(false);
        })
        .catch(function (error) {
          setLoading(false);
          console.log(error);
        });
      axios.get('api/admin/brand/getAll')
        .then(response => {
          // console.log(response);
          if (response.data.status === 200) {
            setBrands(response.data.data);
            // console.log(88, brands);
          } else if (response.data.response_code === 401) {

          } else {

          }
          setLoading(false);
        })
        .catch(function (error) {
          setLoading(false);
          console.log(error);
        });
      // });
    } catch (e) {
      setLoading(false);
    }
  }
  const fetchSearchProduct = async (pageNumber = 1) => {
    setLoading(true);
    setSearching(true);
    try {
      // await axios.get('/sanctum/csrf-cookie').then(response => {

      var search_api_url = 'api/user/product/productMargin';
      if (isAuthenticated) {
        search_api_url = 'api/user/product/productMargin';
      }
      axios.post(search_api_url, {
        "page": pageNumber,
        "q": (searchTest) ? searchTest : formData.q ? formData.q : '',
        "category": formData.selected_categories ? formData.selected_categories : '',
        "brand": formData.selected_brands ? formData.selected_brands : ''

      })
        .then(response => {
          console.log(response.data);
          if (response.data.status === 200) {
            setSearchProducts(response.data);
          } else if (response.data.status === 401) {

          } else {

          }
          setLoading(false);
          setSearching(false);
        })
        .catch(function (error) {
          setLoading(false);
          setSearching(false);
          console.log(error);
        });
      // });
    } catch (e) {
      // setLoading(false);
      setSearching(false);
    }
  }



  const handleFormSubmit = (values, errors) => {
    try {
      fetchSearchProduct();
      // axios.get('/sanctum/csrf-cookie').then(response => {
      //   const uploadFormData = new FormData();
      //   for (let iKey in values) {
      //     if (iKey === 'selected_categories') {
      //       uploadFormData.append(iKey, formData[iKey])
      //     } else {
      //       uploadFormData.append(iKey, values[iKey])
      //     }

      //   }
      //   const config = { headers: { "Content-Type": "multipart/form-data" } };
      //   axios.post('/api/client/register', uploadFormData, config)
      //     .then(response => {
      //       if (response.data.response_code === 200) {
      //         swal({
      //           title: "Good job!",
      //           text: response.data.message,
      //           icon: "success",
      //         });
      //         navigate('/');
      //       } else {
      //         swal({
      //           title: "Warning!",
      //           text: response.data.message,
      //           icon: "warning",
      //         });
      //         errors.setErrors(response.data.errors);
      //       }
      //       setLoading(false);
      //     })
      //     .catch(function (error) {
      //       console.error(error);
      //     });
      // });
    } catch (e) {
      console.log(e);
      setLoading(false);
    }
  };
  const handleFilterChange = (e) => {
    // console.log(e.target.name + '-' + e.target.value);
    setFormData({ ...formData, [e.target.name]: e.target.value });
    // console.log(formData);
  };

  return (
    <>
      <Header searchTest={searchTest} setSearchTest={setSearchTest} />
      <div className="breadcrumb_section  page-title-mini" style={{ padding: '30px 0px' }}>
        <div className="container">
          <div className="row">
            <div className="col-md-6">
              <ol className="breadcrumb ">
                <li className="breadcrumb-item"><Link to="/">Home</Link></li>
                <li className="breadcrumb-item"><Link to="/categories?q=">All Categories</Link></li>
                {/* <li className="breadcrumb-item active">Himalaya Brand Store</li> */}
              </ol>
            </div>
          </div>
        </div>
      </div>
      <Backdrop
        sx={{ color: '#015998', zIndex: (theme) => theme.zIndex.drawer + 1 }}
        open={loading}
        onClick={setLoading}
      >
        <CircularProgress color="inherit" />
      </Backdrop>
      <div className="section marg-btm30">
        <div className="container">
          <div className="row">
            {showFilterButton &&
              <div className='category-filter-div' >
                <Button variant="contained" size="medium" endIcon={showFilters ? <CloseIcon /> : <FilterListIcon />} onClick={handleFilterDisplay}>Filter Products</Button>
              </div>}


            {(!showFilterButton || showFilters) && <div className="col-sm-3">
              <Formik
                onSubmit={(values, errors) => {
                  handleFormSubmit(values, errors);
                }
                }
                initialValues={initialValues}
              >
                {({ values, errors, touched, handleChange, handleBlur, handleSubmit, setFieldValue }) => (
                  <form onSubmit={handleSubmit}>
                    <div className="sidebar">
                      <div className="widget">
                        <h5 className="widget_title">category
                          {values.selected_categories && (
                            <Button onClick={e => {
                              setFieldValue('selected_categories', '');
                              setFormData({ ...formData, ['selected_categories']: '' });
                            }} className='text-right right' sx={{ float: "right" }}>Clear
                            </Button>
                          )}
                        </h5>
                        <ul className="list_brand lstscroll" style={{ height: "250px", overflow: 'auto' }}>
                          {categories && (
                            <RadioGroup
                              row
                              name="selected_categories"
                              value={values.selected_categories}
                              onChange={e => {
                                setFieldValue('selected_categories', e.target.value);
                                setFormData({ ...formData, [e.target.name]: e.target.value });
                              }
                              }
                            >
                              {
                                categories.map((row, index) => (
                                  <li key={'medi-cat-flt-' + index} style={{ width: "100%" }}>
                                    <div className="custome-radio customerad">
                                      <FormControlLabel value={row.categoryName} control={<Radio />} label={row.categoryName} sx={{ height: "25px" }} />
                                    </div>
                                  </li>
                                ))
                              }
                            </RadioGroup>
                          )}
                        </ul>
                      </div>
                      <div className="widget">
                        <h5 className="widget_title">Brand
                          {values.selected_brands && (
                            <Button onClick={e => {
                              setFieldValue('selected_brands', '');
                              setFormData({ ...formData, ['selected_brands']: '' });
                            }} className='text-right right' sx={{ float: "right" }}>Clear
                            </Button>
                          )}
                        </h5>
                        <ul className="list_brand lstscroll" style={{ height: "250px", overflow: 'auto' }}>
                          {brands && (
                            <RadioGroup
                              row
                              name="selected_brands"
                              value={values.selected_brands}
                              onChange={e => {
                                setFieldValue('selected_brands', e.target.value);
                                setFormData({ ...formData, [e.target.name]: e.target.value });
                              }
                              }
                            >
                              {
                                brands.map((row, index) => (
                                  <li key={'medi-brand-flt-' + index} style={{ width: "100%" }}>
                                    <div className="custome-radio customerad">
                                      <FormControlLabel value={row.brand} control={<Radio />} label={row.brand} sx={{ height: "25px" }} />
                                    </div>
                                  </li>
                                ))
                              }
                            </RadioGroup>
                          )}
                        </ul>
                      </div>
                    </div>
                  </form>
                )}
              </Formik>
            </div>}
            <div className="col-sm-9">
              <div className="row prodstyel product-lists" style={{ position: "relative" }}>
                {searchProducts && searchProducts.data.length ? (
                  searchProducts.data.map((row, index) => (
                    <div key={'hot-seller-pro-' + index} className="col-sm-12 col-md-4">
                      <div className="product">
                        <div className="product_wrap">
                          <div className="sale_banner">
                            <Link to={`/product/details/${row.id}`} className="hover_effect1" title={row.productName}>
                              <img src={row.uploadImg ? `${row.uploadImg}` : heganlogo} />
                            </Link>
                            <div className='hint-pre'>
                              <img style={{ right: "-5px" }} src={`${process.env.PUBLIC_URL}/assets/images/frontend/hint.png`} alt="Discount" />
                              <span >{(row.marginPercentage) ? row.marginPercentage : '0'}%</span>
                            </div>
                          </div>
                          <div className="product_cont">
                            <h5><Link to={`/product/details/${row.id}`} title={row.productName}>{row.productName}</Link></h5>
                            <div className="product_price">
                              {/* <span className="descr">Sold By Seller: {row.user.userName}</span> */}
                              {(row.expiryDate === '00/00/0000' || row.expiryDate === 'NA') ? (<span className='col-sm-12 col-md-6'>Expiry Date: NA</span>) : (<span className='col-sm-12 col-md-6'>Expiry Date: {Moment(row.expiryDate).format('DD MMM YYYY')}</span>
                              )}
                              <span className="pricesml">MRP   ({row.mrp})</span>
                              <span className="pricebig">{process.env.REACT_APP_CURRENCY} {row.netRate}</span>
                              <Link to={`/product/details/${row.id}`} title={row.productName} className="pricebigcart"
                              > View Product
                              </Link>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  ))

                ) : (
                  <Alert severity="info">No results match your search criteria!</Alert>
                )}
              </div>
              {searchProducts && (searchProducts.total > 0) && (
                <div className="row">
                  <div className="col-12">
                    <Pagination
                      innerClass="pagination mt-3 justify-content-center pagination_style1"
                      activePage={searchProducts.current_page}
                      itemsCountPerPage={searchProducts.per_page}
                      totalItemsCount={searchProducts.total}
                      pageRangeDisplayed={5}
                      onChange={(pageNumber) => fetchSearchProduct(pageNumber)}
                      itemClass={"page-item"}
                      linkClass={"page-link"}
                    />

                  </div>
                </div>
              )}
            </div>
          </div>
        </div>
      </div >

      <Footer />
    </>
  );
}
export default SpecialOffersViewAll;
