import { useState, useEffect } from 'react';
import { NavLink, useNavigate } from 'react-router-dom';
import { useTheme } from '@emotion/react';
import { LoadingButton } from '@mui/lab';
import {
  Card,
  Checkbox,
  Grid, Divider, Select, MenuItem, TextField, RadioGroup, FormControlLabel, Radio, FormControl,
  FormLabel, FormGroup,

} from '@mui/material';

import { Box, styled } from '@mui/system';
import { Paragraph } from 'app/components/Typography';
import useAuth from 'app/hooks/useAuth';
import axios from 'axios';
import { Formik, FieldArray, ErrorMessage } from 'formik';
import PhoneInput from 'react-phone-input-2'
import 'react-phone-input-2/lib/style.css'
import * as Yup from 'yup';
import swal from 'sweetalert';
import { AiOutlineClose } from "react-icons/ai";

import Header from './../Header/Header';
import Footer from './../Footer/Footer';
import AppLinks from './AppLinks';

import "./partner.css";
import "./termsAndConditions.css";


// import { values } from 'lodash';

const Item = styled("div")(({ theme }) => (
  {
    marginBottom: "5px",
    fontSize: '16px',
    fontWeight: '400',
    color: '#484747'
  }
));
const ItemContent = styled("div")(({ theme }) => (
  {
    marginBottom: "15px",
    fontSize: '16px',
    fontWeight: '600',
  }
));
const FlexBox = styled(Box)(() => ({ display: 'flex', alignItems: 'center' }));

const JustifyBox = styled(FlexBox)(() => ({ justifyContent: 'center' }));

const ContentBox = styled(JustifyBox)(() => ({
  height: '100%',
  padding: '32px',
  background: 'rgba(0, 0, 0, 0.01)',
}));

const JWTRegister = styled(JustifyBox)(() => ({
  background: 'linear-gradient(90deg, #9E8FEC 0%, #0CB7FF 50%, #6DFFAC 100%)',
  minHeight: '100vh !important',
  '& .card': {
    maxWidth: 950,
    minHeight: 500,
    margin: '2rem 1rem',
    display: 'flex',
    borderRadius: 2,
    alignItems: 'center',
  },
}));



// form field validation schema
// .matches(/(\+91\ )[6-9]{1}[0-9 ]{4}[0-9 ]{4}[0-9]{3}/, {
const validationSchema = Yup.object().shape({
  firstName: Yup.string()
    .required('First Name is required!'),
  lastName: Yup.string()
    .required('Last Name is required!'),
  firmName: Yup.string()
    .required('Firm Name is required!'),
  // gst_no: Yup.string()
  //   .min(15, 'GST number must be 15 digit length!')
  //   .max(15, 'GST number must be 15 digit length!')
  //   .matches(/^([0-9]{2}[a-zA-Z]{4}([a-zA-Z]{1}|[0-9]{1})[0-9]{4}[a-zA-Z]{1}([a-zA-Z]|[0-9]){3}){0,15}$/, { message: "Please enter valid GST number.", excludeEmptyString: false }),
  mobile: Yup.string()
    .required('Mobile number is required!')
    .min(10, 'Mobile number must be 10 digit length!')
    .max(10, 'Mobile number must be 10 digit length!')
    .matches(/^[6-9]\d{9}$/, { message: "Please enter valid mobile number!", excludeEmptyString: false }),
  // address: Yup.string()
  //   .required('Address is required!'),
  email: Yup.string().email('Invalid Email address').required('Email is required!'),
  city: Yup.string().required('City is required!'),
  address: Yup.string()
    .required('Address is required!'),
  // state: Yup.string()
  //   .required('State is required!'),
  pinCode: Yup.string()
    .min(6, "Pin Code must be 6 digit!")
    .max(6, "Pin Code must be 6 digit!")
    .required('Pin Code is required!'),

  // categories: Yup.array().test(
  //   'oneOfRequired',
  //   'Please select at least one category!',
  //   function (item) {
  //     console.log(this);
  //     return (this.parent.field1 || this.parent.field2 || this.parent.field3 || this.parent.field4)
  //   }
  // ),
  // registerType: Yup.string().required(`Select you'r Register type!`),

  // dl_no_20b: Yup.string().when('formData', (formData, dl_no_20b) => {
  //   console.log(formData);
  //   if (formData.length > 3) {
  //     return Yup.string()
  //       .required(dl_no_20b, 'dl_no_20b must be after Start Date')
  //       .typeError('End Date is required')
  //   }
  // }),

  // account_name: Yup.string().when('registerType', {
  //   is: (registerType) => registerType == 'M' || registerType == 'D' || registerType == 'W',
  //   then: Yup.string().required('Account Name is required!'),
  //   otherwise: Yup.string(),
  // }),
  // account_no: Yup.string().when('registerType', {
  //   is: (registerType) => registerType === 'M' || registerType === 'D' || registerType === 'W',
  //   then: Yup.string()
  //     .min(10, "Account Number must be 10 to 16 digit!")
  //     .max(16, "Account Number must be 10 to 16 digit!")
  //     .required('Account Number is required!'),
  //   otherwise: Yup.string(),
  // }),
  // ifsc_code: Yup.string().when('registerType', {
  //   is: (registerType) => registerType === 'M' || registerType === 'D' || registerType === 'W',
  //   then: Yup.string()
  //     .min(8, "IFSC Code must be 8 to 13 alphanumeric!")
  //     .max(13, "IFSC Code must be 8 to 13 alphanumeric!")
  //     .required('IFSC Code is required!'),
  //   otherwise: Yup.string(),
  // }),
  // branch: Yup.string().when('registerType', {
  //   is: (registerType) => registerType === 'M' || registerType === 'D' || registerType === 'W',
  //   then: Yup.string()
  //     .min(3, "Branch must be 3 charectors!")
  //     .required('Branch is required!'),
  //   otherwise: Yup.string(),
  // }),
  agree_terms: Yup.boolean()
    .required("The terms and conditions must be accepted!")
    .oneOf([true], "The terms and conditions must be accepted!")
});

const PartnerRegister = () => {
  const theme = useTheme();
  const { register } = useAuth();
  const navigate = useNavigate();
  const [states, setStates] = useState([
    ["Andhra Pradesh", "AP"],
    ["Arunachal Pradesh", "AR"],
    ["Assam", "AS"],
    ["Bihar", "BR"],
    ["Chhattisgarh", "CG"],
    ["Goa", "GA"],
    ["Gujarat", "GJ"],
    ["Haryana", "HR"],
    ["Himachal Pradesh", "HP"],
    ["Jammu and Kashmir", "JK"],
    ["Jharkhand", "JH"],
    ["Karnataka", "KA"],
    ["Kerala", "KL"],
    ["Madhya Pradesh", "MP"],
    ["Maharashtra", "MH"],
    ["Manipur", "MN"],
    ["Meghalaya", "ML"],
    ["Mizoram", "MZ"],
    ["Nagaland", "NL"],
    ["Orissa", "OR"],
    ["Punjab", "PB"],
    ["Rajasthan", "RJ"],
    ["Sikkim", "SK"],
    ["Uttarakhand", "UK"],
    ["Uttar Pradesh", "UP"],
    ["West Bengal", "WB"],
    ["Tamil Nadu", "TN"],
    ["Tripura", "TR"],
    ["Telangana", "TG"],
    ["Andaman and Nicobar Islands", "AN"],
    ["Chandigarh", "CH"],
    ["Dadra and Nagar Haveli", "DH"],
    ["Daman and Diu", "DD"],
    ["Delhi", "DL"],
    ["Lakshadweep", "LD"],
    ["Pondicherry", "PY"],
  ]);
  const [plans, setPlans] = useState(false);
  // const [categories, setCategory] = useState([{ 'id': '101', 'category': 'Allopathic' }, { 'id': '102', 'category': 'Generic' }, { 'id': '103', 'category': 'Surgical & Medical Equipment' }, { 'id': '104', 'category': 'OTC' }, { 'id': '105', 'category': 'Ayurvedic' }, { 'id': '106', 'category': 'Allopathic' }]);
  const [categories, setCategory] = useState(['Allopathic', 'Generic', 'Surgical & Medical Equipment', 'OTC', 'Ayurvedic', 'Veterinary']);
  // inital login credentials
  const initialValues = {
    registerType: '',
    plan: [],
    firstName: '',
    lastName: '',
    email: '',
    city: '',
    firmName: '',
    userName: '',
    mobile: '',
    // user_categories: [],
    categories: [],
    address: '',
    pinCode: '',
    dl_no_20b: '',
    dl_no_21b: '',
    gst_no: '',
    fssai_no: '',
    panCard: '',
    bankDoc: '',
    account_name: '',
    account_no: '',
    ifsc_code: '',
    branch: '',
    state: '',
    uploadSign: '',
    agree_terms: false
  };
  const [formData, setFormData] = useState(initialValues);
  const [loading, setLoading] = useState(false);
  const [DLOpen, setDLOpen] = useState(false);
  const [fssaiOpen, setFssaiOpen] = useState(false);
  const [userCategories, setUserCategories] = useState([]);

  // terms and conditions modal code //
  const [showTerms, setShowTerms] = useState(false);
  const [showRefundPolicies, setShowRefundPolicies] = useState(false);
  const [showPrivacyPolicies, setshowPrivacyPolicies] = useState(false);

  const openTermsModal = () => {
    setShowTerms(true);

  };

  const closeTermsModal = () => {
    setShowTerms(false);
  };

  const openRefundModal = () => {
    setShowRefundPolicies(true);

  };

  const closeRefundModal = () => {
    setShowRefundPolicies(false);
  };

  const openPrivacyModal = () => {
    setshowPrivacyPolicies(true);

  };

  const closePrivacyModal = () => {
    setshowPrivacyPolicies(false);
  };




  useEffect(() => {
    fetchData();
  }, []);

  /**
    * Fetching data from server.
    */
  const fetchData = async () => {


    // setLoading(true);
    try {
      // await axios.get('/sanctum/csrf-cookie').then(response => {
      //   axios.post('/api/client/get-states')
      //     .then(response => {
      //       if (response.data.response_code === 200) {
      //         setStates(response.data.data);
      //       } else if (response.data.response_code === 401) {

      //       }
      //       setLoading(false);
      //     })
      //     .catch(function (error) {
      //       setLoading(false);
      //       console.error(error);
      //     });
      // axios.post('/api/client/get-medicine-categories')
      //   .then(response => {
      //     if (response.data.response_code === 200) {
      //       setCategory(response.data.data);
      //     } else if (response.data.response_code === 401) {

      //     }
      //     setLoading(false);
      //   })
      //   .catch(function (error) {
      //     setLoading(false);
      //     console.error(error);
      //   });

      // axios.get('api/admin/plan/getAll')
      axios.get('api/admin/plan/getAll')
        .then(response => {
          // console.log(response);
          if (response.data.status === 200) {
            setPlans(response.data.data);
          } else if (response.status === 401) {

          }
          // setLoading(false);
        })
        .catch(function (error) {
          // setLoading(false);
          console.error(error);
        });
      // });
    } catch (e) {
      // setLoading(false);
    }
  }

  const handleChange = (e) => {
    // console.log(e.target.name + '-' + e.target.value);
    setFormData({ ...formData, [e.target.name]: e.target.value });
    // console.log(287, formData);
  };

  const changeHandler = (e) => {
    // alert('hi')
    setFormData({ ...formData, [e.target.name]: e.target.files[0] });
  };

  useEffect(() => {
    // console.log('use', formData);
  }, [formData])
  const [categoriesError, setCategoriesError] = useState(false);
  const [registerTypeError, setRegisterTypeError] = useState(false);
  const [planError, setPlanError] = useState(false);
  const [stateError, setStateError] = useState(false);

  const validationFunction = (values) => {
    let flag = false;
    console.log(values);
    if (values.categories.length == 0) {
      setCategoriesError(true);

      return false;
    } else if (values.categories.length > 0) {
      setCategoriesError(false);
      flag = true;

    }

    if (values.state.length == 0) {
      setStateError(true);

      return false;
    } else if (values.state.length > 0) {
      setStateError(false);
      flag = true;

    }

    if (values.registerType == '') {
      setRegisterTypeError(true)

      return false;
    } else if (values.registerType != '') {
      setRegisterTypeError(false)
      flag = true;
    }

    if (values.plan.length == 0) {
      setPlanError(true);

      return false;
    } else if (values.plan.length > 0) {
      setPlanError(false);
      flag = true;

    }


    if (flag) {
      return true;
    }

  }
  const handleFormSubmit = (values, errors) => {

    const shouldSubmit = validationFunction(values)

    console.log(shouldSubmit);
    if (shouldSubmit) {
      setLoading(true);
      try {
        // axios.get('/sanctum/csrf-cookie').then(response => {
        const uploadFormData = new FormData();
        for (let iKey in values) {
          if (iKey === 'uploadSign' || iKey === 'gst_no' || iKey === 'dl_no_20b' || iKey === 'dl_no_21b' || iKey === 'fssai_no' || iKey === 'panCard' || iKey === 'bankDoc') {
            uploadFormData.append(iKey, formData[iKey])
            // for (let file of formData[iKey]) {
            //   uploadFormData.append('picture', file);
            // }
          } else if (iKey === 'plan' || iKey === 'categories' || iKey === 'registerType') {
            uploadFormData.append(iKey, JSON.stringify(values[iKey]))
          } else if (iKey === 'state') {

            uploadFormData.append(iKey, JSON.stringify(values[iKey]))
          } else {
            uploadFormData.append(iKey, values[iKey])
            // console.log(iKey,values[iKey]);
          }

        }
        uploadFormData.append('referCode', ref_coupon)
        const config = { headers: { "Content-Type": "multipart/form-data" } };
        // axios.post('api/admin/partner/create', uploadFormData, config)
        
        axios.post('api/admin/partner/create', uploadFormData, config)
          .then(response => {
            // console.log(response);
            if (response.data.status === 200) {
              swal({
                title: "Good job!",
                text: response.data.message,
                icon: "success",
              });
              // console.log('nav')
              navigate('/');
             
            } else {
              swal({
                title: "Warning!",
                text: response.data.message,
                icon: "warning",
              });
              setLoading(false);
              // errors.setErrors(response.data.errors);
            }
        
          })
          .catch(function (error) {
            console.error(error);
            setLoading(false);
          });
        // });
      } catch (e) {
        console.log(e);
        setLoading(false);
      }
    }

  };

  const [ref_coupon, set_ref_coupon] = useState('');

  const handleRefCoupChange = (e) => {
    set_ref_coupon(e.target.value)
  }
  const handlesubmit_ref_coupon = (values, errors) => {
    try {
      // axios.get('/sanctum/csrf-cookie').then(response => {

      // axios.post('api/admin/partner/create', uploadFormData, config)
      // setLoading(true);
      axios.post('api/admin/partner/matchedCoupon', {
        referCode: ref_coupon,
      },)
        .then(response => {
          // console.log(response);
          if (response.data.statusApk === true) {
            handleFormSubmit(values, errors)
          } else {
            swal({
              title: "Warning!",
              text: "Invalid Referral Coupon!",
              icon: "warning",
            });
            // errors.setErrors(response.data.errors);
          }
          // setLoading(false);
        })
        .catch(function (error) {
          console.error(error);
          setLoading(false);
          swal({
            title: "Warning!",
            text: error.response.data.message,
            icon: "warning",
          });
        });
      // });
    } catch (e) {
      console.log(e);
      setLoading(false);
      swal({
        title: "Warning!",
        text: e.response.data.message,
        icon: "warning",
      });
    }
  }

  return (
    <>
      <Header />
      <JWTRegister>
        <Card className="card client-register">
          {/* <Grid container style={{ pading: '20px' }}> */}
          <div className='login-rightsss' style={{ padding: '20px 0px' }}>
            <h2 style={{ padding: '30px 0px 0px 20px' }}>Partner with us</h2>
            {/* <Grid item sm={6} xs={12}> */}
            {/* <Box p={4} height="100%"> */}
            <Formik
              onSubmit={(values, errors) => {
                handlesubmit_ref_coupon(values, errors)
                // handleFormSubmit(values, errors);
                console.log(values);
              }
              }
              initialValues={initialValues}
              validationSchema={validationSchema}
            >
              {({ values, errors, touched, handleChange, handleBlur, handleSubmit }) => (
                <form onSubmit={handleSubmit}>
                  <Grid container style={{ padding: '20px' }} spacing={2}>
                    <Grid item sm={6} xs={12}>
                      <label htmlFor="firstName" className='form-label'>First Name</label>
                      <TextField
                        fullWidth
                        size="small"
                        type="text"
                        name="firstName"
                        // label="Name"
                        variant="outlined"
                        onBlur={handleBlur}
                        value={values.firstName}
                        onChange={handleChange}
                        helperText={touched.firstName && errors.firstName}
                        error={Boolean(errors.firstName && touched.firstName)}
                        sx={{ mb: 3 }}
                      />
                    </Grid>
                    <Grid item sm={6} xs={12}>
                      <label htmlFor="lastName" className='form-label'>Last Name</label>
                      <TextField
                        fullWidth
                        size="small"
                        type="text"
                        name="lastName"
                        // label="Name"
                        variant="outlined"
                        onBlur={handleBlur}
                        value={values.lastName}
                        onChange={handleChange}
                        helperText={touched.lastName && errors.lastName}
                        error={Boolean(errors.lastName && touched.lastName)}
                        sx={{ mb: 3 }}
                      />
                    </Grid>
                    <Grid item sm={6} xs={12}>
                      <label htmlFor="email" className='form-label'>Email ID</label>
                      <TextField
                        fullWidth
                        size="small"
                        type="email"
                        name="email"
                        // label="Name"
                        variant="outlined"
                        onBlur={handleBlur}
                        value={values.email}
                        onChange={handleChange}
                        helperText={touched.email && errors.email}
                        error={Boolean(errors.email && touched.email)}
                        sx={{ mb: 3 }}
                      />
                    </Grid>
                    <Grid item sm={6} xs={12}>
                      <label htmlFor="mobile" className='form-label'>Mobile No.</label>
                      {/* <PhoneInput
                        country={"in"}
                        value={values.mobile}
                        name="mobile"
                        onChange={handleChange}
                        onBlur={(e) => handleBlur(e)}
                        defaultMask=".... ... ..."
                        masks={{ in: ".........." }}
                        onlyCountries={["in"]}
                        inputProps={{
                          name: "mobile",
                          required: true,
                        }}
                        disableSearchIcon={true}
                        disableDropdown={true}
                        containerClass="h-12 text-sm focus:outline-none block w-full bg-gray-100 dark:bg-transparent border-transparent focus:bg-white"

                        // containerClass="h-12 text-sm focus:outline-none block w-full bg-gray-100 dark:bg-transparent border-transparent focus:bg-white"
                        inputClass="border h-12 text-sm focus:outline-none block w-full bg-gray-100 dark:bg-transparent border-transparent focus:bg-white"
                        inputStyle={{
                          background: "transparent",
                          // border: "1px solid grey",
                          height: "2.70em",
                          width: "100%",
                          outline: "none",

                        }}
                        buttonStyle={{
                          // height: "3em",
                          // background: "transparent",
                          // outline: "none",
                          // border: "none",
                        }}
                        style={{ marginBottom: "22px" }}
                      /> */}
                      <TextField
                        fullWidth
                        size="small"
                        type="text"
                        name="mobile"
                        // label="Name"
                        variant="outlined"
                        onBlur={handleBlur}
                        value={values.mobile}
                        onChange={handleChange}
                        helperText={touched.mobile && errors.mobile}
                        error={Boolean(errors.mobile && touched.mobile)}
                        sx={{ mb: 3 }}
                        inputProps={
                          { maxLength: 10 }
                        }
                      />
                    </Grid>
                    <Grid item sm={6} xs={12}>
                      <label htmlFor="firmName" className='form-label'>Firm Name</label>
                      <TextField
                        fullWidth
                        size="small"
                        type="text"
                        name="firmName"
                        // label="Name"
                        variant="outlined"
                        onBlur={handleBlur}
                        value={values.firmName}
                        onChange={handleChange}
                        helperText={touched.firmName && errors.firmName}
                        error={Boolean(errors.firmName && touched.firmName)}
                        sx={{ mb: 3 }}
                      />
                    </Grid>
                    <Grid item sm={6} xs={12}>
                      <label htmlFor="plan" className='form-label'>Plan</label>
                      <FormControl fullWidth
                        sx={{ mb: 3 }}
                        size="small"
                      >
                        <Select
                          multiple
                          labelId="status-label"
                          id="plan"
                          name="plan"
                          value={values.plan}
                          onChange={(e) => { handleChange(e); setPlanError(false) }}
                          variant="outlined"
                          onBlur={handleBlur}
                          error={Boolean(errors.plan && touched.plan)}
                        >
                          {/* <MenuItem value="" key="sg">Select Plan</MenuItem> */}
                          {plans && plans.map((row) =>
                            <MenuItem value={row} key={row.id}>{row.plan}</MenuItem>
                          )}
                        </Select>
                      </FormControl>
                      {planError &&
                        <div style={{ color: '#ff3d57' }}>{`Select atleast one Plan!`}
                        </div>
                      }
                    </Grid>
                    <Grid item sm={6} xs={12}>
                      <label htmlFor="address" className='form-label'>Address</label>
                      <TextField
                        fullWidth
                        size="small"
                        type="text"
                        name="address"
                        // label="Name"
                        variant="outlined"
                        onBlur={handleBlur}
                        value={values.address}
                        onChange={handleChange}
                        helperText={touched.address && errors.address}
                        error={Boolean(errors.address && touched.address)}
                        sx={{ mb: 3 }}
                      />
                    </Grid>

                    <Grid item sm={6} xs={12}>
                      <label htmlFor="indian_state_abbreviation" className='form-label'>State</label>
                      <FormControl fullWidth
                        sx={{ mb: 3 }}
                        size="small"
                      >
                        <Select
                          labelId="status-label"
                          id="brand_id"
                          name="state"
                          // label="Select States"
                          value={values.state}
                          onChange={handleChange}
                          variant="outlined"
                          onBlur={handleBlur}
                          // defaultValue={values.state.toString()}
                          error={Boolean(errors.state && touched.state)}
                        >
                          <MenuItem value="" key="sg">Select State</MenuItem>
                          {states && states.map((row) =>
                            <MenuItem value={row} key={row[1]}>{row[0]} ({row[1]})</MenuItem>
                          )}

                        </Select>
                        {stateError &&
                          <div style={{ color: '#ff3d57' }}>{`Select atleast one State!`}
                          </div>
                        }
                      </FormControl>
                    </Grid>
                    <Grid item sm={6} xs={12}>
                      <label htmlFor="city" className='form-label'>City</label>
                      <TextField
                        fullWidth
                        size="small"
                        type="text"
                        name="city"
                        // label="Name"
                        variant="outlined"
                        onBlur={handleBlur}
                        value={values.city}
                        onChange={handleChange}
                        helperText={touched.city && errors.city}
                        error={Boolean(errors.city && touched.city)}
                        sx={{ mb: 3 }}
                      />
                    </Grid>
                    <Grid item sm={6} xs={12}>
                      <label htmlFor="pinCode" className='form-label'>Pin Code</label>
                      <TextField
                        fullWidth
                        size="small"
                        type="number"
                        name="pinCode"
                        // label="Name"
                        variant="outlined"
                        onBlur={handleBlur}
                        value={values.pinCode}
                        onChange={handleChange}
                        helperText={touched.pinCode && errors.pinCode}
                        error={Boolean(errors.pinCode && touched.pinCode)}
                        sx={{ mb: 3 }}
                        inputProps={{ min: 6 }}
                      />
                    </Grid>
                    <Grid item sm={6} xs={12}>
                      <label htmlFor="refCoupon" className='form-label'>Referral Code</label>
                      <TextField
                        fullWidth
                        size="small"
                        type="text"
                        name="refCoupon"
                        // label="Name"
                        variant="outlined"

                        value={ref_coupon}
                        onChange={handleRefCoupChange}

                        sx={{ mb: 3 }}
                        inputProps={{ min: 6 }}
                      />
                    </Grid>
                    <Grid item sm={6} xs={12}>
                      {/* <label htmlFor="gst_no" className='form-label'>GST No</label>
                      <TextField
                        fullWidth
                        size="small"
                        type="file"
                        name="gst_no"
                        // label="Name"
                        variant="outlined"
                        onBlur={handleBlur}
                        value={values.gst_no}
                        onChange={handleChange}
                        helperText={touched.gst_no && errors.gst_no}
                        error={Boolean(errors.gst_no && touched.gst_no)}
                        sx={{ mb: 3 }}
                      /> */}
                      <label htmlFor="gst_no" className='form-label'>GST File</label>
                      <FormControl fullWidth={true} sx={{ mb: 3 }}>
                        <input
                          id="gst_no"
                          name="gst_no"
                          type="file"
                          onChange={changeHandler}
                        />
                      </FormControl>
                      {(errors.gst_no)
                        && (touched.gst_no)
                        && <div style={{ color: '#ff3d57' }}>{`${errors.gst_no}`}</div>}

                    </Grid>
                  </Grid>
                  <Grid container style={{ padding: '0px 20px' }} spacing={2}>
                    <Grid item sm={12} xs={12} lg={12}>
                      <h3 style={{ marginLeft: 0, fontSize: '17px' }}>Categories</h3>
                    </Grid>
                    <FormControl sx={{ mb: 3 }} component="fieldset" variant="standard" style={{ padding: "0px 0px 0px 16px" }}>
                      {/* <FormLabel component="legend">Assign responsibility</FormLabel> */}
                      <FormGroup row={true}>
                        {categories ? categories
                          .map((row, index) => (
                            <Grid item sm={6} xs={12} lg={4} key={index}>
                              <FormControlLabel
                                control={
                                  <Checkbox
                                    // checked={values.categories.id==row.category.id}
                                    name='Categories'
                                    value={row.category}
                                    onChange={e => {
                                      setCategoriesError(false)
                                      if (e.target.checked) {
                                        // userCategories.push(row.id);

                                        values.categories.push(row)
                                        if (values.categories.length == 1) {
                                          if (values.categories[0] == 'Ayurvedic') {
                                            setDLOpen(false);
                                            setFssaiOpen(false);
                                          } else if (values.categories[0] == 'OTC') {
                                            setDLOpen(false);
                                            setFssaiOpen(true);
                                          } else {
                                            setDLOpen(true);
                                            setFssaiOpen(true);
                                          }
                                        } else if (values.categories.length) {
                                          if (values.categories.length == 2) {
                                            if ((values.categories[0] == 'Ayurvedic' && values.categories[1] == 'OTC') || (
                                              values.categories[0] == 'OTC' && values.categories[1] == 'Ayurvedic'
                                            )) {
                                              setDLOpen(false);
                                              setFssaiOpen(true);
                                            } else {
                                              setDLOpen(true);
                                              setFssaiOpen(true);
                                            }
                                          }
                                          else if (values.categories.length > 2) {
                                            setDLOpen(true);
                                            setFssaiOpen(true);
                                          }
                                        } else {
                                          setDLOpen(false);
                                          setFssaiOpen(false);
                                        }

                                      } else {
                                        // console.log(653,values.categories);

                                        for (let i = 0; i < values.categories.length; i++) {
                                          // console.log(row.id,values.categories[i].id);
                                          if (row == values.categories[i]) {
                                            values.categories.splice(i)
                                          }

                                        }
                                        // console.log(values.categories);
                                        // const updateUserCat = formData.user_categories.filter((rowLal) => rowLal !== (row.id));
                                        // setUserCategories(updateUserCat);
                                        // setFormData({ ...formData, ['user_categories']: updateUserCat });
                                        // console.log(updateUserCat[0]);
                                        // console.log('hi');
                                        // console.log(values.categories);
                                        if (values.categories.length == 1) {
                                          if (values.categories[0] == 'Ayurvedic') {
                                            setDLOpen(false);
                                            setFssaiOpen(false);
                                          } else if (values.categories[0] == 'OTC') {
                                            setDLOpen(false);
                                            setFssaiOpen(true);
                                          } else {
                                            setDLOpen(true);
                                            setFssaiOpen(true);
                                          }
                                        } else if (values.categories.length) {
                                          if (values.categories.length == 2) {
                                            if ((values.categories[0] == 'Ayurvedic' && values.categories[1] == 'OTC') || (
                                              values.categories[0] == 'OTC' && values.categories[1] == 'Ayurvedic'
                                            )) {
                                              setDLOpen(false);
                                              setFssaiOpen(true);
                                            }
                                          }
                                          else if (values.categories.length > 2) {
                                            setDLOpen(true);
                                            setFssaiOpen(true);
                                          }
                                        } else {
                                          setDLOpen(false);
                                          setFssaiOpen(false);
                                        }
                                      }
                                    }}
                                  />
                                }
                                label={row}
                              />

                            </Grid>
                          )) : <></>}
                      </FormGroup>
                      {/* error={Boolean(errors.gst_no && touched.gst_no)}
                      <ErrorMessage name='user_categories' /> */}
                      {categoriesError &&
                        <div style={{ color: '#ff3d57' }}>{`Select atleast one category!`}
                        </div>
                      }
                    </FormControl>
                  </Grid>
                  {DLOpen && (
                    <Grid container style={{ padding: '0px 20px' }} spacing={2}>
                      <Grid item sm={6} xs={12}>

                        <label htmlFor="dl_no_20b" className='form-label'>DL NO 1</label>
                        <FormControl fullWidth={true} sx={{ mb: 3 }}>
                          <input
                            id="dl_no_20b"
                            name="dl_no_20b"
                            type="file"
                            onChange={changeHandler}
                          />
                        </FormControl>
                        {(errors.dl_no_20b)
                          && (touched.dl_no_20b)
                          && <div style={{ color: '#ff3d57' }}>{`${errors.dl_no_20b}`}</div>}

                      </Grid>
                      <Grid item sm={6} xs={12}>
                        {/* <label htmlFor="dl_no_21b" className='form-label'>DL NO 2</label>
                        <TextField
                          fullWidth
                          size="small"
                          type="text"
                          name="dl_no_21b"
                          // label="Name"
                          variant="outlined"
                          onBlur={handleBlur}
                          value={values.dl_no_21b}
                          onChange={handleChange}
                          helperText={touched.dl_no_21b && errors.dl_no_21b}
                          error={Boolean(errors.dl_no_21b && touched.dl_no_21b)}
                          sx={{ mb: 3 }}
                        /> */}

                        <label htmlFor="dl_no_21b" className='form-label'>DL NO 2</label>
                        <FormControl fullWidth={true} sx={{ mb: 3 }}>
                          <input
                            id="dl_no_21b"
                            name="dl_no_21b"
                            type="file"
                            onChange={changeHandler}
                          />
                        </FormControl>
                        {(errors.dl_no_21b)
                          && (touched.dl_no_21b)
                          && <div style={{ color: '#ff3d57' }}>{`${errors.dl_no_21b}`}</div>}
                      </Grid>
                    </Grid>
                  )}
                  {fssaiOpen && (<Grid container style={{ padding: '0px 20px' }} spacing={2}>
                    <Grid item sm={6} xs={12}>
                      {/* <label htmlFor="fssai_no" className='form-label'>FSSAI No</label>
                      <TextField
                        fullWidth
                        size="small"
                        type="text"
                        name="fssai_no"
                        // label="Name"
                        variant="outlined"
                        onBlur={handleBlur}
                        value={values.fssai_no}
                        onChange={handleChange}
                        helperText={touched.fssai_no && errors.fssai_no}
                        error={Boolean(errors.fssai_no && touched.fssai_no)}
                        sx={{ mb: 3 }}
                      /> */}
                      <label htmlFor="fssai_no" className='form-label'>FSSAI Certificate</label>
                      <FormControl fullWidth={true} sx={{ mb: 3 }}>
                        <input
                          id="fssai_no"
                          name="fssai_no"
                          type="file"
                          onChange={changeHandler}
                        />
                      </FormControl>
                      {(errors.fssai_no)
                        && (touched.fssai_no)
                        && <div style={{ color: '#ff3d57' }}>{`${errors.fssai_no}`}</div>}
                    </Grid>
                  </Grid>
                  )}
                  <Grid container style={{ padding: '0px 20px' }} spacing={2}>
                    <Grid item sm={6} xs={12} lg={12}>
                      <label htmlFor="registerType" className='form-label'>Register As</label>
                      <RadioGroup
                        row
                        aria-labelledby="demo-radio-buttons-group-label"
                        // defaultValue="M"
                        id='registerType'
                        name="registerType"
                        sx={{ mb: 2 }}
                        value={values.registerType}
                        onChange={(e) => { handleChange(e); setRegisterTypeError(false) }}
                      >
                        <FormControlLabel value="Manufacturer" control={<Radio />} label="Manufacturer/Company" />
                        <FormControlLabel value="Distributor" control={<Radio />} label="Distributor" />
                        <FormControlLabel value="Wholesaler" control={<Radio />} label="Wholesaler" />
                        <FormControlLabel value="Retailer" control={<Radio />} label="Retailer" />
                        <FormControlLabel value="Hospital" control={<Radio />} label="Hospital" />
                      </RadioGroup>
                      {registerTypeError &&
                        <div style={{ color: '#ff3d57' }}>{`Select you'r Register type!`}
                        </div>
                      }
                    </Grid>
                    {(values.registerType == 'Manufacturer' || values.registerType == 'Distributor' || values.registerType == 'Wholesaler') ? (
                      <>
                        <Grid item sm={12} xs={12} lg={12} style={{ paddingTop: "10px" }}>
                          <h3 style={{ color: theme.palette.primary.main, marginLeft: 0, fontSize: '17px' }}>Bank Details</h3>
                        </Grid>
                        <Grid item sm={6} xs={12}>
                          <label htmlFor="bankDoc" className='form-label'>Cancelled Cheque / Passbook</label>
                          <FormControl fullWidth={true} sx={{ mb: 3 }}>
                            <input
                              id="bankDoc"
                              name="bankDoc"
                              type="file"
                              onChange={changeHandler}
                            />
                          </FormControl>
                          {(errors.bankDoc)
                            && (touched.bankDoc)
                            && <div style={{ color: '#ff3d57' }}>{`${errors.bankDoc}`}</div>}
                        </Grid>
                        <Grid item sm={6} xs={12}>
                          <label htmlFor="panCard" className='form-label'>Pancard</label>
                          <FormControl fullWidth={true} sx={{ mb: 3 }}>
                            <input
                              id="panCard"
                              name="panCard"
                              type="file"
                              onChange={changeHandler}
                            />
                          </FormControl>
                          {(errors.panCard)
                            && (touched.panCard)
                            && <div style={{ color: '#ff3d57' }}>{`${errors.panCard}`}</div>}
                        </Grid>
                        {/* <Grid item sm={6} xs={12}>
                          <label htmlFor="ifsc_code" className='form-label'>IFSC Code</label>
                          <TextField
                            fullWidth
                            size="small"
                            type="text"
                            name="ifsc_code"
                            // label="Name"
                            variant="outlined"
                            onBlur={handleBlur}
                            value={values.ifsc_code}
                            onChange={handleChange}
                            helperText={touched.ifsc_code && errors.ifsc_code}
                            error={Boolean(errors.ifsc_code && touched.ifsc_code)}
                            sx={{ mb: 3 }}
                          />
                        </Grid>
                        <Grid item sm={6} xs={12}>
                          <label htmlFor="branch" className='form-label'>Branch</label>
                          <TextField
                            fullWidth
                            size="small"
                            type="text"
                            name="branch"
                            // label="Name"
                            variant="outlined"
                            onBlur={handleBlur}
                            value={values.branch}
                            onChange={handleChange}
                            helperText={touched.branch && errors.branch}
                            error={Boolean(errors.branch && touched.branch)}
                            sx={{ mb: 3 }}
                          />
                        </Grid> */}
                      </>
                    )
                      : <></>}
                    <Grid item sm={6} xs={12}>
                      <label htmlFor="firstName" className='form-label'>Upload Signature</label>
                      <FormControl fullWidth={true} sx={{ mb: 3 }}>
                        <input
                          id="upload-photo"
                          name="uploadSign"
                          type="file"
                          onChange={changeHandler}
                        />
                      </FormControl>
                      {(errors.uploadSign)
                        && (touched.uploadSign)
                        && <div style={{ color: '#ff3d57' }}>{`${errors.uploadSign}`}</div>}
                    </Grid>

                  </Grid>
                  <Grid container style={{ padding: '30px 20px 0px' }} spacing={4}>
                    <Grid item sm={6} xs={12} lg={9}>
                      <FlexBox gap={1} alignItems="center">
                        <Checkbox
                          size="small"
                          name="agree_terms"
                          onChange={handleChange}
                          checked={values.agree_terms}
                          sx={{ padding: 0 }}
                        />
                        <Paragraph>
                          Please tick the box to accept our
                          <span style={{ color: theme.palette.primary.main, marginLeft: 5, cursor: 'pointer' }} title="Terms & Conditions" onClick={openTermsModal}>Terms & Conditions, </span>
                          <span style={{ color: theme.palette.primary.main, marginLeft: 5, cursor: 'pointer' }} title="Refund Policies" onClick={openRefundModal}>Refund Policies</span> and
                          <span style={{ color: theme.palette.primary.main, marginLeft: 5, cursor: 'pointer' }} title="Privacy Policy" onClick={openPrivacyModal} >Privacy Policies</span>.
                        </Paragraph>
                      </FlexBox>
                      {(errors.agree_terms && (errors.agree_terms)
                        && (touched.agree_terms)
                        && (
                          <>
                            <Paragraph style={{ color: '#ff3d57' }}>{`${errors.agree_terms}`}</Paragraph>
                          </>
                        ))}
                    </Grid>
                    <Grid item sm={6} xs={12} lg={4}>
                      <LoadingButton
                        fullWidth
                        type="submit"
                        color="primary"
                        loading={loading}
                        variant="contained"
                        sx={{ mb: 2, mt: 4 }}
                      >
                        Register
                      </LoadingButton>
                    </Grid>
                  </Grid>
                  <br />
                  <Paragraph style={{ textAlign: "center" }}>
                    Already have an account?
                    <NavLink
                      to="/login"
                      style={{ color: theme.palette.primary.main, marginLeft: 5 }}
                    >
                      Login
                    </NavLink>
                  </Paragraph>
                </form>
              )}
            </Formik>
            {/* </Box> */}
            {/* </Grid> */}
          </div>
          {/* </Grid> */}
        </Card>
      </JWTRegister >
      {/* <AppLinks /> */}
      <Footer />
      <div className="containerModal">
        {showTerms ? (
          <div className="modals">
            <div className="headerModal ">
              <div className="headingDiv ">
                <h4 className="headingModal">Terms & Conditions</h4>
              </div>
              <div className="close-btn-div">
                <AiOutlineClose
                  onClick={closeTermsModal}
                  type="button"
                  size={25}
                  style={{ cursor: "pointer" }}
                  className="close-btn"
                />
              </div>
            </div>

            <p className="points">
              This website is operated by Hegan India Private Limited. Throughout the site, the terms “we”, “us” and “our” refer to Hegan India Private Limited. Hegan India Private Limited offers this website, including all information, tools and services available from this site to you, the user, conditioned upon your acceptance of all terms, conditions, policies and notices stated here.
            </p>
            <p className="points">
              By visiting our site and/ or purchasing something from us, you engage in our “Service” and agree to be bound by the following terms and conditions (“Terms of Service”, “Terms”), including those additional terms and conditions and policies referenced herein and/or available by hyperlink. These Terms of Service apply to all users of the site, including without limitation users who are browsers, vendors, customers, merchants, and/ or contributors of content.
            </p>
            <p className="points">
              Please read these Terms of Service carefully before accessing or using our website. By accessing or using any part of the site, you agree to be bound by these Terms of Service. If you do not agree to all the terms and conditions of this agreement, then you may not access the website or use any services. If these Terms of Service are considered an offer, acceptance is expressly limited to these Terms of Service.
            </p>
            <p className="points">
              Any new features or tools which are added to the current store shall also be subject to the Terms of Service. You can review the most current version of the Terms of Service at any time on this page. We reserve the right to update, change or replace any part of these Terms of Service by posting updates and/or changes to our website. It is your responsibility to check this page periodically for changes. Your continued use of or access to the website following the posting of any changes constitutes acceptance of those changes.
            </p>
            <h3 className='points'>SECTION 1 - ONLINE STORE TERMS</h3>
            <p className="points">
              By agreeing to these Terms of Service, you represent that you are at least the age of majority in your state or province of residence, or that you are the age of majority in your state or province of residence and you have given us your consent to allow any of your minor dependents to use this site.<br />
              You may not use our products for any illegal or unauthorized purpose nor may you, in the use of the Service, violate any laws in your jurisdiction (including but not limited to copyright laws).
              You must not transmit any worms or viruses or any code of a destructive nature.
              A breach or violation of any of the Terms will result in an immediate termination of your Services
            </p>
            <h3 className='points'>SECTION 2 - GENERAL CONDITIONS</h3>
            <p className='points'>
              We reserve the right to refuse service to anyone for any reason at any time. You understand that your content (not including credit card information), may be transferred unencrypted and involve (a) transmissions over various networks; and (b) changes to conform and adapt to technical requirements of connecting networks or devices. Credit card information is always encrypted during transfer over networks.
            </p>
            <p className='points'>You agree not to reproduce, duplicate, copy, sell, resell or exploit any portion of the Service, use of the Service, or access to the Service or any contact on the website through which the service is provided, without express written permission by us.</p>
            <p className='points'>The headings used in this agreement are included for convenience only and will not limit or otherwise affect these Terms</p>
            <h3 className='points'>SECTION 3 - ACCURACY, COMPLETENESS AND TIMELINESS OF INFORMATION</h3>
            <p className='points'>We are not responsible if information made available on this site is not accurate, complete or current. The material on this site is provided for general information only and should not be relied upon or used as the sole basis for making decisions without consulting primary, more accurate, more complete or more timely sources of information. Any reliance on the material on this site is at your own risk. This site may contain certain historical information. Historical information, necessarily, is not current and is provided for your reference only. We reserve the right to modify the contents of this site at any time, but we have no obligation to update any information on our site. You agree that it is your responsibility to monitor changes to our site.</p>
            <h3 className='points'>SECTION 4 - MODIFICATIONS TO THE SERVICE AND PRICES</h3>
            <p className='points'>
              Prices for our products are subject to change without notice.<br />
              We reserve the right at any time to modify or discontinue the Service (or any part or content thereof) without notice at any time.<br />
              We shall not be liable to you or to any third-party for any modification, price change, suspension or discontinuance of the Service.
            </p>
            <h3 className='points'>SECTION 5 - PRODUCTS OR SERVICES</h3>
            <p className='points'>
              Certain products or services may be available exclusively online through the website. These products or services may have limited quantities and are subject to return or exchange only according to our Return Policy.<br />
              We have made every effort to display as accurately as possible the colours and images of our products that appear at the store. We cannot guarantee that your computer monitor's display of any colour will be accurate.<br />
              We reserve the right, but are not obligated, to limit the sales of our products or Services to any person, geographic region or jurisdiction. We may exercise this right on a case-by-case basis. We reserve the right to limit the quantities of any products or services that we offer. All descriptions of products or product pricing are subject to change at any time without notice, at the sole discretion of us. We reserve the right to discontinue any product at any time. Any offer for any product or service made on this site is void where prohibited.<br />
              We do not warrant that the quality of any products, services, information, or other material purchased or obtained by you will meet your expectations, or that any errors in the Service will be corrected

            </p>
            <h3 className='points'>SECTION 6 - ACCURACY OF BILLING AND ACCOUNT INFORMATION</h3>
            <p className='points'>
              We reserve the right to refuse any order you place with us. We may, in our sole discretion, limit or cancel quantities purchased per person, per household or per order. These restrictions may include orders placed by or under the same customer account, the same credit card, and/or orders that use the same billing and/or shipping address. In the event that we make a change to or cancel an order, we may attempt to notify you by contacting the e-mail and/or billing address/phone number provided at the time the order was made. We reserve the right to limit or prohibit orders that, in our sole judgment, appear to be placed by dealers, resellers or distributors. You agree to provide current, complete and accurate purchase and account information for all purchases made at our store. You agree to promptly update your account and other information, including your email address and credit card numbers and expiration dates, so that we can complete your transactions and contact you as needed.<br />
              For more detail, please review our Returns Policy.
            </p>
            <h3 className='points'>SECTION 7 - OPTIONAL TOOLS</h3>
            <p className='points'>
              We may provide you with access to third-party tools over which we neither monitor nor have any control nor input.<br />
              You acknowledge and agree that we provide access to such tools “as is” and “as available” without any warranties, representations or conditions of any kind and without any endorsement. We shall have no liability whatsoever arising from or relating to your use of optional third-party tools.<br />
              Any use by you of optional tools offered through the site is entirely at your own risk and discretion and you should ensure that you are familiar with and approve of the terms on which tools are provided by the relevant third-party provider(s).<br />
              We may also, in the future, offer new services and/or features through the website (including, the release of new tools and resources). Such new features and/or services shall also be subject to these Terms of Service.
            </p>

            <h3 className='points'>SECTION 8 - THIRD-PARTY LINKS</h3>
            <p className='points'>
              Certain content, products and services available via our Service may include materials from third-parties.<br />
              Third-party links on this site may direct you to third-party websites that are not affiliated with us. We are not responsible for examining or evaluating the content or accuracy and we do not warrant and will not have any liability or responsibility for any third-party materials or websites, or for any other materials, products, or services of third-parties.<br />
              We are not liable for any harm or damages related to the purchase or use of goods, services, resources, content, or any other transactions made in connection with any third-party websites. Please review carefully the third-party's policies and practices and make sure you understand them before you engage in any transaction. Complaints, claims, concerns, or questions regarding third-party products should be directed to the third-party.
            </p>
            <h3 className='points'>SECTION 9 - USER COMMENTS, FEEDBACK AND OTHER SUBMISSIONS</h3>
            <p className='points'>
              If, at our request, you send certain specific submissions (for example contest entries) or without a request from us you send creative ideas, suggestions, proposals, plans, or other materials, whether online, by email, by postal mail, or otherwise (collectively, 'comments'), you agree that we may, at any time, without restriction, edit, copy, publish, distribute, translate and otherwise use in any medium any comments that you forward to us. We are and shall be under no obligation (1) to maintain any comments in confidence; (2) to pay compensation for any comments; or (3) to respond to any comments. We may, but have no obligation to, monitor, edit or remove content that we determine in our sole discretion are unlawful, offensive, threatening, libellous, defamatory, pornographic, obscene or otherwise objectionable or violates any party’s intellectual property or these Terms of Service.
              <br /><br />
              You agree that your comments will not violate any right of any third-party, including copyright, trademark, privacy, personality or other personal or proprietary right. You further agree that your comments will not contain libellous or otherwise unlawful, abusive or obscene material, or contain any computer virus or other malware that could in any way affect the operation of the Service or any related website. You may not use a false e-mail address, pretend to be
              <br /><br />
              someone other than yourself, or otherwise mislead us or third-parties as to the origin of any comments. You are solely responsible for any comments you make and their accuracy. We take no responsibility and assume no liability for any comments posted by you or any third-party.
            </p>
            <h3 className='points'>SECTION 10 - PERSONAL INFORMATION</h3>
            <p className='points'>
              Your submission of personal information through the store is governed by our Privacy Policy.
            </p>

            <h3 className='points'>SECTION 11 - ERRORS, INACCURACIES AND OMISSIONS</h3>
            <p className='points'>Occasionally there may be information on our site or in the Service that contains typographical errors, inaccuracies or omissions that may relate to product descriptions, pricing, promotions, offers, product shipping charges, transit times and availability. We reserve the right to correct any errors, inaccuracies or omissions, and to change or update information or cancel orders if any information in the Service or on any related website is inaccurate at any time without prior notice (including after you have submitted your order). We undertake no obligation to update, amend or clarify information in the Service or on any related website, including without limitation, pricing information, except as required by law. No specified update or refresh date applied in the Service or on any related website, should be taken to indicate that all information in the Service or on any related website has been modified or updated.</p>


            <h3 className='points'>SECTION 12 - PROHIBITED USES</h3>
            <p className='points'>
              In addition to other prohibitions as set forth in the Terms of Service, you are prohibited from using the site or its content: (a) for any unlawful purpose; (b) to solicit others to perform or participate in any unlawful acts; (c) to violate any international, federal, provincial or state regulations, rules, laws, or local ordinances; (d) to infringe upon or violate our intellectual property rights or the intellectual property rights of others; (e) to harass, abuse, insult, harm, defame, slander, disparage, intimidate, or discriminate based on gender, sexual orientation, religion, ethnicity, race, age, national origin, or disability; (f) to submit false or misleading information; (g) to upload or transmit viruses or any other type of malicious code that will or may be used in any way that will affect the functionality or operation of the Service or of any related website, other websites, or the Internet; (h) to collect or track the personal information of others; (i) to spam, phish, pharm, pretext, spider, crawl, or scrape; (j) for any obscene or immoral purpose; or (k) to interfere with or circumvent the security features of the Service or any related website, other websites, or the Internet. We reserve the right to terminate your use of the Service or any related website for violating any of the prohibited uses.
            </p>

            <h3 className='points'>SECTION 13 - DISCLAIMER OF WARRANTIES; LIMITATION OF LIABILITY</h3>
            <p className='points'>
              We do not guarantee, represent or warrant that your use of our service will be uninterrupted, timely, secure or error-free. We do not warrant that the results that may be obtained from the use of the service will be accurate or reliable. You agree that from time to time we may remove the service for indefinite periods of time or cancel the service at any time, without notice to you. You expressly agree that your use of, or inability to use, the service is at your sole risk. The service and all products and services delivered to you through the service are (except as expressly stated by us) provided 'as is' and 'as available' for your use, without any representation, warranties or conditions of any kind, either express or implied, including all implied warranties or conditions of merchantability, merchantable quality, fitness for a particular purpose, durability, title, and non-infringement.
              In no case shall Hegan India Private Limited, our directors, officers, employees, affiliates, agents, contractors, interns, suppliers, service providers or licensors be liable for any injury, loss, claim, or any direct, indirect, incidental, punitive, special, or consequential damages of any kind, including, without limitation lost profits, lost revenue, lost savings, loss of data, replacement costs, or any similar damages, whether based in contract, tort (including negligence), strict liability or otherwise, arising from your use of any of the service or any products procured using the service, or for any other claim related in any way to your use of the service or any product, including, but not limited to, any errors or omissions in any content, or any loss or damage of any kind incurred as a result of the use of the service or any content (or product) posted, transmitted, or otherwise made available via the service, even if advised of their possibility. Because some states or jurisdictions do not allow the exclusion or the limitation of liability for consequential or incidental damages, in such states or jurisdictions, our liability shall be limited to the maximum extent permitted by law.
            </p>


            <h3 className='points'>
              SECTION 14 - INDEMNIFICATION
            </h3>

            <p className='points'>
              You agree to indemnify, defend and hold harmless Hegan India Private Limited and our parent, subsidiaries, affiliates, partners, officers, directors, agents, contractors, licensors, service providers, subcontractors, suppliers, interns and employees, harmless from any claim or demand, including reasonable attorneys’ fees, made by any third-party due to or arising out of your breach of these Terms of Service or the documents they incorporate by reference, or your violation of any law or the rights of a third-party.
            </p>
            <h3 className='points'>SECTION 15 - SEVERABILITY</h3>

            <p className='points'> In the event that any provision of these Terms of Service is determined to be unlawful, void or unenforceable, such provision shall nonetheless be enforceable to the fullest extent permitted by applicable law, and the unenforceable portion shall be deemed to be severed from these Terms of Service, such determination shall not affect the validity and enforceability of any other remaining provisions.</p>

            <h3 className='points'>SECTION 16 - TERMINATION</h3>

            <p className='points'>
              The obligations and liabilities of the parties incurred prior to the termination date shall survive the termination of this agreement for all purposes. These Terms of Service are effective unless and until terminated by either you or us. You may terminate these Terms of Service at any time by notifying us that you no longer wish to use our Services, or when you cease using our site.
              <br /><br />
              If in our sole judgment you fail, or we suspect that you have failed, to comply with any term or provision of these Terms of Service, we also may terminate this agreement at any time without notice and you will remain liable for all amounts due up to and including the date of termination; and/or accordingly may deny you access to our Services (or any part thereof).
            </p>
            <h3 className='points'>SECTION 17 - ENTIRE AGREEMENT</h3>

            <p className='points'>
              The failure of us to exercise or enforce any right or provision of these Terms of Service shall not constitute a waiver of such right or provision. These Terms of Service and any policies or operating rules posted by us on this site or in respect to The Service constitutes the entire agreement and understanding between you and us and govern your use of the Service, superseding any prior or contemporaneous agreements, communications and proposals, whether oral or written, between you and us (including, but not limited to, any prior versions of the Terms of Service). Any ambiguities in the interpretation of these Terms of Service shall not be construed against the drafting party.
            </p>
            <h3 className='points'>
              SECTION 18 - GOVERNING LAW
            </h3>

            <p className='points'>
              These Terms of Service and any separate agreements whereby we provide you Services shall be governed by and construed in accordance with the laws of India and jurisdiction of Jaipur, Rajasthan
            </p>
            <h3 className='points'>
              SECTION 19 - CHANGES TO TERMS OF SERVICE
            </h3>
            <p className='points'>
              You can review the most current version of the Terms of Service at any time at this page. We reserve the right, at our sole discretion, to update, change or replace any part of these Terms of Service by posting updates and changes to our website. It is your responsibility to check our website periodically for changes. Your continued use of or access to our website or the Service following the posting of any changes to these Terms of Service constitutes acceptance of those changes.
            </p>

            <h3 className='points'>SECTION 20 - CONTACT INFORMATION</h3>

            <p className='points'>Questions about the Terms of Service should be sent to us at support@hegan.in</p>
            <h3 className='points'>SECTION 21 - SHIPPING INFORMATION</h3>
            <p className='points'>The order purchased by the customer will be delivered within 7 working days.</p>
          </div>
        ) : (
          ""
        )}
      </div>

      <div className='containerModal'>
        {showRefundPolicies && (
          <div className='modals'>
            <div className="headerModal">
              <div className="headingDiv">
                <h4 className="headingModal">Refund Policies</h4>
              </div>
              <div className="close-btn-div">
                <AiOutlineClose
                  onClick={closeRefundModal}
                  type="button"
                  size={25}
                  style={{ cursor: 'pointer' }}
                />
              </div>
            </div>
            <p className="points">
              (1) If the consignment received by the customer is expired
              medicines then vendor will accept the refund or replace the
              medicine.
            </p>
            <p className="points">
              (2) If the consignment received by the customer is found to be
              damage (quality issue from vendor side) then vendor will accept the refund or replacement of the
              medicines
            </p>
            <p className='points'>
              Refund process to complete within 7 working days.
            </p>
          </div>
        )}

      </div>

      <div className="containerModal">
        {showPrivacyPolicies ? (
          <div className="modals">
            <div className="headerModal privacyHeading">
              <div className="headingDiv">
                <h4 className="headingModal ">Privacy Policies</h4>
              </div>
              <div className="close-btn-div">
                <AiOutlineClose
                  onClick={closePrivacyModal}
                  type="button"
                  size={25}
                  style={{ cursor: "pointer" }}
                />
              </div>
            </div>
            <p className="points">
              Hegan and its mobile application platform (each a “Platform”) is a
              platform that allows the users (hereinafter referred to as “User”
              or “you” or “your”) to communicate with each other directly either
              through direct messaging or through the mobile application itself
              without any kind of intervention from Hegan. Hegan recognizes the
              importance of privacy as well as the importance of maintaining the
              confidentiality of personal information. This Privacy Policy
              (“Privacy Policy”) applies to all products and services provided
              by Hegan and sets out how Hegan may collect, use and disclose
              information in relation to Users of the Platform.
            </p>
            <p className="points">
              User may use Hegan's services and products via a mobile device
              either through mobile applications or mobile optimized websites.
              This Privacy Policy also applies to such use of Hegan’s services
              and products.
            </p>
            <p className="points">
              All capitalized terms not defined in this document shall have the
              meanings ascribed to them in the Terms of Use of the Platform,
              which can be found here.
            </p>
            <h3 className="points">1. COLLECTION OF INFORMATION</h3>
            <p className="points">
              a. User(s) privacy is important to Hegan and Hegan has taken steps
              to ensure that Hegan do not collect more information from User
              than is necessary for Hegan to provide User(s) with Hegan’s
              services and to protect User(s) account.
            </p>
            <p className="points">
              b. Information including, but not limited to, username, address,
              phone number, fax number, email address, gender, date and/or year
              of birth and user preferences ("Registration Information"){" "}
            </p>
            <p className="points">
              c. In connection with any communication or transaction and payment
              services or any other services that you may avail using the
              Platform, information, including but not limited to, bank account
              numbers, billing and delivery information, credit/debit card
              numbers and expiration dates and tracking information from cheques
              or money orders ("Account Information") may be collected, among
              other things, to facilitate the sale and purchase as well as the
              settlement of purchase price of the products or services
              transacted on or procured through the Platform.
            </p>
            <p className="points">
              d. Hegan records and retains details of Users’ activities on the
              Platform. Information relating to communication or transactions
              including, but not limited to, the types and specifications of the
              goods, pricing and delivery information, any dispute records and
              any information disclosed in any communication forum provided by
              us and/or other affiliated companies of Hegan (“Activities
              Information”) may be collected as and when the communication and /
              or transactions are conducted through the Platform.
            </p>
            <p className="points">
              e. Hegan records and retains records of Users’ browsing or buying
              activities on Platform including but not limited to IP addresses,
              browsing patterns and User behavioural patterns. In addition, we
              gather statistical information about the Platform and visitors to
              the Platform including, but not limited to, IP addresses, browser
              software, operating system, software and hardware attributes,
              pages viewed, number of sessions and unique visitors (together
              "Browsing Information").
            </p>
            <p className="points">
              f. Registration Information, Account Information, Activities
              Information, and Browsing Information are collectively referred to
              as User Data
            </p>
            <p className="points">
              g. It is mandatory for Users of the Platform to provide certain
              categories of User Data (as specified at the time of collection).
              In the event that Users do not provide any or sufficient User Data
              marked as mandatory, Hegan may not be able to complete the
              registration process or provide such Users with Hegan’s products
              or services.
            </p>
            <h3 className="points">2. USE OF USER DATA</h3>
            <p className="points">
              If you provide any User Data to Hegan, you are deemed to have
              authorized Hegan to collect, retain and use that User Data for the
              following purposes:
            </p>
            <p className="points">a. Verification of User’s identity;</p>
            <p className="points">
              b. processing User’s registration as a user, providing User with a
              log-in ID for the Platform and maintaining and managing User’s
              registration;
            </p>
            <p className="points">
              c. providing User with customer service and responding to User(s)
              queries, feedback, claims or disputes;
            </p>
            <p className="points">
              d. to facilitate communication between Users on the Platform and /
              or processing Users transactions on the Platform;
            </p>
            <p className="points">
              e. performing research or statistical analysis in order to improve
              the content and layout of the Platform, to improve Hegan’s product
              offerings and services and for marketing and promotional purposes;
            </p>
            <p className="points">
              f. subject to applicable laws, Hegan (including our affiliated
              companies and their designated Service Providers may use User’s
              name, phone number, residential address, email address, fax number
              and other data ("Marketing Data") to provide notices, surveys,
              product alerts, communications and other marketing materials to
              User(s) relating to products and services offered by Hegan or
              Hegan’s affiliated companies; g. if User voluntarily submit any
              User informat
            </p>
            <p className="points">
              g. if User voluntarily submit any User information or other
              information to the Platform for publication on the Platform
              through the publishing tools, then Users are deemed to have given
              consent to the publication of such information on the Platform;
              and
            </p>
            <p className="points">
              h. making such disclosures as may be required for any of the above
              purposes or as required by law, regulations, and guidelines or in
              respect of any investigations, claims or potential claims brought
              on or against us or against third parties
            </p>
            <h3 className="points">3. DISCLOSURE OF USER DATA</h3>
            <p className="points">
              a. User further agrees that Hegan may disclose and transfer User
              Data to third party service providers (including but not limited
              to data entry, database management, promotions, products and
              services alerts, delivery services, payment extension services,
              authentication and verification services and logistics services)
              ("Service Providers"). These Service Providers are under a duty of
              confidentiality to Hegan and are only permitted to use User Data
              in connection with the purposes specified in clause 2 herein
              above.
            </p>
            <p className="points">
              b. User(s) agree that Hegan may disclose and transfer User Data to
              Hegan’s affiliated companies and/or their designated Service
              Providers.
            </p>
            <p className="points">
              c. When necessary Hegan may also disclose and transfer User Data
              to our professional advisers, law enforcement agencies, insurers,
              government and regulatory and other organizations.
            </p>
            <p className="points">
              d. Any User Data supplied by User will be retained by Hegan and
              will be accessible by our employees, any Service Providers engaged
              by Hegan and third parties referred to in clause 3 herein, for or
              in relation to any of the purposes stated in Clause 2 herein
              above.
            </p>
            <p className="points">
              e. All voluntary information uploaded by you on the Platform
              (including without limitation information about your products,
              images, remarks, feedbacks etc. ) may be made publicly available
              on the Platform and therefore accessible by any internet user. Any
              voluntary information that User disclose to Hegan becomes public
              information and User relinquishes any proprietary rights
              (including but not limited to the rights of confidentiality and
              copyright) in such information. User should exercise caution when
              deciding to include personal or proprietary information in the
              voluntary information that User submits to Hegan or uploads on the
              Platform.
            </p>
            <p className="points">
              f. Hegan may share User Data with third parties, including without
              limitation, banks, financial institutions, credit agencies, or
              vendors to enable such third parties to offer their products or
              services to such Users. While Hegan shall endeavor to have in
              place internal procedures to keep User Data secure from intruders,
              there is no guarantee that such measures/procedures can eliminate
              all of the risks of theft, loss or misuse.
            </p>
            <p className="points">
              g. Hegan may establish relationships with other parties and
              websites to offer User the benefit of products and services which
              Hegan does not offer. Hegan may offer you access to these other
              parties and/or their websites. This Privacy Policy does not apply
              to the products and services enabled or facilitated by such third
              parties. The privacy policies of those other parties may differ
              from Hegan, and Hegan has no control over the information that
              User may submit to those third parties. User should read the
              relevant privacy policy for those third parties before responding
              to and availing any offers, products or services advertised or
              provided by those third parties.
            </p>
            <h3 className="points">RIGHT TO UPDATE USER DATA</h3>
            <p className="points">
              Under the applicable laws, User(s) have the right of access to
              personal information held by Hegan and to request updating /
              correcting the information.
            </p>
            <h3 className="points">5. COOKIES</h3>
            <p className="points">
              Hegan uses "cookies" to store specific information about User and
              track User(s) visits to the Sites. A "cookie" is a small amount of
              data that is sent to User’s browser and stored on User’s device.
              If User does not deactivate or erase the cookie, each time User
              uses the same device to access the Platform, our services will be
              notified of User visit to the Platform and in turn Hegan may have
              knowledge of User visit and the pattern of User’s usage.
            </p>
            <p className="points">
              Generally, Hegan use cookies to identify User and enable Hegan to
              i) access User’s Registration Information or Account Information
              so User do not have to re-enter it; ii) gather statistical
              information about usage by Users; iii) research visiting patterns
              and help target advertisements based on User interests; iv) assist
              Hegan’s partners to track User visits to the Platform and process
              orders; and v) track progress and participation on the Platform.
            </p>
            <p className="points">
              User can determine if and how a cookie will be accepted by
              configuring the browser which is installed in User’s device. If
              User chooses, User can change those configurations. If User reject
              all cookies by choosing the cookie-disabling function, User may be
              required to re-enter information on the Platform more often and
              certain features of the Platform may be unavailable.
            </p>
            <h3 className="points">MINORS</h3>
            <p className="points">
              The Platform and its contents are not targeted to minors (those
              under the age of 18). However, Hegan have no way of distinguishing
              the age of individuals who access our Platform. If a minor has
              provided Hegan with personal information without parental or
              guardian consent, the parent or guardian should contact Hegan’s
              Legal Department at the address set out in clause 11 below to
              remove the information.
            </p>
            <h3 className="points">SECURITY MEASURES</h3>
            <p className="points">
              Hegan employs commercially reasonable security methods to prevent
              unauthorized access to the Platform, to maintain data accuracy and
              to ensure the correct use of the information Hegan hold. No data
              transmission over the internet or any wireless network can be
              guaranteed to be perfectly secure. As a result, while Hegan tries
              to protect the information it holds, Hegan cannot guarantee the
              security of any information the User transmits to Hegan and Users
              do so at their own risk
            </p>
            <h3 className="points">DATA RETENTING</h3>
            <p className="points">
              Hegan takes every reasonable step to ensure that User’s personal
              information is processed ‘as is’. You may access, correct, and
              update your personal information directly through the
              functionalities provided on the Platform.
            </p>
            <p className="points">
              You have an option to withdraw your consent that you have already
              provided by writing to us at the contact information provided
              below. Please mention “for withdrawal of consent” in the subject
              line of your communication. Hegan will verify such requests before
              acting upon your request. Please note, however, that withdrawal of
              consent will not be retroactive and will be in accordance with the
              terms of this Privacy Policy, related terms of use, and applicable
              laws. In the event you withdraw the consent given to us under this
              Privacy Policy, such withdrawal may hamper your access to the
              Platform or restrict the provision of our services to you for
              which Hegan consider that information to be necessary.
            </p>
            <h3 className="points">USER'S RIGHTS</h3>
            <p className="points">
              Hegan retains your personal information in accordance with
              applicable laws, for a period no longer than is required for the
              purpose for which it was collected or as required under any
              applicable law. However, Hegan may retain data related to you if
              it believes it may be necessary to prevent fraud or future abuse,
              to enable Hegan to exercise its legal rights and/or defend against
              legal claims or if required by law or for other legitimate
              purposes. Hegan may continue to retain your data in anonymised
              form for analytical and research purposes.
            </p>
            <p className='points'>Hegan gives rights to its users to delete all data stored in Hegan Website and App.</p>
            <h3 className="points">CHANGES TO THIS PRIVACY POLICY</h3>
            <p className="points">
              Any changes to this Privacy Policy will be communicated by us
              posting an amended and restated Privacy Policy on the Platform.
              Once posted on the Platform the new Privacy Policy will be
              effective immediately. Your continued use of the Platform shall be
              deemed to be your acceptance to the provisions of the Privacy
              Policy. User agrees that any information Hegan holds about User
              (as described in this Privacy Policy and whether or not collected
              prior to or after the new Privacy Policy became effective) will be
              governed by the latest version of the Privacy Policy
            </p>
          </div>
        ) : (
          ""
        )}
      </div>
    </>
  );
};

export default PartnerRegister;
