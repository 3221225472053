import React, { useEffect, useState } from 'react';
import {
  Link, NavLink, useLocation, useNavigate, useParams
} from 'react-router-dom';
import Moment from 'moment';
import swal from 'sweetalert';
import axios from 'axios';
import OwlCarousel from 'react-owl-carousel';
import useAuth from 'app/hooks/useAuth';

import 'owl.carousel/dist/assets/owl.carousel.css';
import 'owl.carousel/dist/assets/owl.theme.default.css';
import heganlogo from '../HomePage/heganlogo.png'

function RelatedProducts() {
  let { logout, user, isAuthenticated } = useAuth();
  const [products, setProducts] = useState(false);
  const [loading, setLoading] = useState(false);
  useEffect(() => {
    fetchData();
  }, []);

  const params = useParams();
  const navigate = useNavigate();

  const [slug, setSlug] = useState(params.slug);
  const [row_id, setRowId] = useState(params.row_id);

  /**
   * Fetching data from server.
   */
  const fetchData = async () => {
    console.log(row_id);
    setLoading(true);
    try {
      // await axios.get('/sanctum/csrf-cookie').then(response => {
      // afterwards add the api for related products using formulation of product.for now adding get all here.
      // var api_url = '/api/frontend/get-related-products';
      // if (isAuthenticated) {
      //   api_url = '/api/frontend/get-auth-related-products';
      // }
      axios.post('api/user/product/getByFormulation', {
        productId: row_id,
      })
        .then(response => {
          console.log(response);
          if (response.data.status === 200) {
            setProducts(response.data.data);
          } else if (response.data.response_code === 401) {
          } else {
          }
          setLoading(false);
        })
        .catch(function (error) {
          setLoading(false);
          console.log(error);
        });
      // });
    } catch (e) {
      setLoading(false);
    }
  }

  const options = {
    autoplay: true,
    loop: false,
    margin: 20,
    responsiveClass: true,
    navText: ['<i class="ion-ios-arrow-left"></i>', '<i class="ion-ios-arrow-right"></i>'],
    smartSpeed: 1000,
    responsive: {
      0: {
        items: 1,
        dots: false,
        nav: false,
      },
      700: {
        items: 2,
        nav: true,
        dots: false,
      },
      1000: {
        items: 4,
        nav: true,
        dots: false,
      },
      // responsive: {
      //   0: {
      //     items: 1,
      //     dots: false,
      //     nav: false,
      //   },
      //   700: {
      //     items: Math.min(2, products.length),
      //     nav: true,
      //     dots: false,
      //   },
      //   1000: {
      //     // items: Math.min(4, products.length),
      //     // nav: true,
      //     // dots: false,
      //     items: Math.min(4, products.length),
      //     nav: true,
      //     dots: false,

      //   },
      // },

    },
  };



  return (
    <>
      <div className="section small_pt prodstyel 1marg-btm30" style={{ margin: "40px 0px" }}>
        {products.length > 0 && (
          <>
            <div className="heading_tab_header">
              <h3 style={{ color: '#015998', fontWeight: 'bold' }}>Similar Products</h3>
            </div>

            <OwlCarousel className='hot_seller product_slider carousel_slider owl-carousel owl-theme nav_style1'
              {...options}
            >
              {
                products.map((row, index) => (
                  <div key={'hot-seller-pro-' + index} className="item">
                    <div className="product">
                      <div className="product_wrap">
                        <div className="sale_banner">
                          <Link to={`/product/details/${row.id}`} className="hover_effect1" title={row.productName}>
                            <img src={row.uploadImg ? `${row.uploadImg}` : heganlogo} />

                          </Link>
                          <div className='hint-pre'>
                            <img src={`${process.env.PUBLIC_URL}/assets/images/frontend/hint.png`} alt="Discount" />
                            <span>{(row.marginPercentage) ? row.marginPercentage : '0'}%</span>
                          </div>
                        </div>
                        <div className="product_cont">
                          <h5><Link to={`/product/details/${row.id}`} title={row.productName}>{row.productName}</Link></h5>
                          <div className="product_price">
                            <span className="descr">Sold By Seller: {row.user.userName}</span>
                            {(row.expiryDate === '00/00/0000' || row.expiryDate === 'NA') ? (<span className="date">Expiry Date: NA</span>) : (<span className='date'>Expiry Date: {Moment(row.expiryDate).format('DD MMM YYYY')}</span>
                            )}
                            <span className="pricesml">MRP ({row.mrp})</span>
                            <span className="pricebig">{process.env.REACT_APP_CURRENCY} {row.netRate}</span>
                            <Link to={`/product/details/${row.id}`} title={row.productName} className="pricebigcart"
                            > View Product
                            </Link>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                ))
              }
            </OwlCarousel>
          </>
        )}

        {/* {products && (products.length <= 4) &&
         ( <div className="item">
            <div className="product">
              <div className="product_wrap">
                <div className="sale_banner">
                  <Link to={`/product/details/${products[0].id}`} className="hover_effect1" title={products[0].productName}>
                    <img src={products[0].uploadImg ? `${products[0].uploadImg}` : heganlogo} />

                  </Link>
                  <div className='hint-pre'>
                    <img src={`${process.env.PUBLIC_URL}/assets/images/frontend/hint.png`} alt="Discount" />
                    <span>{(products[0].marginPercentage) ? products[0].marginPercentage : '0'}%</span>
                  </div>
                </div>
                <div className="product_cont">
                  <h5><Link to={`/product/details/${products[0].id}`} title={products[0].productName}>{products[0].productName}</Link></h5>
                  <div className="product_price">
                    <span className="descr">Sold By Seller: {products[0].user.userName}</span>
                    <span className="date">Expiry Date: {Moment(products[0].expiryDate).format('DD MMM YYYY')}</span>
                    <span className="pricesml">MRP ({products[0].mrp})</span>
                    <span className="pricebig">{process.env.REACT_APP_CURRENCY} {products[0].netRate}</span>
                    <Link to={`/product/details/${products[0].id}`} title={products[0].productName} className="pricebigcart"
                    > View Product
                    </Link>
                  </div>
                </div>
              </div>
            </div>
          </div>)
        } */}
      </div>
    </>
  );
}
export default RelatedProducts;
