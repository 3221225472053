import React, { useEffect, useState } from 'react';
import { Link, useParams, NavLink, useSearchParams, useLocation, useNavigate } from 'react-router-dom';
import { Grid, RadioGroup, FormControlLabel, Radio, Alert, Backdrop, CircularProgress, Button } from '@mui/material';
import { styled } from '@mui/material/styles';
import Paper from '@mui/material/Paper';
import FilterListIcon from '@mui/icons-material/FilterList';
import CloseIcon from '@mui/icons-material/Close';
import { makeStyles } from '@material-ui/core/styles';
import Header from './../Header/Header';
import Footer from './../Footer/Footer';
import './category.css'
import useAuth from 'app/hooks/useAuth';
import axios from 'axios';
import swal from 'sweetalert';
import Moment from 'moment';
import Pagination from "react-js-pagination";
import { Formik, FieldArray, ErrorMessage } from 'formik';

import "./../frontend.css";

import heganlogo from '../HomePage/heganlogo.png'
import BrandsSidePanel from './BrandsSidePanel';
import FormulationSidePanel from './FormulationSidePanel';
import SubCategoriesSidePanel from './SubCategoriesSidePanel';



const Item = styled(Paper)(({ theme }) => ({
  backgroundColor: theme.palette.mode === 'dark' ? '#1A2027' : '#fff',
  ...theme.typography.body2,
  padding: theme.spacing(1),
  textAlign: 'center',
  color: theme.palette.text.secondary,
}));

const useStyles = makeStyles({
  payment_table_heads: {
    fontFamily: 'Poppins, sans-serif',
    fontWeight: 'bold',
  },
});

function Category() {

  const classes = useStyles();
  const navigate = useNavigate();
  const { category_id, brand, margin_id, sub_category, formulation } = useParams();
  const location = useLocation();
  let { logout, user, isAuthenticated } = useAuth();
  const [searchProducts, setSearchProducts] = useState([]);
  const [categories, setCategories] = useState([]);
  const [brands, setBrands] = useState([]);
  const [searching, setSearching] = useState(false);
  const [searchTest, setSearchTest] = useState(false);
  const [loading, setLoading] = useState(false);
  const [searchParams, setSearchParams] = useSearchParams();
  const [show_categories, set_show_categories] = useState(true)
  const [caption,setCaption] = useState('')

  const initialValues = {
    q: (searchParams.get("q")) ? searchParams.get("q") : sub_category ? sub_category : formulation ? formulation : '',
    selected_categories: (category_id) ? category_id : '',
    selected_brands: (brand) ? brand : '',
    margin_id: (margin_id) ? margin_id : '',
  };

  const [formData, setFormData] = useState(initialValues);

  // filter on mobile screen logic

  const [showFilterButton, setShowFilterButton] = useState(false);
  const [showFilters, setShowFilters] = useState(false)

  useEffect(() => {
    const handleResize = () => {
      setShowFilterButton(window.innerWidth < 580);
    };
    // console.log(useParams);

    // Set initial state based on window width
    handleResize();

    // Add event listener for window resize
    window.addEventListener('resize', handleResize);

    // Clean up the event listener on component unmount
    return () => {
      window.removeEventListener('resize', handleResize);
    };
  }, []);

  const handleFilterDisplay = () => {
    setShowFilters(!showFilters);

  }

  const showLeftSuggestionPanel = () => {
    if (location.pathname.split('/')[1] == 'brand' || location.pathname.split('/')[1] == 'subCategories' ) {
      set_show_categories(false);
    }
  }

  useEffect(() => {

    // alert('coming inside')
    console.log('coming insdie')

    showLeftSuggestionPanel();

    if (!categories) {
      fetchData();
      // fetchSearchProduct();
      // fetchProducts(brand,1)
      fetchSearchProduct(1);
    } else {
      fetchSearchProduct(1);
      // fetchSearchProduct();
      // fetchProducts(brand,1)
    }
  }, [formData, searchTest, location]);
  /**
   * Fetching data from server.
   */
  const fetchData = async () => {
    setLoading(true);
    try {
      
      // await axios.get('/sanctum/csrf-cookie').then(response => {
      axios.get('api/admin/medicineCategory/getList')
        .then(response => {
          // console.log(response);
          if (response.data.status === 200) {
            setCategories(response.data.data);
          } else if (response.data.response_code === 401) {

          } else {

          }
          setLoading(false);
        })
        .catch(function (error) {
          setLoading(false);
          // console.log(error);
        });
      axios.get('api/admin/brand/getAll')
        .then(response => {
          // console.log(response);
          if (response.data.status === 200) {
            setBrands(response.data.data);
            // console.log(88, brands);
          } else if (response.data.response_code === 401) {

          } else {

          }
          setLoading(false);
        })
        .catch(function (error) {
          setLoading(false);
          // console.log(error);
        });
      // });
    } catch (e) {
      setLoading(false);
    }
  }
  const fetchSearchProduct = async (pageNumber = 1) => {
    setLoading(true);
    setSearching(true);
    try {
     
      // await axios.get('/sanctum/csrf-cookie').then(response => {
      // console.log(formData.selected_brands);
      // console.log(searchTest,formData.q,brand);
      var search_api_url = 'api/user/product/getFilter';
      if (isAuthenticated) {
        search_api_url = 'api/user/product/getFilter';
      }
      axios.post(search_api_url, {
        "page": pageNumber,
        "q": (searchTest) ? searchTest : formData.q ? formData.q : brand ? brand : '',
        "category": formData.selected_categories ? formData.selected_categories : '',
        // "brand": formData.selected_brands ? formData.selected_brands : '',
        "marginId": formData.margin_id ? formData.margin_id : '',

      })
        .then(response => {
          // console.log(response.data.total);
          if (response.data.status === 200) {
            setSearchProducts(response.data);
            setCaption(response.caption)
          } else if (response.data.status === 401) {

          } else {

          }
          setLoading(false);
          setSearching(false);
        })
        .catch(function (error) {
          setLoading(false);
          setSearching(false);
          // console.log(error);
        });
      // });
    } catch (e) {
      // setLoading(false);
      setSearching(false);
    }
  }



  const handleFormSubmit = (values, errors) => {
    // try {
    fetchSearchProduct();
    // axios.get('/sanctum/csrf-cookie').then(response => {
    //   const uploadFormData = new FormData();
    //   for (let iKey in values) {
    //     if (iKey === 'selected_categories') {
    //       uploadFormData.append(iKey, formData[iKey])
    //     } else {
    //       uploadFormData.append(iKey, values[iKey])
    //     }

    //   }
    //   const config = { headers: { "Content-Type": "multipart/form-data" } };
    //   axios.post('/api/client/register', uploadFormData, config)
    //     .then(response => {
    //       if (response.data.response_code === 200) {
    //         swal({
    //           title: "Good job!",
    //           text: response.data.message,
    //           icon: "success",
    //         });
    //         navigate('/');
    //       } else {
    //         swal({
    //           title: "Warning!",
    //           text: response.data.message,
    //           icon: "warning",
    //         });
    //         errors.setErrors(response.data.errors);
    //       }
    //       setLoading(false);
    //     })
    //     .catch(function (error) {
    //       console.error(error);
    //     });
    // });
    // } catch (e) {
    //   console.log(e);
    //   setLoading(false);
    // }
  };
  const handleFilterChange = (e) => {
    // console.log(e.target.name + '-' + e.target.value);
    setFormData({ ...formData, [e.target.name]: e.target.value });
    // console.log(formData);
  };





//when user click on left side top brands it will search all products of that brands 
  const fetchProducts = async (brand, page) => {
    try {
      // setLoading(true);  
      let pageSize = 52
      const response = await axios.post(
        `api/admin/subCategory/getAllBrandProduct?brand=${encodeURIComponent(
          brand,
        )}&page=${page}&pageSize=${pageSize}`);
        
        if (response.status === 200) {
          console.log('getting top brand new added in the ok build',response.data)
        setSearchProducts(response.data);
        setCaption(response.caption)
        setLoading(false);
      } else {
        setLoading(false);
      }
    } catch (error) {
      // Handle error
      console.error('Error fetching products:', error);
      setLoading(false);
    }
  };


  const take_params_from_panels = (val, urlFrom) => {
    if (urlFrom == 'brand') {

    //calling top brands search function to search all top brands 
      fetchProducts(val,1)

      // navigate(`/brand/${val}`)
      // setFormData({
      //   q: val,
      //   selected_categories: '',
      //   selected_brands: '',
      //   margin_id: '',
      // })

      setSearchTest(false);

      // console.log('hi');
    } 
    else if (urlFrom == 'subCategories') {
      navigate(`/subCategories/${val}`)
      setFormData({
        q: val,
        selected_categories: '',
        selected_brands: '',
        margin_id: '',
      })
      setSearchTest(false)
    }



    // fetchSearchProduct();
  }


  const handleSearchChange = (event) => {
    setSearchTest(event);
    // alert('coming')
    // Call fetchSearchProduct here whenever searchTest changes
    fetchSearchProduct(1);
};

  return (
    <>

      {/* <Header searchTest={searchTest} setSearchTest={setSearchTest} /> */}
      <Header searchTest={searchTest} setSearchTest={handleSearchChange} />
      {/* <input
        type="text"
        value={searchTest}
        onChange={(event) => setSearchTest(event.target.value)}
        onKeyPress={handleSearchChange}
      /> */}



      <div className="breadcrumb_section  page-title-mini" style={{ padding: '30px 0px' }}>
        <div className="container">
          <div className="row">
            <div className="col-md-6">
              <ol className="breadcrumb ">
                <li className='breadcrumbs_content'>
                  <Link to="/" style={{ color: 'white', }}>Home</Link>
                </li>
                <li className="breadcrumb-item"></li>
                <li className="breadcrumb-item" style={{ padding: '1% 0 1% 1.5%' }}></li>
                <li className='breadcrumbs_content'
                 onClick={ () => {fetchSearchProduct()}}
                >
                  <Link to="/categories?q=" style={{ color: 'white', }}>All Categories</Link>
                </li>

                {/* <li className="breadcrumb-item active">Himalaya Brand Store</li> */}
              </ol>
            </div>
          </div>
        </div>
      </div>
      <Backdrop
        sx={{ color: '#015998', zIndex: (theme) => theme.zIndex.drawer + 1 }}
        open={!!loading}
      // onClick={setLoading}
      >
        <CircularProgress color="inherit" />
      </Backdrop>
      <div style={show_categories ? { position: 'relative', } : { position: 'relative', margin: '0 0 0 128px' }}>
        {location.pathname.split('/')[1] == 'brand' && searchProducts && <BrandsSidePanel take_params_from_panels={take_params_from_panels} />}
        {location.pathname.split('/')[1] == 'subCategories' && searchProducts && <SubCategoriesSidePanel take_params_from_panels={take_params_from_panels} />}
        <div className="section marg-btm30">
          <div className="container">


            <div className="row">
              {showFilterButton && show_categories &&
                <div className='category-filter-div' >
                  <Button variant="contained" size="medium" endIcon={showFilters ? <CloseIcon /> : <FilterListIcon />} onClick={handleFilterDisplay}>Filter Products</Button>
                </div>}

              {(location.pathname.split('/')[1] == 'brand' || location.pathname.split('/')[1] == 'subCategories') && <div className="col-sm-1"></div>}
              {(!showFilterButton || showFilters) && show_categories && <div className="col-sm-3">
                <Formik
                  onSubmit={(values, errors) => {
                    // handleFormSubmit(values, errors);
                  }
                  }
                  initialValues={initialValues}
                >
                  {({ values, errors, touched, handleChange, handleBlur, handleSubmit, setFieldValue }) => (
                    <form onSubmit={handleSubmit}>
                      <div className="sidebar">
                        <div className="widget">
                          <h5 className="widget_title">category
                            {values.selected_categories && (
                              <Button onClick={e => {
                                setFieldValue('selected_categories', '');
                                setFormData({ ...formData, ['selected_categories']: '' });
                              }} className='text-right right' sx={{ float: "right" }}>Clear
                              </Button>
                            )}
                          </h5>
                          <ul className="list_brand lstscroll" style={{ height: "250px", overflow: 'auto' }}>
                            {categories && (
                              <RadioGroup
                                row
                                name="selected_categories"
                                value={values.selected_categories}
                                onChange={e => {
                                  setFieldValue('selected_categories', e.target.value);
                                  setFormData({ ...formData, [e.target.name]: e.target.value });
                                }
                                }
                              >
                                {
                                  categories.map((row, index) => (
                                    <li key={'medi-cat-flt-' + index} style={{ width: "100%" }}>
                                      <div className="custome-radio customerad">
                                        <FormControlLabel value={row.categoryName} control={<Radio />} label={row.categoryName} sx={{ height: "25px", }} />
                                      </div>
                                    </li>
                                  ))
                                }
                              </RadioGroup>
                            )}
                          </ul>
                        </div>
                        {/* <div className="widget">
                          <h5 className="widget_title">Brand
                            {values.selected_brands && (
                              <Button onClick={e => {
                                setFieldValue('selected_brands', '');
                                setFormData({ ...formData, ['selected_brands']: '' });
                              }} className='text-right right' sx={{ float: "right" }}>Clear
                              </Button>
                            )}
                          </h5>
                          <ul className="list_brand lstscroll" style={{ height: "250px", overflow: 'auto' }}>
                            {brands && (
                              <RadioGroup
                                row
                                name="selected_brands"
                                value={values.selected_brands}
                                onChange={e => {
                                  setFieldValue('selected_brands', e.target.value);
                                  setFormData({ ...formData, [e.target.name]: e.target.value });
                                }
                                }
                              >
                                {
                                  brands.map((row, index) => (
                                    <li key={'medi-brand-flt-' + index} style={{ width: "100%" }}>
                                      <div className="custome-radio customerad">
                                        <FormControlLabel value={row.brand} control={<Radio />} label={row.brand} sx={{ height: "25px" }} />
                                      </div>
                                    </li>
                                  ))
                                }
                              </RadioGroup>
                            )}
                          </ul>
                        </div> */}
                      </div>
                    </form>
                  )}
                </Formik>
              </div>}
              <div className={show_categories ? `col-sm-9` : `col-sm-10`}>
                <div className="row prodstyel product-lists" style={{ position: "relative" }}>
                {searchProducts && searchProducts.data && searchProducts.data.length ? (
                    searchProducts.data.map((row, index) => (
                      <div key={'hot-seller-pro-' + index} className="col-sm-12 col-md-4">
                        <div className="product">
                          <div className="product_wrap">
                            <div className="sale_banner" style={{ height: '230px', border: '2px solid #015998', borderRadius: '20px', }}>
                              <Link to={`/product/details/${row.id}`} className="hover_effect1" title={row.productName} target="_blank" rel="noreferrer">
                                <img src={row.uploadImg ? `${row.uploadImg}` : heganlogo} />
                              </Link>

                              <div className='hint-pre' >
                                <img style={{ right: "6px", maxHeight: '207px' }} src={`${process.env.PUBLIC_URL}/assets/images/frontend/hint.png`} alt="Discount" />
                                <span style={{ right: '5%' }} >{(row.marginPercentage) ? row.marginPercentage : '0'}%</span>
                              </div>
                            </div>
                            <div className="product_cont" style={{ display: 'flex', flexDirection: 'column', justifyContent: 'center', alignItems: 'center', }}>
                              <div className='blue_container ' style={{ width: '93%', background: '#015998', color: 'white', }}>
                                <span className={`descr classes.payment_table_heads`} ><Link to={`/product/details/${row.id}`} title={row.productName} target="_blank" rel="noreferrer" style={{ color: 'white', }}>{row.productName} </Link></span>
                              </div>
                              <div className="product_price" style={{ padding: '8px', width: '100%', }}>
                                <div className={`breaking-news-container blue_container classes.payment_table_heads`} style={{ background: '#015998', color: 'white', }}>
                                  <div className={`breaking-news classes.payment_table_heads`} >
                                    <span>{row.formulation}</span>
                                  </div>
                                </div>
                                {(row.expiryDate === '00/00/0000' || row.expiryDate === 'NA') ? (<span className={`descr  classes.payment_table_heads`} style={{ fontWeight: 'bold', }} >Expiry Date: NA</span>) : (<span className={`descr  classes.payment_table_heads`} style={{ fontWeight: 'bold', }} >Expiry Date: {Moment(row.expiryDate).format('DD MMM YYYY')}</span>
                                )}
                                <span className={`pricesml  classes.payment_table_heads`} style={{ fontWeight: 'bold', }} >MRP   ({row.mrp})</span>
                                <span className={`pricebig  classes.payment_table_heads`}>{'₹'} {(row.netRate / row.minQty).toFixed(2)}<span style={{ fontSize: '13px', margin: '0 0 0 3px', }}>(Unit Qty.)</span> </span>
                                <div style={{ display: 'flex', justifyContent: 'center', }}>
                                  <Link to={`/product/details/${row.id}`} title={row.productName} className="pricebigcart"
                                    style={{ width: '50%', borderRadius: '10px', }}> View
                                  </Link>
                                </div>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                    ))

                  ) : (
                    <Alert severity="info">Press Enter or No results match your search criteria!</Alert>
                  )}
                </div>
                {searchProducts && (searchProducts.total > 0) && (
                  <div className="row">
                    <div className="col-12">
                      <Pagination
                        innerClass="pagination mt-3 justify-content-center pagination_style1"
                        activePage={searchProducts.current_page}
                        itemsCountPerPage={searchProducts.per_page}
                        totalItemsCount={searchProducts.total}
                        pageRangeDisplayed={5}
                        onChange={(pageNumber) => { 
                          // alert(caption)
                          // searchProducts.caption === 'getFilter' 
                          // ? 
                           fetchSearchProduct(pageNumber) ;
                          //  : 
                          //  fetchProducts('Emocare',pageNumber)
                           }}
                        itemClass={"page-item"}
                        linkClass={"page-link"}
                      />

                    </div>
                  </div>
                )}
              </div>
            </div>
          </div>
        </div >
      </div>
      <Footer />
    </>
  );
}
export default Category;
