import React from 'react'
import './steps.css'
import { Paper } from '@mui/material'
import { Link } from 'react-router-dom'

import steps_1 from './steps1.png'
import steps_2 from './steps2.png'
import steps_3 from '../Footer/playBtn.png'


const Steps = () => {
    return (
        <>
            <div className='steps_num_maindiv'>
                <h3  style={{ margin: '0', color: 'white',padding:'7% 0',textAlign:'center',}}>Get Started In 3 Easy Steps!</h3>
                <Paper elevation={8} className='steps_submain_div' style={{ display: 'flex', flexDirection: 'column', justifyContent: 'center', alignItems: 'center', padding: '2px', }}>
                <Link to='/partner-register'>
                    <div className='steps_image_div'><img className='steps_num_img_top' src={steps_2} style={{paddingRight:'17px',}} /></div>
                    <div className='steps_num_img_top' style={{ textAlign: 'center', }}>
                        <h4 style={{ margin: '0', color: '#015998', fontSize: '1.1rem', }}>01. Documentation</h4>
                        <p style={{ margin: '0', color: '#015998', }}>Get An Account By Submitting Drug License</p>
                    </div>
                </Link>
                </Paper>
                <Paper elevation={8} className='steps_submain_div' style={{ display: 'flex', flexDirection: 'column', justifyContent: 'center', alignItems: 'center', padding: '2px', }}>
                <a href='https://play.google.com/store/apps/details?id=com.hegan.in' style={{display:'contents'}}>
                    <div className='steps_gimage_div'><img className='steps_num_img_top' src={steps_3} /></div>
                    <div className='steps_num_img_top' style={{ textAlign: 'center', }}>
                        <h4 style={{ margin: '0', color: '#015998', fontSize: '1.2rem', }}>02. App Download</h4>
                        <p style={{ margin: '0', color: '#015998', }}>Gain Access To Large Inventory</p>
                    </div>
                </a>
                </Paper>
                <Paper elevation={8} className='steps_submain_div' style={{ display: 'flex', flexDirection: 'column', justifyContent: 'center', alignItems: 'center', padding: '2px', }}>
                <Link to='/categories'>
                    <div className='steps_image_div'><img className='steps_num_img_top' src={steps_1} /></div>
                    <div className='steps_num_img_top' style={{ textAlign: 'center', }}>
                        <h4 style={{ margin: '0', color: '#015998', fontSize: '1.2rem', }}>03. Start Business</h4>
                        <p style={{ margin: '0', color: '#015998', }}>Start Buying And Selling</p>
                    </div>
                </Link>
                </Paper>





            </div>
        </>
    )
}

export default Steps