import React, { useState, useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import { useCart } from 'app/contexts/CartContext';
import {
  Button, Grid, Alert, AlertTitle
} from '@mui/material';
import DeleteIcon from '@mui/icons-material/Delete';
import CheckCircleIcon from '@mui/icons-material/CheckCircle';
import axios from 'axios';
import swal from 'sweetalert';

import * as Yup from 'yup';
import Moment from 'moment';

import PaymentDetails from './PaymentDetails';
import OrderProducts from './OrderProducts';
import PaymentProcess from './PaymentProcess';
import PaymentMethod from './PaymentMethod';

const validationSchema = Yup.object().shape({

});
const Payment = (props) => {
  const navigate = useNavigate();
  const [loading, setLoading] = useState(false);
  const [open, setOpen] = useState(false);
  const [open_payment_method, set_open_payment_method] = useState(false);
  const { order } = useCart();
  const handlePaymentMethodOpen = () => {
    set_open_payment_method(true)
  }
  const handleClickOpen = () => {
    setOpen(true);
  }

  const handleCancelOrder = (order_Id) => {
    swal({
      title: "Are you sure?",
      text: "You want to cancel \"" + order_Id + "\" order!",
      icon: "warning",
      buttons: [
        'No, Dont Cancel!',
        'Yes, Cancel Order!'
      ],
      dangerMode: true,
    }).then(function (isConfirm) {
      if (isConfirm) {

        // console.log(order_Id);

        try {
          // axios.get('/sanctum/csrf-cookie').then(response => {
          let token = localStorage.getItem('accessToken')
          const config = { headers: { 'x-api-key': token } };
          axios.post('api/cart/orderCancel', {

            'orderId': order_Id
          }, config)
            .then(response => {
              if (response.data.status === 200) {

                swal({
                  title: 'Done!',
                  text: response.data.message,
                  icon: 'success'
                })
              } else {
                swal({
                  title: "Error!",
                  text: response.data.message,
                  icon: "error",
                });
              }
              setLoading(false);
            })
            .catch(function (error) {
              setLoading(false);
              swal({
                title: "Error!",
                text: error.response.data.message,
                icon: "error",
              });
            });
          // });
        } catch (e) {
          setLoading(false);
          console.log(e);
          // swal({
          //   title: "Error!",
          //   text: e,
          //   icon: "error",
          // });
        }
      }
    });
  }

  return (
    <>
      {order && (
        <Grid container item rowSpacing={3} columnSpacing={3}>
          <Grid item lg={8} sm={12} xs={12}>
            <OrderProducts />
          </Grid>
          <Grid item lg={4} sm={12} xs={12}>
            {/* <Alert severity="success">Thank you for your order!</Alert> */}
            <Alert severity="success" icon={false} sx={{ textAlign: "center", width: "100%", display: "block", overflow: 'hidden' }}>
              <br />
              <AlertTitle color="success" sx={{ textAlign: "center", fontSize: "25px" }}>
                Your order has been received!
              </AlertTitle>
              <br />
              <CheckCircleIcon color="success" sx={{ textAlign: "center", margin: "0px auto", display: "block", fontSize: "60px" }} />
              <AlertTitle color="success" sx={{ textAlign: "center", fontSize: "25px" }}>
                Thank you for your order!
              </AlertTitle>
              <p><strong>The order confirmation has been sent to your mobile number.</strong></p>
              <br />
              <p>Your order ID is : <strong>{order.orderId}</strong></p>
              <br />
              <p>Order Date : <strong>{Moment(order.created_at).format('DD MMM YYYY')}</strong></p>
              <br />
              <p>Total Amount : <strong>{process.env.REACT_APP_CURRENCY}. {order.totalPayable}</strong></p>
              <br />
              <p>Payment Status : <strong>Pending</strong></p>
              <br />
              <br />
              <Button onClick={handlePaymentMethodOpen} type='submit'
                variant="contained" color="success" size='medium'
                sx={{ fontSize: '15px', fontWeight: "700" }}>
                Pay Now
              </Button>
              <Button onClick={(e) => { handleCancelOrder(order.orderId) }} type='submit'
                variant="contained" color="error" size='medium'
                sx={{ fontSize: '15px', fontWeight: "700", marginLeft: '2%' }}>
                Cancel
              </Button>
              <PaymentProcess open={open} setOpen={setOpen} />
              <PaymentMethod open={open_payment_method} setOpen={set_open_payment_method} />
              <br />
              <br />
            </Alert>
          </Grid>
        </Grid >
      )}
    </>
  );
}

export default Payment;
