import React from 'react'
import { Link } from 'react-router-dom';
import OwlCarousel from 'react-owl-carousel';
import 'owl.carousel/dist/assets/owl.carousel.css';
import 'owl.carousel/dist/assets/owl.theme.default.css';
import Carousel from 'react-material-ui-carousel'
import Item from './Item'
import { useState, useEffect } from 'react'

import './sellWithUSCarousel.css'
import dashboard from './dashboard new.png'
import firmGenerator from './firm generator new.png'
import purchaseEntry from './purchase entry new.png'
const SellWithUsCarouselFile = () => {

  const [slider, setSlider] = useState(
    [
      { "id": 1, "image": dashboard, "title": 'dashboard' },
      { "id": 2, "image": firmGenerator, "title": 'firmGenerator' },
      { "id": 3, "image": purchaseEntry, "title": 'purchaseEntry' },


    ]
  )

  const options = {
    nav: true,
    dots: false,
    autoplay: true,
    loop: true,
    margin: 0,
    responsiveClass: true,
    animateIn: 'fadeIn',
    animateOut: 'fadeOut',
    // navText: ['<i class="ion-ios-arrow-left"></i>', '<i class="ion-ios-arrow-right"></i>'],
    smartSpeed: 1000,
    responsive: {
      0: {
        items: 1,
      },
      400: {
        items: 1,
      },
      600: {
        items: 1,
      },
      700: {
        items: 1,
      },
      1000: {
        items: 1,

      }
    },
  };
  const [isText1Visible, setIsText1Visible] = useState(true);

  useEffect(() => {
    const interval = setInterval(() => {
      setIsText1Visible((prev) => !prev);
    }, 10000);

    return () => clearInterval(interval);
  }, []);

  return (
    // // <div style={{ display: 'flex',  }}>
    //   <Carousel indicators={false} interval={'4000'} >
    //     {
    //       slider.map((item, i) => <Item key={item.id} item={item} />)
    //     }
    //   </Carousel>
    // // </div>
    <>
      <div className="section outer_vtwo_div">
        <div className='outer_bp_vtwo'>
          <div className={`fade ${isText1Visible ? 'in' : ''}`} >
            <h5>Free Trial - 90 days</h5>
            <p>Try it free for 90 days - discover the full potential of our product with our extended trial offer</p>
            <h5>Web Based Backup</h5>
            <p>Empower your data security with our cutting-edge Web-Based Backup solution. Safeguard your files and access them anytime, anywhere with ease</p>
            <h5>Quick Customer Support</h5>
            <p>Experience peace of mind with our Quick Customer Support. Our team is ready to assist you promptly, ensuring a seamless and stress-free experience</p>
            <h5>Assign Your Invoice Sequence</h5>
            <p>Take control of your financial workflow – Assign Your Invoice Sequence. Tailor your invoicing process for personalized and organized financial management</p>
          </div>
          <div className={`fade ${!isText1Visible ? 'in' : ''}`} >
            <h5>Single Click Export To Marketplace</h5>
            <p>Effortless expansion. Grow your reach seamlessly with a swift, one-click export of your products to Hegan Marketplace</p>
            <h5>Purchase Entry</h5>
            <p>Streamline your business finances with ease. Effortlessly manage your purchases through our intuitive Purchase Entry system for accurate and organized financial records</p>
            <h5>Sales Entry</h5>
            <p>Boost your business with precision. Track sales effortlessly with our intuitive Sales Entry system, ensuring accurate financial management</p>
            <h5>Reports</h5>
            <p>Gain clarity into your financial landscape. Easily generate detailed Sales and Purchase Reports, empowering you to make informed decisions for the growth of your business</p>
          </div>
        </div>
        <div className='inner_vtwo_div' >
          <h4>Hegan SaaS Platform</h4>
          <h5 style={{color:'white',}}>Your Billing Software</h5>
          <Carousel
            animation="slide"
            duration={1500}
          >
            {slider.map((item, index) => (
              <img src={item.image} alt={item.title} style={{}} key={'img' + item.id} />
            ))}
          </Carousel>

        </div>

      </div >

    </>

  )
}

export default SellWithUsCarouselFile