import React from "react";

import Category from 'app/views/frontend/Category/Category';
import ProductDetail from 'app/views/frontend/ProductDetail/ProductDetail';
import Checkout from 'app/views/frontend/Checkout/Checkout';
import CheckoutOrderSuccess from 'app/views/frontend/Checkout/CheckoutOrderSuccess';
import SpecialOffersViewAll from "./Category/SpecialOffersViewAll";
import FormulationSidePanel from "./Category/FormulationSidePanel";

const FrontendRoutes = [
  { path: '/categories', element: <Category /> },
  { path: '/margin/:margin_id', element: <Category /> },
  { path: '/specialOffersViewAll', element: <SpecialOffersViewAll /> },
  { path: '/categories/:category_id', element: <Category /> },
  { path: '/subCategories/:sub_category', element: <Category /> },
  { path: '/subCategories', element: <Category /> },
  { path: '/formulationproduct/:formulation', element: <Category /> },
  { path: '/formulation', element: <FormulationSidePanel /> },
  { path: '/brand/:brand', element: <Category /> },
  { path: '/brand', element: <Category /> },
  { path: '/specialOffersViewAll/:category_id', element: <Category /> },
  { path: '/product/details/:row_id', element: <ProductDetail /> },
  { path: '/detail', element: <ProductDetail /> },
  { path: '/product/checkout', element: <Checkout props={{ selected_step: 0 }} /> },
  // { path: '/product/checkout/order-summary', element: <Checkout props={{ selected_step: 0 }} /> },
  { path: '/product/checkout/shipping-address', element: <Checkout props={{ selected_step: 1 }} /> },
  // { path: '/product/checkout/order-placed', element: <Checkout props={{ selected_step: 2 }} /> },
  // { path: '/product/checkout/order-success/:order_id', element: <CheckoutOrderSuccess props={{ selected_step: 3 }} /> },
];

export default FrontendRoutes;
