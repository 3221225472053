import React, { useEffect, useState } from 'react';

import {
    Link, NavLink, useLocation, useNavigate,
} from 'react-router-dom';
import Moment from 'moment';
import useAuth from 'app/hooks/useAuth';
import { makeStyles } from '@material-ui/core/styles';

import axios from 'axios';


import { Grid, RadioGroup, FormControlLabel, Radio, Alert, Backdrop, CircularProgress, Button } from '@mui/material';



// import './hotSellerSlider.css'

import formulation_bg from '../HomePage/formularion_blue_bg.jpg'
import heganlogo from '../HomePage/heganlogo.png'
import Header from '../Header/Header';
import "../Header/header.css"
import Footer from '../Footer/Footer';
import './formulationSidePanel.css'
import './../frontend.css'
import './category.css'

const useStyles = makeStyles({
    payment_table_heads: {
        fontFamily: 'Poppins, sans-serif',
        fontWeight: 'bold',
    },
});

function FormulationSidePanel() {


    const [formulations, set_formulations] = useState(false);
    const [searchProducts, setSearchProducts] = useState(false);
    const [loading, setLoading] = useState(false);
    const [page, set_page] = useState(1);
    const location = useLocation();
    let { logout, user, isAuthenticated } = useAuth();










    useEffect(() => {

        if (isAuthenticated) {
            fetchProductData();
        } else {
            fetchData();
        }
    }, []);

    const handleFormulationChange = async (e) => {


        try {

            // var search_api_url = 'api/user/product/getFilter';
            // if (isAuthenticated) {
            //     search_api_url = 'api/user/product/getFilter';
            // }
            axios.post(`api/admin/subCategory/searchFormulation?name=${e.target.value}`)
                .then(response => {
                    // console.log(response);
                    if (response.data.status === 200) {
                        setSearchProducts(false);
                        set_formulations(response.data.data);

                    } else {
                        set_formulations(false);
                        setSearchProducts(false);
                    }


                })
                .catch(function (error) {


                    // console.log(error);
                });
            // });
        } catch (e) {
            // setLoading(false);

        }
    }






    /**
     * Fetching data from server.
     */




    const fetchData = async (page_no = 1) => {
        setLoading(true);
        try {
            // await axios.get('/sanctum/csrf-cookie').then(response => {
            axios.post('api/admin/subCategory/getAllformulationCategory', {
                page: page_no,
            })
                .then(response => {
                    // console.log(response);
                    if (response.data.status === 200) {
                        set_formulations(response.data.category);
                    } else {
                        set_formulations(false);
                    }
                    setLoading(false);
                })
                .catch(function (error) {
                    setLoading(false);
                    console.log(error);
                });
            // });
        } catch (e) {
            setLoading(false);
        }
    }
    /**
     * Fetching data from server.
     */



    const fetchProductData = async (page_no = 1) => {
        setLoading(true);
        try {
            // await axios.get('/sanctum/csrf-cookie').then(response => {
            // axios.get('api/user/product/getAll')
            axios.post('api/admin/subCategory/getAllformulationCategory', {
                page: page_no,
            })
                .then(response => {
                    // console.log(response);
                    if (response.data.status === 200) {
                        set_formulations(response.data.category);
                    } else {
                    }
                    setLoading(false);
                })
                .catch(function (error) {
                    setLoading(false);
                    console.log(error);
                });
            // });
        } catch (e) {
            // setLoading(false);
        }
    }

    const fetch_product_for_formulation = async (formulation) => {
        setLoading(true);
        try {
            // await axios.get('/sanctum/csrf-cookie').then(response => {
            // axios.get('api/user/product/getAll')
            axios.post('api/admin/subCategory/getProductByFormulation', {

                name: formulation,
            })
                .then(response => {
                    console.log(response);
                    if (response.data.status === 200) {
                        setSearchProducts(response.data.data);

                    }
                    setLoading(false);
                })
                .catch(function (error) {
                    setLoading(false);
                    console.log(error);
                });
            // });
        } catch (e) {
            // setLoading(false);
        }
    }





    return (
        <>

            <Header />
            <div style={{ display: 'flex', justifyContent: 'center', alignItems: 'center', position: 'relative', }} >
                <input
                    className="searchFormInput"
                    name="q"
                    // onBlur={handleBlur}
                    // value={values.q}
                    onChange={(e) => {
                        handleFormulationChange(e)

                    }}
                    placeholder="Search For Formulations"
                    required=""
                    type="text"
                    style={{ color: 'grey' }}
                />


                <i className="linearicons-magnifier search_icon_formulation" style={{
                    fontSize: '1.2rem', color: '#015998'


                }}>
                </i>

            </div>

            <div style={{ padding: '30px 8.7% 40px 9%' }}>
                <div className="container" style={{ padding: '0' }}>
                    <div >

                        <ol className="breadcrumb ">
                            <li className='breadcrumbs_content'>
                                <Link to="/" style={{ color: 'white', }}>Home</Link>
                            </li>
                            <li className="breadcrumb-item"></li>
                            <li className="breadcrumb-item" style={{ padding: '1% 0 1% 1.5%' }}></li>
                            <li className='breadcrumbs_content'>
                                <Link to="/formulation" style={{ color: 'white', }}>All Formulations</Link>
                            </li>

                            {/* <li className="breadcrumb-item active">Himalaya Brand Store</li> */}
                        </ol>

                    </div>
                </div>
            </div>
            <Backdrop
                sx={{ color: '#015998', zIndex: (theme) => theme.zIndex.drawer + 1 }}
                open={!!loading}
            // onClick={setLoading}
            >
                <CircularProgress color="inherit" />
            </Backdrop>

            <div className='formulation_flexbox'>
                {formulations ?
                    (
                        formulations.map((formulation, index) => (
                            <Link to={`/formulationproduct/${formulation.name}`} className="hover_effect1" title={formulation.name}>
                                <div className='main_formulation_div' onClick={(e) => {
                                    fetch_product_for_formulation(formulation.name)
                                }}>
                                    <p style={{ color: 'white', fontWeight: 'bold', lineHeight: '14px', }} key={"h2" + index}>{formulation.name}</p>
                                </div>
                            </Link>
                        ))
                    ) :
                    (
                        <Alert style={{ margin: '0 0 100px 0' }} severity="info">No results match your search criteria!</Alert>
                    )
                }
            </div>



            <Footer />
        </>
    );
}
export default FormulationSidePanel;
