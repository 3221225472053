import React from 'react'
import Header from '../Header/Header'
import Footer from '../Footer/Footer'

const PrivacyPolicy = () => {
    return (
        <>
            <div>
                <Header />


                <div style={{ margin: '1% 5% ' }}>
                    <div className="headerModal" style={{ borderRadius: '10px' }}>
                        <div className="headingDiv">
                            <h4 className="headingModal">Privacy Policies</h4>
                        </div>

                    </div>

                    <p className="points">
                        Hegan and its mobile application platform (each a “Platform”) is a
                        platform that allows the users (hereinafter referred to as “User”
                        or “you” or “your”) to communicate with each other directly either
                        through direct messaging or through the mobile application itself
                        without any kind of intervention from Hegan. Hegan recognizes the
                        importance of privacy as well as the importance of maintaining the
                        confidentiality of personal information. This Privacy Policy
                        (“Privacy Policy”) applies to all products and services provided
                        by Hegan and sets out how Hegan may collect, use and disclose
                        information in relation to Users of the Platform.
                    </p>
                    <p className="points">
                        User may use Hegan's services and products via a mobile device
                        either through mobile applications or mobile optimized websites.
                        This Privacy Policy also applies to such use of Hegan’s services
                        and products.
                    </p>
                    <p className="points">
                        All capitalized terms not defined in this document shall have the
                        meanings ascribed to them in the Terms of Use of the Platform,
                        which can be found here.
                    </p>
                    <h3 className="points">1. COLLECTION OF INFORMATION</h3>
                    <p className="points">
                        a. User(s) privacy is important to Hegan and Hegan has taken steps
                        to ensure that Hegan do not collect more information from User
                        than is necessary for Hegan to provide User(s) with Hegan’s
                        services and to protect User(s) account.
                    </p>
                    <p className="points">
                        b. Information including, but not limited to, username, address,
                        phone number, fax number, email address, gender, date and/or year
                        of birth and user preferences ("Registration Information"){" "}
                    </p>
                    <p className="points">
                        c. In connection with any communication or transaction and payment
                        services or any other services that you may avail using the
                        Platform, information, including but not limited to, bank account
                        numbers, billing and delivery information, credit/debit card
                        numbers and expiration dates and tracking information from cheques
                        or money orders ("Account Information") may be collected, among
                        other things, to facilitate the sale and purchase as well as the
                        settlement of purchase price of the products or services
                        transacted on or procured through the Platform.
                    </p>
                    <p className="points">
                        d. Hegan records and retains details of Users’ activities on the
                        Platform. Information relating to communication or transactions
                        including, but not limited to, the types and specifications of the
                        goods, pricing and delivery information, any dispute records and
                        any information disclosed in any communication forum provided by
                        us and/or other affiliated companies of Hegan (“Activities
                        Information”) may be collected as and when the communication and /
                        or transactions are conducted through the Platform.
                    </p>
                    <p className="points">
                        e. Hegan records and retains records of Users’ browsing or buying
                        activities on Platform including but not limited to IP addresses,
                        browsing patterns and User behavioural patterns. In addition, we
                        gather statistical information about the Platform and visitors to
                        the Platform including, but not limited to, IP addresses, browser
                        software, operating system, software and hardware attributes,
                        pages viewed, number of sessions and unique visitors (together
                        "Browsing Information").
                    </p>
                    <p className="points">
                        f. Registration Information, Account Information, Activities
                        Information, and Browsing Information are collectively referred to
                        as User Data
                    </p>
                    <p className="points">
                        g. It is mandatory for Users of the Platform to provide certain
                        categories of User Data (as specified at the time of collection).
                        In the event that Users do not provide any or sufficient User Data
                        marked as mandatory, Hegan may not be able to complete the
                        registration process or provide such Users with Hegan’s products
                        or services.
                    </p>


                    <h3 className="points">2. USE OF USER DATA</h3>
                    <p className="points">
                        If you provide any User Data to Hegan, you are deemed to have
                        authorized Hegan to collect, retain and use that User Data for the
                        following purposes:
                    </p>
                    <p className="points">a. Verification of User’s identity;</p>
                    <p className="points">
                        b. processing User’s registration as a user, providing User with a
                        log-in ID for the Platform and maintaining and managing User’s
                        registration;
                    </p>
                    <p className="points">
                        c. providing User with customer service and responding to User(s)
                        queries, feedback, claims or disputes;
                    </p>
                    <p className="points">
                        d. to facilitate communication between Users on the Platform and /
                        or processing Users transactions on the Platform;
                    </p>
                    <p className="points">
                        e. performing research or statistical analysis in order to improve
                        the content and layout of the Platform, to improve Hegan’s product
                        offerings and services and for marketing and promotional purposes;
                    </p>
                    <p className="points">
                        f. subject to applicable laws, Hegan (including our affiliated
                        companies and their designated Service Providers may use User’s
                        name, phone number, residential address, email address, fax number
                        and other data ("Marketing Data") to provide notices, surveys,
                        product alerts, communications and other marketing materials to
                        User(s) relating to products and services offered by Hegan or
                        Hegan’s affiliated companies; g. if User voluntarily submit any
                        User informat
                    </p>
                    <p className="points">
                        g. if User voluntarily submit any User information or other
                        information to the Platform for publication on the Platform
                        through the publishing tools, then Users are deemed to have given
                        consent to the publication of such information on the Platform;
                        and
                    </p>
                    <p className="points">
                        h. making such disclosures as may be required for any of the above
                        purposes or as required by law, regulations, and guidelines or in
                        respect of any investigations, claims or potential claims brought
                        on or against us or against third parties
                    </p>
                    <h3 className="points">3. DISCLOSURE OF USER DATA</h3>
                    <p className="points">
                        a. User further agrees that Hegan may disclose and transfer User
                        Data to third party service providers (including but not limited
                        to data entry, database management, promotions, products and
                        services alerts, delivery services, payment extension services,
                        authentication and verification services and logistics services)
                        ("Service Providers"). These Service Providers are under a duty of
                        confidentiality to Hegan and are only permitted to use User Data
                        in connection with the purposes specified in clause 2 herein
                        above.
                    </p>
                    <p className="points">
                        b. User(s) agree that Hegan may disclose and transfer User Data to
                        Hegan’s affiliated companies and/or their designated Service
                        Providers.
                    </p>
                    <p className="points">
                        c. When necessary Hegan may also disclose and transfer User Data
                        to our professional advisers, law enforcement agencies, insurers,
                        government and regulatory and other organizations.
                    </p>
                    <p className="points">
                        d. Any User Data supplied by User will be retained by Hegan and
                        will be accessible by our employees, any Service Providers engaged
                        by Hegan and third parties referred to in clause 3 herein, for or
                        in relation to any of the purposes stated in Clause 2 herein
                        above.
                    </p>
                    <p className="points">
                        e. All voluntary information uploaded by you on the Platform
                        (including without limitation information about your products,
                        images, remarks, feedbacks etc. ) may be made publicly available
                        on the Platform and therefore accessible by any internet user. Any
                        voluntary information that User disclose to Hegan becomes public
                        information and User relinquishes any proprietary rights
                        (including but not limited to the rights of confidentiality and
                        copyright) in such information. User should exercise caution when
                        deciding to include personal or proprietary information in the
                        voluntary information that User submits to Hegan or uploads on the
                        Platform.
                    </p>
                    <p className="points">
                        f. Hegan may share User Data with third parties, including without
                        limitation, banks, financial institutions, credit agencies, or
                        vendors to enable such third parties to offer their products or
                        services to such Users. While Hegan shall endeavor to have in
                        place internal procedures to keep User Data secure from intruders,
                        there is no guarantee that such measures/procedures can eliminate
                        all of the risks of theft, loss or misuse.
                    </p>
                    <p className="points">
                        g. Hegan may establish relationships with other parties and
                        websites to offer User the benefit of products and services which
                        Hegan does not offer. Hegan may offer you access to these other
                        parties and/or their websites. This Privacy Policy does not apply
                        to the products and services enabled or facilitated by such third
                        parties. The privacy policies of those other parties may differ
                        from Hegan, and Hegan has no control over the information that
                        User may submit to those third parties. User should read the
                        relevant privacy policy for those third parties before responding
                        to and availing any offers, products or services advertised or
                        provided by those third parties.
                    </p>
                    <h3 className="points">RIGHT TO UPDATE USER DATA</h3>
                    <p className="points">
                        Under the applicable laws, User(s) have the right of access to
                        personal information held by Hegan and to request updating /
                        correcting the information.
                    </p>
                    <h3 className="points">5. COOKIES</h3>
                    <p className="points">
                        Hegan uses "cookies" to store specific information about User and
                        track User(s) visits to the Sites. A "cookie" is a small amount of
                        data that is sent to User’s browser and stored on User’s device.
                        If User does not deactivate or erase the cookie, each time User
                        uses the same device to access the Platform, our services will be
                        notified of User visit to the Platform and in turn Hegan may have
                        knowledge of User visit and the pattern of User’s usage.
                    </p>
                    <p className="points">
                        Generally, Hegan use cookies to identify User and enable Hegan to
                        i) access User’s Registration Information or Account Information
                        so User do not have to re-enter it; ii) gather statistical
                        information about usage by Users; iii) research visiting patterns
                        and help target advertisements based on User interests; iv) assist
                        Hegan’s partners to track User visits to the Platform and process
                        orders; and v) track progress and participation on the Platform.
                    </p>
                    <p className="points">
                        User can determine if and how a cookie will be accepted by
                        configuring the browser which is installed in User’s device. If
                        User chooses, User can change those configurations. If User reject
                        all cookies by choosing the cookie-disabling function, User may be
                        required to re-enter information on the Platform more often and
                        certain features of the Platform may be unavailable.
                    </p>
                    <h3 className="points">MINORS</h3>
                    <p className="points">
                        The Platform and its contents are not targeted to minors (those
                        under the age of 18). However, Hegan have no way of distinguishing
                        the age of individuals who access our Platform. If a minor has
                        provided Hegan with personal information without parental or
                        guardian consent, the parent or guardian should contact Hegan’s
                        Legal Department at the address set out in clause 11 below to
                        remove the information.
                    </p>
                    <h3 className="points">SECURITY MEASURES</h3>
                    <p className="points">
                        Hegan employs commercially reasonable security methods to prevent
                        unauthorized access to the Platform, to maintain data accuracy and
                        to ensure the correct use of the information Hegan hold. No data
                        transmission over the internet or any wireless network can be
                        guaranteed to be perfectly secure. As a result, while Hegan tries
                        to protect the information it holds, Hegan cannot guarantee the
                        security of any information the User transmits to Hegan and Users
                        do so at their own risk
                    </p>
                    <h3 className="points">DATA RETENTING</h3>
                    <p className="points">
                        Hegan takes every reasonable step to ensure that User’s personal
                        information is processed ‘as is’. You may access, correct, and
                        update your personal information directly through the
                        functionalities provided on the Platform.
                    </p>
                    <p className="points">
                        You have an option to withdraw your consent that you have already
                        provided by writing to us at the contact information provided
                        below. Please mention “for withdrawal of consent” in the subject
                        line of your communication. Hegan will verify such requests before
                        acting upon your request. Please note, however, that withdrawal of
                        consent will not be retroactive and will be in accordance with the
                        terms of this Privacy Policy, related terms of use, and applicable
                        laws. In the event you withdraw the consent given to us under this
                        Privacy Policy, such withdrawal may hamper your access to the
                        Platform or restrict the provision of our services to you for
                        which Hegan consider that information to be necessary.
                    </p>
                    <h3 className="points">USER'S RIGHTS</h3>
                    <p className="points">
                        Hegan retains your personal information in accordance with
                        applicable laws, for a period no longer than is required for the
                        purpose for which it was collected or as required under any
                        applicable law. However, Hegan may retain data related to you if
                        it believes it may be necessary to prevent fraud or future abuse,
                        to enable Hegan to exercise its legal rights and/or defend against
                        legal claims or if required by law or for other legitimate
                        purposes. Hegan may continue to retain your data in anonymised
                        form for analytical and research purposes.
                    </p>
                    <p className='points'>Hegan gives rights to its users to delete all data stored in Hegan Website and App.</p>
                    <h3 className="points">CHANGES TO THIS PRIVACY POLICY</h3>
                    <p className="points">
                        Any changes to this Privacy Policy will be communicated by us
                        posting an amended and restated Privacy Policy on the Platform.
                        Once posted on the Platform the new Privacy Policy will be
                        effective immediately. Your continued use of the Platform shall be
                        deemed to be your acceptance to the provisions of the Privacy
                        Policy. User agrees that any information Hegan holds about User
                        (as described in this Privacy Policy and whether or not collected
                        prior to or after the new Privacy Policy became effective) will be
                        governed by the latest version of the Privacy Policy
                    </p>
                </div>
                <Footer />
            </div>
        </>
    )
}

export default PrivacyPolicy