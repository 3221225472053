import useSettings from 'app/hooks/useSettings';
import { Link } from 'react-router-dom';
const MatxLogo = ({ className }) => {
  const { settings } = useSettings();
  const theme = settings.themes[settings.activeTheme];

  return (
    <div>
      <Link to='/'>
        <img src={`${process.env.PUBLIC_URL}/assets/images/logos/Logo.png`} width="30px" title='Hegan Logo'/>
      </Link>
    </div>
  );
};

export default MatxLogo;
